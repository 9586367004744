<script>
	import TreuFalse from './AssignmentBuilder/Treu-false.svelte';
	import TrueFalse from './../questions/TrueFalse.svelte';
	import Short from './AssignmentBuilder/Short.svelte';
  import { link, navigate } from "svelte-routing";

    export let location;
    location = location;
    let newtopic;
    let newSection;
    let part;
    let editTopicData;
    let editSection;

    let allSections =[]

    import TeacherSideBar from "./TeacherSideBar.svelte";
    import TeacherDashboardHeader from "./TeacherDashboardHeader.svelte";

    import MultipleChoice from "./AssignmentBuilder/MultipleChoice.svelte";
    import QuestionEditor from "./AssignmentBuilder/QuestionEditor.svelte";
    import Long from "./AssignmentBuilder/Long.svelte";
    import { get_all_section, add_new_section_data , delete_sections_all_data , add_section_names, get_unit_section_data, delete_unit_section , edit_section_topic} from "../db/exams";

    import { onMount } from "svelte";
    import Match from './AssignmentBuilder/Match.svelte';

    let alert_section={
        type:'',
        msg:''
    }

    let alert_delete_section={
        type:'',
        msg:''
    }

  let  select =''
  let editdata;

  onMount(async ()=>{
    all_section()
  })

  async function all_section(){
     allSections = await get_all_section()
     let s=allSections.sort((a,b)=>{
         return b.slNo - a.slNo
     })
     allSections = s
    //  console.log(allSections)
  }

async function add_new_section(){
    alert_section={
        type:'',
        msg:''
    }
    if(newtopic && newSection){

        let s = await add_new_section_data(newtopic, newSection)
        if(s){
            alert_section = {
                type:'success',
                msg:'Section added succesfully'
            }
            setTimeout(async () => {
            await all_section()   
            newtopic='' 
            newSection=''
            }, 1000);
            
        }else{
            alert_section = {
                type:'danger',
                msg:'Sorry, something is wrong'
            }
        }
        
    }else{
        alert_section = {
                type:'danger',
                msg:'Section name or topic name is missing.'
            }
    }
}

async function selectSection(d){
    alert_delete_section={
               type:'',
               msg:''
           }
    editSection = d
}

async function delete_section(d){
  if(d){
       await delete_sections_all_data(d.id)

      
           alert_delete_section={
               type:'success',
               msg:'Delete succesfull'
           }
           
           await all_section()


       
  }
}

async function delete_section_unit_data(data, key){
  if(data){
      let s = await delete_unit_section(data, key)

      if(s){
          setTimeout( async () => {
            editSection  =  await get_unit_section_data(data)
            await all_section()

              
          }, 1000);
      }
  }
}

async function all_alert_clear(){
    alert_delete_section={
               type:'',
               msg:''
           }
           alert_section = {
                type:'',
                msg:''
            }

            editTopicData=null
            newtopic=null
}

async function Add_section_data(data){
    if(editdata){
      let s = await add_section_names(data, editdata )

      if(s){
            alert_section = {
                type:'success',
                msg:'Section added succesfully'
            }
            
            editdata=''
             setTimeout( async() => {
                await all_section()
            editSection  =  await get_unit_section_data(data)
                 
             }, 1000);

        }else{
            alert_section = {
                type:'danger',
                msg:'Sorry, something is wrong'
            }
        }
    }else{
        alert_section = {
                type:'danger',
                msg:'Section name or topic name is missing.'
            }
    }
}


async function editTopic(data){
    editTopicData = data

    newtopic = data.topic


}


async function editTopicNow(data){
  if(data && newtopic ){
      let s = await edit_section_topic(data, newtopic);

      if(s){
        alert_section = {
                type:'success',
                msg:'Section added succesfully'
            }
            setTimeout(async() => {
                newtopic=null
                await all_section()

            }, 1000);
            
        }else{
            alert_section = {
                type:'danger',
                msg:'Sorry, something is wrong'
            }
        }
      
  }
}



</script>

<TeacherSideBar location={location} activeOption={'SYSTEMADMIN_STANDARDS'}>
    <TeacherDashboardHeader title="Section for assign an assignment" writeUp="Topic section is required for create an assignment for students."></TeacherDashboardHeader>

    <!-- <QuestionEditor></QuestionEditor> -->
  
    <div class="row"  >
        <div class="col">
            <a  href={`/asignment/build/now`} use:link  class="btn btn-primary" style="float: right; margin-right:50px">
                Create Assignment
            </a >
        </div>
       
    </div>

    <div class="row">
        <div class="col">
            <h4>
                <strong>
                    Create Section here first:-
                </strong>
            </h4>

            </div>
           
        
    </div>
    <div class="row">
        {#if alert_section.type=='success'}
             <!-- content here -->
             <div class="alert alert-success alert-dismissible fade show" role="alert">
                <strong>
                    {alert_section.msg}
                </strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" on:click={all_alert_clear}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
        {:else if alert_section.type=='danger'}
             <!-- else content here -->
             <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>
                    {alert_section.msg}
                </strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" on:click={all_alert_clear}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
        {/if}
    </div>

    {#if !editTopicData}
         <!-- content here -->
         <div class="row" style="padding:20px ;">

        

            <div class="form-group row">
                
                <div class="col-sm-4">
                  <input type="text" placeholder="Enter topic name"  class="form-control" id="topicName" bind:value={newtopic} >
                </div>
                <div class="col-sm-4">
                    <input type="text" placeholder="Enter Section name"  class="form-control" id="sectionName" bind:value={newSection} >
                  </div>
                <div class="col-sm-4">
                    <button class="btn btn-primary" on:click={add_new_section}>Submit</button>
                  </div>
              </div>
    
        </div>
    
    {:else}
         <!-- else content here -->
         <div class="row" style="padding:20px ;">

        

            <div class="form-group row">
                
                <div class="col-sm-8">
                  <input type="text" placeholder="Enter topic name"  class="form-control" id="topicName1" bind:value={newtopic} >
                </div>
              
                <div class="col-sm-2">
                    <button class="btn btn-primary" on:click={editTopicNow(editTopicData)}>Edit</button>
                  </div>
                  <div class="col-sm-2">
                    <button class="btn btn-danger" on:click={all_alert_clear}>Close</button>
                  </div>
              </div>
    
        </div>
    
    {/if}

  

    
         <!-- content here -->
         <div class="row">
            <section style=" position:relative">
                <div class="table-responsive" style="width: 100%; height:500px; overflow:scroll;">
                    <table class="table">
                      <thead>
                          <tr>
                              <th>
                                  #
                              </th>
                              <th>
                                 Section Topic
                              </th>
                              <th>
                                  Section Names
                              </th>
                              <th>
                                Add Sections
                            </th>
                            <th>
                               Edit topic name
                            </th>
                              <th>
                                  Delete
                              </th>
                          </tr>
    
    
                      </thead>
    
                      <tbody>
                          {#each allSections as item,i}
                               <!-- content here -->
                               <tr>
                                   <td>
                                       {i+1}
                                   </td>
                                   <td>
                                       {item.topic}
                                   </td>
                                   <td>
                                       {#if item.sections}
                                            <!-- content here -->
                                            <select class="custom-select mr-sm-2"         id="inlineFormCustomSelect">
                                                <option selected>View section names</option>
                                               {#each item.sections as s}
                                                    <!-- content here -->
                                                    <option value="">{s.section}</option>
                                               {/each}
                                                
                                                </select>
                                       {/if}
                                   
                                   </td>
                                   <td>
                                    <button class="btn btn-warning btn-sm"  data-toggle="modal" data-target="#exampleModal1" on:click={selectSection(item)}>Add Sections</button>
                                   </td>
                                   <td>
                                       <button class="btn btn-info" on:click={editTopic(item)}>Edit topic</button>
                                   </td>
                                   <td>
                                       <button class="btn btn-danger"  data-toggle="modal" data-target="#exampleModal" on:click={selectSection(item)}>Delete</button>
                                   </td>
                               </tr>
                          {/each}
                      </tbody>
                    </table>
                  </div>
             </section>
         </div>
       
   



        
  


</TeacherSideBar>



<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Confirmation of section</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" on:click={all_alert_clear}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {#if editSection}
                      <!-- content here -->
                      {#if alert_delete_section.type =='success'}
                      <!-- content here -->
                      <div class="alert alert-success alert-dismissible fade show" role="alert">
                         <strong>
                             {alert_delete_section.msg}
                         </strong>
                        
                       </div>
                 {:else if alert_delete_section.type =='danger'}
                      <!-- else content here -->
                      <div class="alert alert-danger alert-dismissible fade show" role="alert">
                         <strong>
                             {alert_delete_section.msg}
                         </strong>
                        
                       </div>
                 {/if}
         
               <!-- content here -->
               {#if alert_delete_section.type !='success'}
                    <div style="padding:20px ;">
                        Q) Do you realy want to delete section {editSection.topic} ?
                     </div>
               {/if}
              
          {/if}
        </div>
        <div class="modal-footer">
            {#if alert_delete_section.type != 'success'}
                 <!-- content here -->
          <button type="button" class="btn btn-danger" on:click={delete_section(editSection)} >Delete</button>

            {/if}
          <button type="button" class="btn btn-secondary" data-dismiss="modal" on:click={all_alert_clear}>Close</button>
        </div>
      </div>
    </div>
  </div>


  <!-- Modal -->
<div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add New Section</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            {#if editSection}
             <div class="row">
                 <div class="col">
                     Topic Name:- {editSection.topic}
                 </div>
             </div>
             {#if alert_section.type=='success'}
             <!-- content here -->
             <div class="alert alert-success alert-dismissible fade show" role="alert" style="padding: 20px;">
                <strong>
                    {alert_section.msg}
                </strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" on:click={all_alert_clear}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
        {:else if alert_section.type=='danger'}
             <!-- else content here -->
             <div class="alert alert-danger alert-dismissible fade show" role="alert" style="padding: 20px;">
                <strong>
                    {alert_section.msg}
                </strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" on:click={all_alert_clear}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
        {/if}

             <div class="row" >
            
             </div>

             <div class="row" style="padding: 20px;">
                 <div class="col">
                    <div class="col-auto">
                        <label class="sr-only" for="sec">Section Name</label>
                        <input type="text" class="form-control mb-2" id="sec" placeholder="Enter section name" bind:value={editdata}>
                      </div>
                 </div>
                 <div class="col">
                     <button class="btn btn-primary" on:click={Add_section_data(editSection)}>ADD</button>
                 </div>
             </div>

             <div class="sectiion" style="margin: 10px;">
                <div class="table-responsive" style="height: 300px; overflow-x:scroll">
                    <table class="table">
                       <thead>
                           <tr>
                              <th>
                                  #
                              </th>
                              <th>
                                  Name
                              </th>
                              <th>
                                  Action
                              </th>
                           </tr>
                       </thead>
                       <tbody>
                           {#each editSection.sections as item,i}
                                <!-- content here -->
                                <tr>
                                  <td>
                                      {i+1}
                                  </td>
                                  <td>
                                      {item.section}
                                  </td>
                                  <td>
                                      <button class="btn btn-danger btn-sm" on:click={delete_section_unit_data(editSection, item.key)} >Delete</button>
                                  </td>
                                </tr>
                           {/each}
                       </tbody>
                    </table>
                  </div>
             </div>
            {/if}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>