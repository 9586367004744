<script>
  export let location;
  location = location;
</script>
<style>
/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
  background-color: red;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}
/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
  background-color: #87b2db;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}
/* MARKETING CONTENT
-------------------------------------------------- */
/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}
/* Featurettes
------------------------- */
.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}
/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.05rem;
}
/* RESPONSIVE CSS
-------------------------------------------------- */
@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }
  .featurette-heading {
    font-size: 50px;
  }
}
@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}
</style>
<main role="main">
  <div id="myCarousel" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#myCarousel" data-slide-to="1" class=""></li>
      <li data-target="#myCarousel" data-slide-to="2" class=""></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="first-slide" src="/assets/images/Caaspp.png" alt="First slide"/>
        <div class="container">
          <div class="carousel-caption text-center">
            <h1>California Assessment of Student Performance and Progress</h1>
            <p>Every student deserves the absolute best chance of passing their CAASPP exams.  Smarter Math was designed by certified educators to provide the perfect, most useful tools available.</p>
            <!--<p><a class="btn btn-lg btn-primary" href="#" role="button">Login</a></p>-->
          <br/><br/><br/>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img class="second-slide" src="/assets/images/customize.png" alt="Second slide"/>
        <div class="container">
          <div class="carousel-caption text-right">
            <h1>Customized Student Curriculum</h1>
            <p>The entire learning process is tailored to the needs<br/>of each student based on a preliminary assessment. <br/>No two learning paths are the same!</p>
           <br/><br/><br/>
            <!--<p><a class="btn btn-lg btn-primary" href="#" role="button">Login</a></p>-->
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img class="third-slide" src="/assets/images/Support.png" alt="Third slide"/>
        <div class="container">
          <div class="carousel-caption text-left">
            <h1>We Are With You Every Step of The Way!</h1>
            <p>As much or as little guidance as the student needs.  <br/>Hints, Videos & How to's walk the student through every problem. </p>
            <br/><br/><br/>
            <!--<p><a class="btn btn-lg btn-primary" href="#" role="button">Login</a></p>-->
          </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>


  <!-- Marketing messaging and featurettes
  ================================================== -->
  <!-- Wrap the rest of the page in another container to center all the content. -->

  <div class="container marketing">

    <!-- Three columns of text below the carousel -->
    <div class="row">
      <div class="col-lg-4">
        <img src="/assets/images/newStudents.png" alt="State Standards" width="300">
        <!--<p><a class="btn btn-secondary" href="#" role="button">View details »</a></p>-->
      </div><!-- /.col-lg-2 -->
        <div class="col-lg-8">
        <h2 style="color: #ad4454">WELCOME NEW STUDENTS</h2>
        <p>If you are new to Smarter Math.  WELCOME!!<br/>Simply click the login button below or in the top right-hand corner of the screen and enter the login information your teacher provided.  We will get you started right away!</p>
        <p><button
              class="btn btn-primary"
              style="border-radius: 20px; width:200px;"
              data-toggle="modal"
              data-target="#login-modal"><i class="fas fa-sign-in-alt"></i>&nbsp;Login
            </button>
            </p>
      </div><!-- /.col-lg-12 -->
    </div><!-- /.row -->
  



   <hr class="featurette-divider">
    <!-- Three columns of text below the carousel -->
    <div class="row">
      <div class="col-lg-4">
        <img class="rounded-circle" src="/assets/images/standardsCircle.png" alt="State Standards" width="140" height="140">
        <h2>State Standards</h2>
        <p>Everything at Smarter Math is based on the State Standards.  These are the same standards that students are evaluated on for the Caaspp exam.  By aligning our curriculums with the state standard, we can guarantee the most effective learning platform available.</p>
        <!--<p><a class="btn btn-secondary" href="#" role="button">View details »</a></p>-->
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">
        <img class="rounded-circle" src="data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Generic placeholder image" width="140" height="140">
        <h2>How It Works</h2>
        <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh.</p>
        <!--<p><a class="btn btn-secondary" href="#" role="button">View details »</a></p>-->
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">
        <img class="rounded-circle" src="data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Generic placeholder image" width="140" height="140">
        <h2>Success Stories</h2>
        <p>Donec sed odio dui. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Vestibulum id ligula porta felis euismod semper. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.</p>
        <!--<p><a class="btn btn-secondary" href="#" role="button">View details »</a></p>-->
      </div><!-- /.col-lg-4 -->
    </div><!-- /.row -->


    <!-- START THE FEATURETTES -->
    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading">Math Redesigned<br/><span class="text-muted">No Other Prep System Can Do What We Do!</span></h2>
        <p class="lead">Unlike traditional online systems that are designed by engineers, Smarter Math was designed from the ground up by certified educators.  This means that we have more of the actual hand on content that you need to succeed.</p>
      </div>
      <div class="col-md-5">
        <img class="featurette-image img-fluid mx-auto" data-src="holder.js/500x500/auto" alt="500x500" style="width: 500px; height: 500px;" src="/assets/images/NewDesign.png" data-holder-rendered="true">
      </div>
    </div>

    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7 order-md-2">
        <h2 class="featurette-heading">Created By Certified Math Teachers</h2>
        <p class="lead">When we say that the system was designed by Certified match teachers we mean completely designed.  Every section of the system was engineered to maximize the learning protentional of every exercise.  State standards are at the core of the learning curriculum and through AI are able to adjust as your knowledge increases.</p>
      </div>
      <div class="col-md-5 order-md-1">
        <img class="featurette-image img-fluid mx-auto" data-src="holder.js/500x500/auto" alt="500x500" src="/assets/images/teachers.png" data-holder-rendered="true" style="width: 500px; height: 500px;">
      </div>
    </div>

    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading">Individualized Tailored Curriculum<br/><span class="text-muted">As Individual As You Are</span></h2>
        <p class="lead">By leveraging a customized curriculum an educator along with the built in AI engine is able to tailor each and every learning path to the needs of the students.  Gone are the days of one curriculum fits all.  We provide a learning path curriculum as individual as each student.</p>
      </div>
      <div class="col-md-5">
        <img class="featurette-image img-fluid mx-auto" data-src="holder.js/500x500/auto" alt="500x500" src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22500%22%20height%3D%22500%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20500%20500%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_173c7156f33%20text%20%7B%20fill%3A%23AAAAAA%3Bfont-weight%3Abold%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A25pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_173c7156f33%22%3E%3Crect%20width%3D%22500%22%20height%3D%22500%22%20fill%3D%22%23EEEEEE%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22185.1171875%22%20y%3D%22261.1%22%3E500x500%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" data-holder-rendered="true" style="width: 500px; height: 500px;">
      </div>
    </div>
    <hr class="featurette-divider">
    <!-- /END THE FEATURETTES -->
  </div><!-- /.container -->

  <!-- FOOTER -->
  <footer class="container">
    <p class="float-right"><a href="#">Back to top</a></p>
    <p>© 2020 Smarter Math · <a href="#">Privacy</a> · <a href="#">Terms</a></p>
  </footer>
</main>


