<script>
  export let close_callback;
  export let show_ask;
  export let student_id;
  export let exam_id;
  export let question_id;
  export let assignment_id;

  import Popup from "./Popup.svelte";
  import {saveExamMessage} from "../db/messages";


let subject="";
let message="";
 
async function saveNewMessage() {
  let f = await saveExamMessage(subject, message, assignment_id, exam_id, question_id)
  alert("Message Sent");
  subject="";
  message="";
  show_ask = false;
}

</script>
<style>

</style>

<Popup {close_callback} show_it={show_ask}>
  <div slot="header">ASK YOUR TEACHER</div>
  <form style="width:800px;">
    <div class="form-group">
      <label for="subject">Subject</label>
      <input bind:value={subject} type="text" class="form-control" id="subject" placeholder="Can you help me understand ....">
    </div>
    <div class="form-group">
      <label for="message">Message</label>
      <textarea bind:value={message} class="form-control" id="message" rows="10"></textarea>
    </div>
  </form>
  <div style="border-top:black solid 1px; padding-top:10px; margin-top:10px; text-align:center;">
    <button class="btn btn-primary" on:click={()=>saveNewMessage()}>Send Message</button>
  </div>
</Popup>