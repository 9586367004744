<script>
  export let location;
  location = location;

  import StudentSideBar from "./StudentSideBar.svelte";
</script>

<h1>
  
</h1>


<StudentSideBar>
  Welcome to Student Dashboard.
  

</StudentSideBar>
