<script>
  export let question;
  export let on_update;
  export let hintVisible;
  export let last_question;
  export let last_answer;
  export let correctGiven;
  
  import MultipleChoice from "./MultipleChoice.svelte";
  import TrueFalse from "./TrueFalse.svelte";
  import FillSmall from "./FillSmall.svelte";
  import Plot2d from "./Plot2d.svelte";
  import ShortAnswer from "./ShortAnswer.svelte";
  import Match from "./Match.svelte";

</script>

<style>

</style>
<div class="p-3">
  {#if question.type == 'multiple-choice'}
    <MultipleChoice {question} {on_update} />
  {:else if question.type == 'true-false'}
    <TrueFalse {question} {on_update} />
  {:else if question.type == 'fill-small'}
    <FillSmall {question} {on_update} />
  {:else if question.type == 'short-answer'}
    <ShortAnswer {question} {on_update} />
  {:else if question.type == 'plot-2d-lines'}
    <Plot2d {question} {on_update} />
  {:else if question.type == 'plot-2d-points'}
    <Plot2d {question} {on_update} />
  {:else if question.type == 'match'}
    {#if question.index % 2 == 0}
      <Match {question} {on_update} />
    {:else}
      <Match {question} {on_update} />
    {/if}
  {:else if question.scenario}
    {@html question.scenario}
  {:else}
    <pre>TODO {question.type}</pre>
  {/if}
</div>
<div>

  <div>
    {#if correctGiven == true}
       <!-- content here -->
       <p style="color: green;">
        That's correct, great work!
       </p>
    {:else if correctGiven == false}
       <!-- else content here -->
       <p style="color: red;">
        Incorrect, please try again.
       </p>
    {/if}
  </div>

<div id="hintWindow" class="card" style="visibility: {hintVisible};">
  <div class="card-header" style="background-color: #0084ff; color: white; font-weight: bold; font-size:14pt;">
    <i class="fas fa-lightbulb-on"></i>&nbsp;Hint
  </div>
  <div class="card-body">
    <p class="card-text">{@html question.hints[0].hint}</p>
  </div>
  </div>  
</div>
