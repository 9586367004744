




<section>
    <h3>Create Long Question</h3>
    
     <div>
        <div class="form-group">
            <label for="exampleFormControlTextarea1">Write your question ?</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>
     </div>
    
     <div >
        <div class="form-group">
            <label for="formGroupExampleInput">Write Correct Answare</label>
            <textarea rows="2" type="text" class="form-control" id="formGroupExampleInput" placeholder="Example input"></textarea>
          </div>
     </div>
    
     <div >
         <button class='btn btn-primary'>Submit</button>
     </div>
    </section>