<script>
	import { onMount } from 'svelte';
  export let location;
  location = location;
  export let class_id;
  export let assignment_id;

  let assign ;
  let students=[];
  let score=[]

  import { link, navigate } from "svelte-routing";


  import { get_student_answers } from "../db/exams";
  import { get_assignment , all_assignment , get_student_by_id} from "../db/general"
  import TeacherSideBar from "./TeacherSideBar.svelte";
  import TeacherDashboardHeader from './TeacherDashboardHeader.svelte'

  onMount(async ()=>{
   let  allAss  = await all_assignment()

   let a = allAss.filter(f => f.id == assignment_id )
   assign = a[0]


   setTimeout(() => {
   studentList()
     
   }, 1000);



  })

  async function studentList(){
    if(assign){
      if(assign.students.length > 0){
        let d=[]
        assign.students.forEach(async element => {
          if(element){
            let s= await get_student_by_id(element)
          if(s){
            console.log(s)
            d.push(s)
          }
          }
         
        });
        setTimeout(() => {
          console.log(d)
          students=d

          let g=[]

          students.forEach( async (a)=>{
            let v= await get_student_answers(assign.id , a.id)

            if(v){
              g.push(v)
            }
          })

          setTimeout(() => {
            score = g
          }, 2000);
          
     
        }, 1500);
       
      }
    }
  }

  // $: console.log(assignment_id)

</script>

<TeacherSideBar {class_id} >

<div class="cointainer" style="margin-top:50px">
 
  {#if assign}
     <!-- content here -->
     <h1>Preview:</h1>
     <h3>{assign.name}</h3>
<div class="table-responsive">
  <table class="table table-hover" >
    <tr>
      <th class="p-2">Student</th>
      <th class="p-2" >Completed</th>
      <th class="p-2" >Correct</th>
      <th class="p-2" >Total</th>
      <th class="p-2" >Action</th>


    </tr>

      {#if students}
         <!-- content here -->
        {#each students as item}
           <!-- content here -->

           {#each score as s}
              <!-- content here -->
              {#if item.id == s.id}
                 <!-- content here -->
                 <tr>
                  <td>{item.email}</td>
                  <td>
                    {#if s.totalQuestions}
                       <!-- content here -->
                       {s.totalQuestions}
                    {:else}
                       <!-- else content here -->
                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                    {/if}
                    
                  </td>
                  <td>
                    {#if s.correctAnswers}
                    <!-- content here -->
                    {s.correctAnswers}
                 {:else}
                    <!-- else content here -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                     <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                   </svg>

                 {/if}
                   
                  
                  </td>

                  <td>

                    {#if s.percentageCorrect}
                    <!-- content here -->
                    {s.percentageCorrect}
                 {:else}
                    <!-- else content here -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                     <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                   </svg>
                 {/if}
                    
                  
                  
                  </td>
                  <td>
                    <!-- svelte-ignore missing-declaration -->
                    {#if s.percentageCorrect }
                       <!-- content here -->
                       <a class='btn btn-primary' href='/test/report/{assignment_id}/{item.id}' use:link>
                        Answer
                      </a>
                    {:else}
                       <!-- else content here  href='/test/report/{assignment_id}/{item.id}'-->
                       <button class='btn btn-primary'    use:link disabled>
                        Answer
                       </button>
                    {/if}

                  </td>

   
                </tr>
              {/if}
           {/each}

         
        {/each}
      {/if}

    </table>
</div>

  {/if}

</div>
 

  <!-- {#await get_assignment(assignment_id)}
    <div class="spinner-border text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  {:then assignment}
    <h1>Preview:</h1>
    <h3>{assignment.name}</h3>
    <table>
      <tr>
        <th class="p-2">Student</th>
        <th class="p-2" style="text-align: right">Completed</th>
        <th class="p-2" style="text-align: right">Correct</th>
        <th class="p-2" style="text-align: right">Total</th>
      </tr>
       {#each assignment.student_emails as student_email}
        {#await get_student_answers(assignment_id, student_email)}
          <tr>
            <td>
              <div class="spinner-border text-warning" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </td>
          </tr>
        {:then answers}
          <tr>
            <td class="p-2">{student_email}</td>
            <td class="p-2" style="text-align: right">
              {answers.answered || ''}
            </td>
            <td class="p-2" style="text-align: right">
              {answers.correct || ''}
            </td>
            <td class="p-2" style="text-align: right">{answers.total || ''}</td>
          </tr>
        {:catch error}
          <div class="alert alert-danger">{error.message}</div>
        {/await}
      {/each} 
    </table>
  {:catch error}
    <div class="alert alert-danger">{error.message}</div>
  {/await} -->
</TeacherSideBar>
