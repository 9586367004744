<script>
  export let location;
  location = location;

  import { onMount } from "svelte";

  const MQ = MathQuill.getInterface(2);

  let answerSpan;
  let answerMathField;
  onMount(() => {
    answerMathField = MQ.MathField(answerSpan, {
      handlers: {
        edit: function() {
          const enteredMath = answerMathField.latex();
          console.log(enteredMath);
        }
      }
    });
  });

  function type(something) {
    answerMathField.cmd(something);
  }
</script>

<style>

</style>

<p>
  <span bind:this={answerSpan}>x=</span>
</p>
<p>
  <button class="btn btn-primary" on:click={() => type('0')}>0</button>
  <br />
  <button class="btn btn-primary" on:click={() => type('1')}>1</button>
  <button class="btn btn-primary" on:click={() => type('2')}>2</button>
  <button class="btn btn-primary" on:click={() => type('3')}>3</button>
  <br />
  <button class="btn btn-primary" on:click={() => type('4')}>4</button>
  <button class="btn btn-primary" on:click={() => type('5')}>5</button>
  <button class="btn btn-primary" on:click={() => type('6')}>6</button>
  <br />
  <button class="btn btn-primary" on:click={() => type('7')}>7</button>
  <button class="btn btn-primary" on:click={() => type('8')}>8</button>
  <button class="btn btn-primary" on:click={() => type('9')}>9</button>
  <br />
</p>

<p>
  <button class="btn btn-primary" on:click={() => type('\\sqrt')}>sqrt</button>
</p>

<p>
  <button class="btn btn-primary" on:click={() => type('\\frac')}>fact</button>
</p>
