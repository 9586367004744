<script>
  import { link, navigate } from "svelte-routing";
  import { list_student_courses, list_teacher_classes, get_student, get_teacher } from "./db/general";
  import { course_id } from './store.js';

  import {
    sign_out,
    auth_store,
    current_user_email,
    is_teacher,
    is_student,
  } from "./auth.js";

  async function logout() {
    await sign_out();
    navigate("/");
  }

  async function settings() {
    navigate("/my/settings");
  }

  async function help() {
    alert("I'm sorry but help is not yet imlemented in the beta version.")
  }

  function setActiveClass(type, classRef) {
    console.log("classRef", classRef);
    if(classRef) {
      activeCourse = classRef;
      course_id.set(classRef.id);
      // When classes are changed it is necessary to go back to the home page for that new class selection
      if(type==="TEACHER") {
        alert("TEACHER");
        navigate("/" + classRef.id);
      }else if(type==="STUDENT") {
        alert("STUDENT");
        navigate("/" + classRef.id);
      }
    }
  }

  let activeCourse = {}

</script>

<style>
  .tableContainer {
    width:100%;
    position: relative;
    
  }
  .loginLogout {
   text-align: right;
  }

  .clearfix {
    overflow: auto; 
  }

  .searchText {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    background-color: transparent;
    color: white;
  }

  .searchText:focus {
     outline: none;
  }

  .student-name-menu-item {
    color: white;
  }

  .student-name-menu-item:hover {
    background-color: #0a5192;
  }
</style>

<header>
  <nav class="navbar navbar-expand-md fixed-top" style="background-color:#89c6ff;">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

      {#if $auth_store}
        {#if is_student($auth_store)}
          {#await list_student_courses()}
              <div class="spinner-border text-warning" role="status">
                <span class="sr-only">Loading...</span>
              </div>
          {:then courses}
            <div class="dropdown mr-auto">
              <button style="color:white; font-weight:bold;" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Courses
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="background-color:#0084ff;">
                {#each courses as crs, courseIndex}
                  <a class="dropdown-item student-name-menu-item" href="#">
                  <div style="border-bottom:solid 1px #efefef; padding-bottom:5px;" >{crs.class.name}<br/>{crs.name}</div>
                  </a>
                {/each}
              </div>
            </div>
          {:catch error}
            <div class="alert alert-danger">{error.message}</div>
          {/await}
        {:else if is_teacher($auth_store)}
          {#await list_teacher_classes()}
              <div class="spinner-border text-warning" role="status">
                <span class="sr-only">Loading...</span>
              </div>
          {:then courses}
            {setActiveClass("TEACHER",courses[0]) ?? ""}
            <div class="dropdown mr-auto">
              <button style="color:white; font-weight:bold;" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {activeCourse.name}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="background-color:#0084ff;">
                {#each courses as crs}
                  <!-- svelte-ignore a11y-missing-attribute -->
                  <a class="dropdown-item student-name-menu-item" on:click={()=>setActiveClass("TEACHER",crs)}>
                  <div style="border-bottom:solid 1px #efefef; padding-bottom:5px;">{crs.courseName}<br/>{crs.name}</div>
                  </a>
                {/each}
              </div>
            </div>
          {:catch error}
            <div class="alert alert-danger">{error.message}</div>
          {/await}
        {/if}

      {:else}
        <div class="mr-auto" style="font-size:14pt; color: white;">SMARTER MATH</div>
      {/if}

      {#if $auth_store}
      <div class="mr-auto">
        <div style="border-radius:30px; border:solid 1px white; width:250px;">
          <span style="margin-left:10px;"><i class="fal fa-search" style="color:white;"></i></span>
          <span>
            <input type="text" class="searchText"/>
          </span>
        </div>
      </div>
      {/if}

      {#if $auth_store}
            <div class="dropdown" >
                <!-- STUDENT LOGGED IN -->
                {#if is_student($auth_store)}
                  <button style="color:white; font-weight:bold;" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {#await get_student(current_user_email())}
                      <div class="spinner-border text-warning" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                  {:then student}
                      {student.firstName}&nbsp;{student.lastName}
                  {:catch error}
                    <div class="alert alert-danger">{error.message}</div>
                  {/await}
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="background-color:#0084ff;">
                    <a class="dropdown-item student-name-menu-item" on:click={help}>Help</a>
                    <a class="dropdown-item student-name-menu-item" on:click={settings}>Settings</a>
                    <a class="dropdown-item student-name-menu-item" on:click={logout}><i class="fad fa-sign-out"></i>&nbsp;Logout</a>
                  </div>
                {:else}
                <!-- TEACHER LOGGED IN -->
                  <button style="color:white; font-weight:bold;" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {#await get_teacher(current_user_email())}
                      <div class="spinner-border text-warning" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    {:then teacher}
                      {teacher.firstName}&nbsp;{teacher.lastName}
                    {:catch error}
                      <div class="alert alert-danger">{error.message}</div>
                  {/await}
                  </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" on:click={logout}><i class="fad fa-sign-out"></i>&nbsp;Logout</a>
            </div>
                {/if}
          </div>
      {:else}
            <button
              class="btn btn-primary"
              style="border-radius: 20px; width:200px;"
              data-toggle="modal"
              data-target="#login-modal">
              <i class="fas fa-sign-in-alt"></i>&nbsp;Login
            </button>
      {/if}
  </nav>
</header>
