<script>
	import { each } from 'svelte/internal';
	import { onMount } from 'svelte';
  export let location;
  location = location;
  export let class_id;
  let all_assign=[];

  import { get_class, new_assignment, all_assignment } from "../db/general";
  import TeacherSideBar from "./TeacherSideBar.svelte";
  import TeacherDashboardHeader from "./TeacherDashboardHeader.svelte";
  
  import {get_testBank_sections} from "../db/curriculum.js";

  import { link, navigate } from "svelte-routing";


  let test_name;
  let due_date;
  let due_time;
  let students;

  onMount(async ()=>[
    all_assign = await all_assignment()
  ])

  function set_test_name(_test_name) {
    test_name = _test_name;
  }

  function select_all() {
    for (const student of students) {
      student.selected = true;
    }
    students = students;
  }
  function select_none() {
    for (const student of students) {
      student.selected = false;
    }
    students = students;
  }

  async function fetch_class() {
    const cls = await get_class(class_id);
    students = cls.student_emails.map(email => ({ email, selected: true }));
    return cls;
  }

  async function assign() {
    document.getElementById("assign-modal-close").click();
    const student_emails = students
      .filter(student => student.selected)
      .map(student => student.email);
    console.log("@test_name", test_name);
    console.log("@due_date", due_date);
    console.log("@due_time", due_time);
    console.log("@student_emails", student_emails);
    await new_assignment({
      class_id,
      test_name,
      due_date,
      due_time,
      student_emails
    });
  }

async function  dueDateToString(d){
  // console.log(d)
   return  new Date(d.seconds *1000).toDateString()
  }
</script>

<style>

.subjectHeader {
    background-color: #676d74;
    height:30px;
    vertical-align: middle;
    border-bottom: black solid 1px;
  }

  .subjectTitle {
    font-size:12pt;
    font-weight: bold;
    color: white;
  }

  .topicHeader {
    background-color: #88939f;
    height:30px;
    border-bottom: black solid 1px;
  }

  .topicTitle {
    font-size:12pt;
    font-weight: bold;
    color: white;
  }

  .sectionHeader {
    background-color: #cfcfcf;
    height:30px;
    border-bottom: black solid 1px;
  }

  .menuItem:not(.collapsed) .arrow {
    -webkit-transition: all 150ms ease-in 0s;
    -moz-transition: all 150ms ease-in 0s;
    -o-transition: all 150ms ease-in 0s;
    -ms-transition: all 150ms ease-in 0s;
    transition: all 150ms ease-in 0s;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);   
  }

  .menuItem.collapsed .arrow {
    -webkit-transition: all 150ms ease-in 0s;
    -moz-transition: all 150ms ease-in 0s;
    -o-transition: all 150ms ease-in 0s;
    -ms-transition: all 150ms ease-in 0s;
    transition: all 150ms ease-in 0s; 
  }

  .menuItem .arrow{
    width:18px; 
    height:18px;
    margin-left:5px;
    margin-top:5px;
    text-align:center;
    color: white;
  }

  
</style>

<TeacherSideBar {class_id} location={location} activeOption={'ASSIGNMENT_ASSIGN'}>
  
  <TeacherDashboardHeader title="Assign Content" 
    writeUp="When you assign specific content to a student, those learning materials will show up in their home portal under 'Assignments'"
    ></TeacherDashboardHeader>

  <div>
  {#await get_testBank_sections()}
    <div class="spinner-border text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  {:then subject}
    {#each subject.subjects as sbj, index}
    <div>
      <div class="subjectHeader" >
        <div class="menuItem collapsed" data-toggle="collapse" href="#subject{index}" role="button" aria-expanded="false" aria-controls="subject{index}">
          <i class="arrow far fa-arrow-circle-right"></i>
          <span class="subjectTitle">&nbsp;{sbj.subject}</span>
        </div>
      </div>
      <div class="collapse" id="subject{index}">
        <div>
          <!--  Topics ----------------------------------------------------------------------------------------------->
          {#each sbj.topics as topic, topicIndex}
          <div >
            <div class='topicHeader' style="padding-left:30px;">
            <div class="menuItem collapsed" data-toggle="collapse" href="#topic{topicIndex}" role="button" aria-expanded="false" aria-controls="topic{topicIndex}">
              <i class="arrow far fa-arrow-circle-right"></i>
              <span class="topicTitle">&nbsp;{topic.topic}</span>
            </div>
          </div>
            <div class="collapse" id="topic{topicIndex}">
              <div>
                <!--  Sections ----------------------------------------------------------------------------------------------->
                {#if topic.sections}
                  {#each topic.sections as sct, sectionIndex}
                    <div >
                      <div class='sectionHeader' style="padding-left:60px;">
                      <div class="menuItem collapsed" data-toggle="collapse" href="#section{sectionIndex}" role="button" aria-expanded="false" aria-controls="section{sectionIndex}">
                        <i class="arrow far fa-arrow-circle-right" style="color:black;"></i>
                        <span class="sectionTitle">&nbsp;{sct.section}</span>
                      </div>
                    </div>
                      <div class="collapse" id="section{sectionIndex}"> 
                        <div>
                          STUFF
                        </div>
                      </div>
                    </div>  
                  {/each}
                {/if}
                <!---------------------------------------------------------------------------------------------------------->
              </div>
            </div>
          </div>
          {/each}
          <!---------------------------------------------------------------------------------------------------------->
        </div>
      </div>
    </div>

    <!--<TeacherAssignNode node={index} {set_test_name} />-->
    {/each}
  {:catch error}
    <!-- <div class="alert alert-danger">{error.message}</div> -->
  {/await}
  </div>


  <!-- Add section all assigmnet  -->
<section  style="padding:20px ;">
  <h4>
    <strong>
      All Assigmnet List:-
    </strong>

  </h4>

  <div class="table-responsive table-hover">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Assignment Name</th>
          <th scope="col">Dua Date</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        {#each all_assign as item, i}
           <!-- content here -->

           <tr>
            <th scope="row">{i+1}</th>
            <td>{item.name}</td>
            <td>
            {#await dueDateToString(item.dueDate)}
              <!-- promise is pending -->
            {:then value}
            {value}
              <!-- promise was fulfilled -->
            {:catch error}
              <!-- promise was rejected -->
            {/await}
            </td>
            <td>
              <!-- /class/{item.class_id}/assignment/{item.id} svelte-ignore missing-declaration /class/assign/:asign_id/:class_id -->
              <a href="/class/assign/{item.id}/{item.class_id}"  use:link>View</a>
            </td>
          </tr>
        {/each}
      
     
    </table>
  </div>
</section>



</TeacherSideBar>

<div
  id="assign-modal"
  class="modal fade"
  tabindex="-1"
  aria-labelledby="assign-modal-label"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="assign-modal-label">Assign to Students</h5>
        <button
          id="assign-modal-close"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form on:submit|preventDefault={assign}>
        <div class="modal-body">

          <div class="row g-3">
            <div class="col-auto">
              <label for="due-date">Due Date</label>
              <input id="due-date" type="date" required bind:value={due_date} />
            </div>
          </div>
          <br />
          <div class="row g-3">
            <div class="col-auto">
              <label for="due-time">Due Time</label>
              <input id="due-time" type="time" required bind:value={due_time} />
            </div>
          </div>
          <br />

          {#await fetch_class()}
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          {:then cls}
            <table>
              <tr>
                <th>Student Email</th>
                <th>
                  <button
                    type="button"
                    class="btn btn-link"
                    on:click={select_all}>
                    All
                  </button>
                  <button
                    type="button"
                    class="btn btn-link"
                    on:click={select_none}>
                    None
                  </button>
                </th>
              </tr>
              {#each students as student}
                <tr>
                  <td>{student.email}</td>
                  <td style="text-align: center">
                    <input type="checkbox" bind:checked={student.selected} />
                  </td>
                </tr>
              {/each}
            </table>
          {:catch error}
            <div class="alert alert-danger">{error.message}</div>
          {/await}

        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary">Assign</button>
        </div>
      </form>
    </div>
  </div>
</div>


