const db = firebase.firestore();
import {current_user_id} from '../auth';
import {as_object, as_array, as_single_object} from './dbUtil'

export async function get_testBank_sections() { 
    let subjects = {subjects:[]};
    let masterList = as_object(await db.collection("testBankSections").doc("TestBankMaster").get());
    for(let i=0; i < masterList.subjects.length; i++) {
        var subject = Object.keys(masterList.subjects[i])[0];
        let newSubject = {subject: subject, topics: []};
        subjects.subjects.push(newSubject);
        for(let j=0; j < masterList.subjects[i][subject].sections.length; j++) {
            let sectionId = masterList.subjects[i][subject].sections[j];
            try{
            let sect = as_object(await db.collection("testBankSections").doc(sectionId).get());
                newSubject.topics.push(sect);        
            } catch (e) {
                console.log("error", e);
            }
        }
    }
    return subjects;
}