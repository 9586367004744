<script>
  export let location;
  location = location;

  import Question from "../questions/Question.svelte";

  const test_path = decodeURIComponent(window.location.search.slice(1));

  async function fetch_test() {
    const response = await fetch("/data/" + test_path + ".json");
    return await response.json();
  }

  function on_update(question, my_answer) {
    console.log(
      "@on_update",
      question.id,
      question.type,
      JSON.stringify(my_answer)
    );
  }
</script>

<div class="container">
  <h1>Preview:</h1>
  <h3>{test_path}</h3>

  {#await fetch_test()}
    <div class="spinner-border text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  {:then test}
    {#each test as question}
      <div class="card">
        <Question {question} {on_update} />
        <div class="card-footer">
          <pre>{JSON.stringify(question.answer)}</pre>
        </div>
      </div>
      <br />
    {/each}
  {:catch error}
    <div class="alert alert-danger">{error.message}</div>
  {/await}

</div>
