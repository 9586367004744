<script>
  export let close_callback;
  export let show_it;

  import { onMount } from "svelte";

  let popup_div;

  function on_down(down_event) {
    const start_offset_x = popup_div.offsetLeft;
    const start_offset_y = popup_div.offsetTop;
    function on_move(move_event) {
      popup_div.style.left =
        start_offset_x + move_event.clientX - down_event.clientX + "px";
      popup_div.style.top =
        start_offset_y + move_event.clientY - down_event.clientY + "px";
    }
    function on_up(event) {
      window.removeEventListener("mousemove", on_move);
      window.removeEventListener("mouseup", on_up);
    }
    window.addEventListener("mousemove", on_move);
    window.addEventListener("mouseup", on_up);
  }
  onMount(() => {
    popup_div.addEventListener("mousedown", on_down);
    return () => {
      popup_div.removeEventListener("mousedown", on_down);
    };
  });
</script>

<style>
  .popup {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 0.5em;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
    padding: 1em;
    position: fixed;
    z-index: 12;
    position: fixed;
    top: 0;
    left: 0;
    transform: scale(0, 0);
    transition: 0.2s transform ease-in-out;
    position: fixed;
    left: 16em;
    top: 5em;
  }
  .popup.show-it {
    transform: scale(1, 1);
  }
  .header {
    height: 2em;
    line-height: 2em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    white-space: nowrap;
    margin-bottom: 1em;
    margin-left: -1em;
    margin-right: -1em;
    padding-left: 1em;
    padding-right: 3em;
    cursor: pointer;
    user-select: none;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0.5em;
    cursor: pointer;
    font-size: 2em;
    opacity: 0.5;
  }
  .close:hover {
    color: var(--nice-color);
    opacity: 1;
  }
</style>

<div class="popup yo-col" bind:this={popup_div} class:show-it={show_it}>
  <div class="header" on:mousedown={on_down}>
    <slot name="header" />
    <div class="close" on:click={() => close_callback()}>⨯</div>
  </div>
  <slot />
</div>
