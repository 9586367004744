<script>
	import CreateAssignment from './teacher/createAssignment.svelte';
	import Answers from './teacher/answers.svelte';

  import { auth_store, is_teacher, is_student, is_nobody } from "./auth.js";
  import { Router, Route } from "svelte-routing";
  import Login from "./Login.svelte";
  import NobodyHomePage from "./NobodyHomePage.svelte";
  import StudentAssignmentReportPage from "./student/StudentAssignmentReportPage.svelte";
  import StudentAssignmentTestPage from "./student/StudentAssignmentTestPage.svelte";
  import StudentClassAssignmentsPage from "./student/StudentClassAssignmentsPage.svelte";
  import StudentHomePage from "./student/StudentHomePage.svelte";
  import StudentLearningPathwayPage from "./student/StudentLearningPathwayPage.svelte";
  import StudentReportsPage from "./student/StudentReportsPage.svelte";
  import StudentSettingsPage from "./student/StudentSettingsPage.svelte";
  import TeacherAssignPage from "./teacher/TeacherAssignPage.svelte";
  import TeacherAssignmentBuilder from "./teacher/TeacherAssignmentBuilder.svelte";
  import TeacherClassAssignmentPage from "./teacher/TeacherClassAssignmentPage.svelte";
  import TeacherClassDataPage from "./teacher/TeacherClassDataPage.svelte";
  import TeacherClassPage from "./teacher/TeacherClassPage.svelte";
  import TeacherHomePage from "./teacher/TeacherHomePage.svelte";
  import TeacherManagePage from "./teacher/TeacherManagePage.svelte";
  import TeacherPreviewPage from "./teacher/TeacherPreviewPage.svelte";
  import TeacherScoresPage from "./teacher/TeacherScoresPage.svelte";
  import TeacherSettingsPage from "./teacher/TeacherSettingsPage.svelte";
  import TeacherStudentDataPage from "./teacher/TeacherStudentDataPage.svelte";
  import TeacherStudentsPage from "./teacher/TeacherStudentsPage.svelte";
  import TeacherTestMathInputPage from "./teacher/TeacherTestMathInputPage.svelte";
  import ImportQuestions from "./teacher/ImportQuestions.svelte";
  import SystenAdminStandards from "./teacher/SystemAdminStandards.svelte";

  import Top from "./Top.svelte";
  import { component_subscribe } from "svelte/internal";
import TeacherClassProgress from './teacher/TeacherClassProgress.svelte';
import TeacherAssignmentDetails from './teacher/TeacherAssignmentDetails.svelte';

</script>

<Top/>
<Login/>
<main>
  <Router>
    {#if is_nobody($auth_store)}
      <Route path="/" component={NobodyHomePage} />
    {/if}
    {#if is_teacher($auth_store)}
      <Route path="/class/import" component={ImportQuestions} />
      <Route path="/:class_id" component={TeacherHomePage}/> 
      <Route path="/class/:class_id" component={TeacherClassPage} />
      <Route path="/class/:class_id/manage" component={TeacherManagePage} />
      <Route path="/class/:class_id/assign" component={TeacherAssignPage} />
      <Route path="/class/:class_id/scores" component={TeacherScoresPage} />
      <Route path="/class/:class_id/student-data" component={TeacherStudentDataPage} />
      <Route path="/class/:class_id/class-data" component={TeacherClassDataPage} />
      <Route path="/class/:class_id/settings" component={TeacherSettingsPage} />
      <Route path="/class/:class_id/students" component={TeacherStudentsPage} />
      <Route path="/preview" component={TeacherPreviewPage} />
      

      <Route path="/class/:class_id/assignment/:assignment_id" component={TeacherClassAssignmentPage} />

      <Route path="/asignment/buildAssignments" component={TeacherAssignmentBuilder} />
      <Route path="/asignment/build/now" component={CreateAssignment} />


      <Route path="/test/math-input" component={TeacherTestMathInputPage} />
      <Route path="/class/progress/:class_id" component={TeacherClassProgress} />
      <Route path="/class/assign/:asign_id/:class_id" component={TeacherAssignmentDetails} />


      <Route path="/systemadmin/standards" component={SystenAdminStandards} />
      <Route path="/test/report/:assignment_id/:class_id" component={Answers} />

    {/if}
    {#if is_student($auth_store)}
      <Route path="/:class_id" component={StudentHomePage} />
      <Route
        path="/my/learning-pathway"
        component={StudentLearningPathwayPage} />
      <Route
        path="/my/class-assignments"
        component={StudentClassAssignmentsPage} />
      <Route path="/my/reports" component={StudentReportsPage} />
      <Route path="/my/settings" component={StudentSettingsPage} />
      <Route
        path="/assignment/:assignment_id/test"
        component={StudentAssignmentTestPage} />
      <Route
        path="/assignment/:assignment_id/report"
        component={StudentAssignmentReportPage} />
    {/if}
  </Router>
</main>
