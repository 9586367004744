<script>

    import {saveQuestion} from "../db/Import/import.js"
    import {new_student} from "../db/general.js"

    import {buildTestByTopicID, buildTestBySectionID} from "../db/exams.js"

    export async function buildFakeStudents(howMany) {
        
        let names = [
    {
      "firstName": "Knight",
      "lastName": "Madden"
    },
    {
      "firstName": "Helene",
      "lastName": "Garrett"
    },
    {
      "firstName": "Jeannine",
      "lastName": "Woodard"
    },
    {
      "firstName": "Benson",
      "lastName": "Mcguire"
    },
    {
      "firstName": "Robinson",
      "lastName": "Wilson"
    },
    {
      "firstName": "Charlotte",
      "lastName": "Stevenson"
    },
    {
      "firstName": "Elise",
      "lastName": "Ward"
    },
    {
      "firstName": "Ford",
      "lastName": "Chandler"
    },
    {
      "firstName": "Farley",
      "lastName": "Bradford"
    },
    {
      "firstName": "Zimmerman",
      "lastName": "Mercado"
    },
    {
      "firstName": "Arlene",
      "lastName": "Bullock"
    },
    {
      "firstName": "Dixie",
      "lastName": "Barber"
    },
    {
      "firstName": "Flora",
      "lastName": "Holden"
    },
    {
      "firstName": "Rose",
      "lastName": "England"
    },
    {
      "firstName": "Arnold",
      "lastName": "Nielsen"
    },
    {
      "firstName": "Patti",
      "lastName": "Dale"
    },
    {
      "firstName": "Berta",
      "lastName": "Leon"
    },
    {
      "firstName": "Edwina",
      "lastName": "Ray"
    },
    {
      "firstName": "Marissa",
      "lastName": "Schmidt"
    },
    {
      "firstName": "Myrna",
      "lastName": "Galloway"
    },
    {
      "firstName": "Kerry",
      "lastName": "Grimes"
    },
    {
      "firstName": "Ava",
      "lastName": "Gutierrez"
    },
    {
      "firstName": "Luna",
      "lastName": "Deleon"
    },
    {
      "firstName": "Shaffer",
      "lastName": "Elliott"
    },
    {
      "firstName": "Kane",
      "lastName": "Carlson"
    },
    {
      "firstName": "Jaime",
      "lastName": "Pope"
    },
    {
      "firstName": "Shari",
      "lastName": "Harper"
    },
    {
      "firstName": "Monica",
      "lastName": "Sweeney"
    },
    {
      "firstName": "Flynn",
      "lastName": "Petty"
    },
    {
      "firstName": "Bettye",
      "lastName": "Goff"
    },
    {
      "firstName": "Ross",
      "lastName": "Jordan"
    },
    {
      "firstName": "Cleo",
      "lastName": "Carter"
    },
    {
      "firstName": "Rivers",
      "lastName": "Murray"
    },
    {
      "firstName": "Sykes",
      "lastName": "Blankenship"
    },
    {
      "firstName": "Whitfield",
      "lastName": "Fisher"
    },
    {
      "firstName": "Nicole",
      "lastName": "Strong"
    },
    {
      "firstName": "Chavez",
      "lastName": "Santiago"
    },
    {
      "firstName": "Case",
      "lastName": "Hammond"
    },
    {
      "firstName": "Lilian",
      "lastName": "Baldwin"
    },
    {
      "firstName": "Kirk",
      "lastName": "Mercer"
    },
    {
      "firstName": "Dawson",
      "lastName": "Berger"
    },
    {
      "firstName": "Galloway",
      "lastName": "Henry"
    },
    {
      "firstName": "Terrie",
      "lastName": "Holder"
    },
    {
      "firstName": "Hamilton",
      "lastName": "Whitehead"
    },
    {
      "firstName": "Joy",
      "lastName": "Benton"
    },
    {
      "firstName": "Schultz",
      "lastName": "Salinas"
    },
    {
      "firstName": "Davidson",
      "lastName": "Meyer"
    },
    {
      "firstName": "Jensen",
      "lastName": "Fletcher"
    },
    {
      "firstName": "Odom",
      "lastName": "Rose"
    },
    {
      "firstName": "Walls",
      "lastName": "Rice"
    },
    {
      "firstName": "Ada",
      "lastName": "Johnson"
    },
    {
      "firstName": "Ochoa",
      "lastName": "Gonzalez"
    },
    {
      "firstName": "Good",
      "lastName": "Riddle"
    },
    {
      "firstName": "Hope",
      "lastName": "Hall"
    },
    {
      "firstName": "Eddie",
      "lastName": "Torres"
    },
    {
      "firstName": "Roy",
      "lastName": "Meadows"
    },
    {
      "firstName": "Leticia",
      "lastName": "Duran"
    },
    {
      "firstName": "Holloway",
      "lastName": "Woods"
    },
    {
      "firstName": "Avis",
      "lastName": "Abbott"
    },
    {
      "firstName": "Potts",
      "lastName": "West"
    },
    {
      "firstName": "Lorraine",
      "lastName": "Wolf"
    },
    {
      "firstName": "Irma",
      "lastName": "Washington"
    },
    {
      "firstName": "Rivera",
      "lastName": "Vazquez"
    },
    {
      "firstName": "Heath",
      "lastName": "Roberson"
    },
    {
      "firstName": "Vinson",
      "lastName": "Sheppard"
    },
    {
      "firstName": "Selma",
      "lastName": "Ware"
    },
    {
      "firstName": "Madden",
      "lastName": "Park"
    },
    {
      "firstName": "Paige",
      "lastName": "Strickland"
    },
    {
      "firstName": "Martinez",
      "lastName": "Morris"
    },
    {
      "firstName": "Blanche",
      "lastName": "Lindsay"
    },
    {
      "firstName": "Lang",
      "lastName": "Gardner"
    },
    {
      "firstName": "Sharpe",
      "lastName": "Trujillo"
    },
    {
      "firstName": "Hebert",
      "lastName": "Aguilar"
    },
    {
      "firstName": "Bradford",
      "lastName": "Fleming"
    },
    {
      "firstName": "Peck",
      "lastName": "Pitts"
    },
    {
      "firstName": "Erin",
      "lastName": "Andrews"
    },
    {
      "firstName": "Juliet",
      "lastName": "Parsons"
    },
    {
      "firstName": "Kemp",
      "lastName": "Stein"
    },
    {
      "firstName": "Lawanda",
      "lastName": "Jennings"
    },
    {
      "firstName": "Becky",
      "lastName": "Guy"
    },
    {
      "firstName": "Elisabeth",
      "lastName": "Marshall"
    },
    {
      "firstName": "Hickman",
      "lastName": "Castro"
    },
    {
      "firstName": "Knapp",
      "lastName": "Ferrell"
    },
    {
      "firstName": "Flossie",
      "lastName": "Gentry"
    },
    {
      "firstName": "Faulkner",
      "lastName": "Glass"
    },
    {
      "firstName": "Rowland",
      "lastName": "Spence"
    },
    {
      "firstName": "Latasha",
      "lastName": "Graham"
    },
    {
      "firstName": "Williamson",
      "lastName": "Lang"
    },
    {
      "firstName": "Polly",
      "lastName": "Townsend"
    },
    {
      "firstName": "Ellen",
      "lastName": "Mullins"
    },
    {
      "firstName": "Vera",
      "lastName": "George"
    },
    {
      "firstName": "Garcia",
      "lastName": "Nichols"
    },
    {
      "firstName": "Elaine",
      "lastName": "Hinton"
    },
    {
      "firstName": "Gertrude",
      "lastName": "Duke"
    },
    {
      "firstName": "Fox",
      "lastName": "Long"
    },
    {
      "firstName": "Fern",
      "lastName": "Ingram"
    },
    {
      "firstName": "Margarita",
      "lastName": "Knight"
    },
    {
      "firstName": "Madeline",
      "lastName": "Bennett"
    },
    {
      "firstName": "Pope",
      "lastName": "Copeland"
    },
    {
      "firstName": "Cameron",
      "lastName": "Goodwin"
    },
    {
      "firstName": "Melody",
      "lastName": "Foreman"
    },
    {
      "firstName": "Bond",
      "lastName": "Mack"
    },
    {
      "firstName": "Johnson",
      "lastName": "Joseph"
    },
    {
      "firstName": "Kelley",
      "lastName": "Gregory"
    },
    {
      "firstName": "Britney",
      "lastName": "Acosta"
    },
    {
      "firstName": "Rochelle",
      "lastName": "Booker"
    },
    {
      "firstName": "Jenna",
      "lastName": "Blackwell"
    },
    {
      "firstName": "Hernandez",
      "lastName": "Newton"
    },
    {
      "firstName": "Best",
      "lastName": "Ramos"
    },
    {
      "firstName": "Hoffman",
      "lastName": "Hudson"
    },
    {
      "firstName": "Spears",
      "lastName": "Hampton"
    },
    {
      "firstName": "Opal",
      "lastName": "Mcconnell"
    },
    {
      "firstName": "Eleanor",
      "lastName": "Buchanan"
    },
    {
      "firstName": "Francisca",
      "lastName": "Mendoza"
    },
    {
      "firstName": "Guzman",
      "lastName": "Hawkins"
    },
    {
      "firstName": "James",
      "lastName": "Pate"
    },
    {
      "firstName": "Cantu",
      "lastName": "Dodson"
    },
    {
      "firstName": "Calhoun",
      "lastName": "Figueroa"
    },
    {
      "firstName": "Acevedo",
      "lastName": "Serrano"
    },
    {
      "firstName": "Desiree",
      "lastName": "Terrell"
    }
  ]
  for(let i=0; i < howMany; i++) {
        let fakest = names[i];
        let newSt = {
          UUID: 'N/A',
          email: `${fakest.firstName}.${fakest.lastName}@students.school.org`,
          firstName: fakest.firstName,
          lastName: fakest.lastName
        }
        console.log(newSt);
        let v = await new_student(newSt);
  }

}


    export async function impt(){
        
       let objectToImport = [
            {
                topic_id: 'PvS0IdXaggUXWuf5inAc',
                section: 'Simplifying Expressions using Multiple Properties',
                section_id: 'c7f68635ab7447bda677',
                location: 'Algebra I/1 - Intro to Algebra 1/1 -  Simplifying Expressions using Multiple Properties - 7.EE.1'
            },
            {
                topic_id: 'PvS0IdXaggUXWuf5inAc',
                section: 'Order of Operations (PEMDAS)',
                section_id: 'mhM2oJS592pgY0hIpd9r',
                location: 'Algebra I/1 - Intro to Algebra 1/2 - Order of Operations (PEMDAS) - 7.NS.3'
            },
            {
                topic_id: 'PvS0IdXaggUXWuf5inAc',
                section: 'Defining Exponents',
                section_id: 'mhM2oJS592pgY0hIpd9r',
                location: 'Algebra I/1 - Intro to Algebra 1/3 - Defining Exponents - 8.EE.1'
            },
            {
                topic_id: 'PvS0IdXaggUXWuf5inAc',
                section: 'Taking the Root',
                section_id: '6NzlWVDMhGSWrjbYxRAx',
                location: 'Algebra I/1 - Intro to Algebra 1/4 - Taking the Root - 8.EE.2'
            },
            {
                topic_id: 'PvS0IdXaggUXWuf5inAc',
                section: 'Solving Linear Equations',
                section_id: 'epHcIcgdUsWLiBcgTBrR',
                location: 'Algebra I/1 - Intro to Algebra 1/5 - Solving Linear Equations - 8.EE.7b'
            },
            {
                topic_id: 'PvS0IdXaggUXWuf5inAc',
                section: 'Finding the Equation of a Line',
                section_id: 'YHcStwKlKJhgf3lTYkSk',
                location: 'Algebra I/1 - Intro to Algebra 1/6 - Finding the Equation of a Line - 8.EE.6'
            },
            {
                topic_id: 'PvS0IdXaggUXWuf5inAc',
                section: 'Shopping Spree: Introduction to Algebra I',
                section_id: 'TZDkupSwZJ1q56vsQeMI',
                location: 'Algebra I/1 - Intro to Algebra 1/7 - Shopping Spree: Introduction to Algebra I'
            }
        ]

       /*let objectToImport = [
            {
                topic_id: 'PvS0IdXaggUXWuf5inAc',
                section: 'Order of Operations (PEMDAS)',
                section_id: 'mhM2oJS592pgY0hIpd9r',
                location: 'Algebra I/1 - Intro to Algebra 1/2 - Order of Operations (PEMDAS) - 7.NS.3'
            }
        ]*/

        for(let k=0; k < objectToImport.length; k++) {
            let obj = objectToImport[k];
            const response = await fetch("/data/" + obj.location + ".json");
            let questionObject = await response.json();

            for(let j=0; j < questionObject.length; j++) {
                let qst = questionObject[j];
                let questionToSave = {
                    topic_id: obj.topic_id,
                    section_id: obj.section_id,
                    type: qst.type,
                    section: obj.section,
                    claim: qst.claim,
                    dok: qst.dok,
                    answers: buildAnswers(qst),
                    correctAnswers: buildCorrectAnswers(qst),
                    question: qst.question,
                    hints: buildHints(qst),
                    standard: qst.ccs,
                    video: ''
                };

                console.log(qst.id, questionToSave);
                let save = await saveQuestion(questionToSave);
            }

        }



    }

    function buildHints(qst) {
        let hints = [];
        try{
        hints.push({hint: qst.hint || '' ,order: 1});
        } catch(e) {
        
        }
        return hints;
    }

    function buildCorrectAnswers(question) {
        let answerArray = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
        let correctAnswer = []
        let answ = question.answer;
        if(question.type == 'match') {
            answ.split(',').forEach((an)=>{
                correctAnswer.push(an.trim());
            })
        }else if(question.type == 'true-false') {
            let tmp = answ.split(',');
            for(let i=0; i<tmp.length;i++) {
                correctAnswer.push(`${answerArray[i].toUpperCase()}:${tmp[i].trim()}`);
            }
        } else if(question.type == 'fill-small') {

            for(let i=0; i < answerArray.length; i++) {
                let answerStart = question[answerArray[i]];
                let answerDef = question[`answer-${answerArray[i]}`];
                answerStart = answerStart || '';
                if(answerDef) {
                    correctAnswer.push({"qestion": answerStart, "answer": removeTags(answerDef)})
                }
            }  
        }
        else{
            correctAnswer.push(answ);
        }

        return correctAnswer;
    }

    function buildAnswers(question) {
        let answers = [];
        let answerArray = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
        answerArray.forEach((letter)=>{
            if(question[letter]) {
                // We found an answer that needs to be converted
                let grp = 1;
                if(question.type == 'match') {
                    grp = letter <= 'm' ? 1:2; 
                }
                
                let answer = {
                    key: letter,
                    data: question[letter],
                    group: grp
                }
                answers.push(answer);
            }
        })
        return answers;
    }

    function removeTags(str) { 
    if ((str===null) || (str==='')) 
        return false; 
    else
        str = str.toString(); 
          
    // Regular expression to identify HTML tags in  
    // the input string. Replacing the identified  
    // HTML tag with a null string. 
    let strr = str.replace( /(<([^>]+)>)/ig, ''); 
    return strr.replace(/[^\x00-\x7F]/g, "").replace(/&#xA0;/ig,'');
} 

export async function buildExam(section_id, course_id, name, assignmenType) {
  let date = moment('2020-08-28 11:59:00').toDate();
  return await buildTestBySectionID(section_id, course_id, name, assignmenType, date);
} 

</script>

<h1>IMPORT</h1>

<button on:click={()=>impt()}>Start Import</button>
<button on:click={()=>buildFakeStudents(100)}>Build Fake Students</button>
<button on:click={()=>buildExam('c7f68635ab7447bda677','iT63ueb4apggT0Zmjopk','Getting Started With Expressions','Exercise - Different Question Set')}>Build Assignment</button>
<button on:click={()=>buildExam('mhM2oJS592pgY0hIpd9r','iT63ueb4apggT0Zmjopk','Lets Do Some Ordering','Exercise - Different Question Set')}>Build Assignment</button>