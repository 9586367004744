import { readable } from "svelte/store";
const db = firebase.firestore();

const auth = firebase.auth();

export const auth_store = readable(undefined, function start(set) {
  return auth.onAuthStateChanged(set);
});

export async function sign_in_with_google() {
  await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
}
 
export async function sign_in_with_email_and_password(email, password) {
  await auth.signInWithEmailAndPassword(email, password);
}

export async function sign_out() {
  await auth.signOut();
}

export function current_user_email() {
  const user = firebase.auth().currentUser;
  if (user) return user.email;
  return null;
}

export async function current_user_id() {
  const user = firebase.auth().currentUser;
  const uid = user.uid;
  let person = {id:0};
  if(user.email.toLowerCase().indexOf("@students") >= 0) {
    person = as_single_object(await db.collection("students").where("UUID","==",uid).get());
  } else {
    person = as_single_object(await db.collection("teachers").where("UUID","==",uid).get());
  }
  console.log(person)
  return person.id;
}

export function is_teacher(user) {
  console.log(user && user.email.toLowerCase().indexOf("@students") < 0)
  return user && user.email.toLowerCase().indexOf("@students") < 0;
}

export function is_student(user) {
  return user && user.email.toLowerCase().indexOf("@students") >= 0;
}

export function is_nobody(user) {
  return !user;
}

function as_single_object(doc) {
  return { id: doc.docs[0].id, ...doc.docs[0].data() };
}
