<script>
  export let location;
  location = location;

  import { onMount } from "svelte";
  import StudentSideBar from "./StudentSideBar.svelte";
  import ProgressWording from "../tests/ProgressWording.svelte";
  import {get_single_student_assignment_results, get_single_student_assignment_pathway_results} from "../db/exams";

</script>
<style>
  .title {
    font-size:18pt;
    font-weight:bold;
    color: #10c31c;
    padding-top:15px;
    padding-bottom:20px;
  }

  .reportTable thead{
    padding-top:3px;
    padding-bottom:3px;
    background-color: #efefef;
    text-align: center;
    font-size: 12pt;
    font-weight: bold;
    border-bottom: solid 1px #848383;
  }

  .classTitle {
    background-color: #afd7fc;
    font-size: 12pt;
    color: #066ccb;
    padding-left:10px;
    border-bottom: solid 1px #066ccb;
  }

  .assignmentName {
    text-decoration: none;
    color: black;
  }
  
  .assignmentName:hover {
    color: #066ccb;
  }

  .instructions {
    margin-top:20px;
    background-color: #efefef;
    border: solid 1px #848383;
    text-align: center;
    padding-top:10px;
    padding-bottom:10px;
  }
</style>

<StudentSideBar>

  <div class="title">My Learning Pathway</div>
  {#await get_single_student_assignment_pathway_results()}
    <div class="spinner-border text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  {:then pathwayResults}

    <table border="0" style="width:100%;" class="reportTable">
      <thead>
        <tr>
          <th>Title</th>
          <th style="text-align:left;">Standard</th>
          <th>Percentage</th>
          <th>Level</th>
        </tr>
      </thead>
      <tbody>
        {#each pathwayResults.standards as std}
            <tr>
              <td>
                <a href=".." class="assignmentName">{std.category}</a>
              </td>
              <td>{std.standard}</td>
              <td style="text-align: center;">
                  {std.percentageCorrect}%
              </td>
              <td>
                <ProgressWording percentage={std.percentageCorrect}/>
              </td>
            </tr> 
        {/each}
      </tbody>
    </table>

    {:catch error}
    <div class="alert alert-danger">{error.message}</div>
  {/await}

<!--
  <div class="instructions">
    <i class="fas fa-mouse-pointer" style="color: #0084ff;"></i> Click on the title to go to the assignment.  Click the percentage to see you assignment report.
  </div>
-->

  <div style="padding-top:30px; position: absolute;left: 50%;-ms-transform: translate(-50%);transform: translate(-50%);">
    <table>
      <tr>
        <td style="padding-right:15px;">
          <div style="color:red; font-weight:bold">Below (Below 70%)</div>
        <td>
        <td style="padding-right:15px;">
          <div style="color:#10c31c; font-weight:bold">Met (70-79%)</div>
        </td>
        <td style="padding-right:15px;">
          <div style="color:#0c6412; font-weight:bold;">Proficient (80-89%)</div>
        </td>
        <td>
          <div style="color:#265ad0; font-weight:bold;">Exceed 90%+</div>
        </td>
      </tr>
    </table>
  </div>

</StudentSideBar>
