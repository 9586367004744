
<script>
  import Editor from "cl-editor/src/Editor.svelte"
  import { initializeApp } from "firebase/app";

import { getStorage , ref, uploadBytesResumable, getDownloadURL} from "firebase/storage";

import { firebaseConfig } from "../../storage";


export let saveFormDate;
export let alert;
export let d_id;
export let d_n;
export let clearSelect;


 let sm ={
   question:'',
   correct:'',
   hints:''
 }

 let data;


   // Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Storage and get a reference to the service
 const storage = getStorage(app);

 var percent =''
     var fileLink=[]
     var file=''

 function getUrl(b){
  setTimeout(() => {
  fileLink.push(b)
    console.log(fileLink)
  }, 1000);

  
}


 async function onSubmit(){
   if(sm.question && sm.correct){
     data={
        answers: [sm.correct],
        question: sm.question,
        correctAnswers: [sm.correct],
        hints:[{hint:sm.hints}],
        type:'fill-small',
        dok:'1',
        video:fileLink[0],
        claim:1
     }
   }
 }

 async function submitData(){
        if(data){
           await saveFormDate(data, "fill-small")
       }
    }

    async function refresh(){
      sm ={
   question:'',
   correct:'',
   hints:''
 }

 data=null

 setTimeout(async () => {

   await clearSelect()
 }, 1000);
    }

</script>

<section>
<h3>Create Short Question</h3>

 <div>
    <div class="form-group">
        <label for="exampleFormControlTextarea1">Write your question ?</label>
        <!-- <textarea bind:value={sm.question} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea> -->
        <Editor    on:change={(evt)=>{
          sm.question = evt.detail
          
          // console.log(mq.question)
          }}/>
      </div>
 </div>

 <div >
    <div class="form-group">
        <label for="co">Write Correct Answare</label>
        <input bind:value={sm.correct}  type="text" class="form-control" id="co" placeholder="Example input">
      </div>
 </div>
 <div >
  <div class="form-group">
      <label for="hints">Write hints</label>
      <!-- <textarea type="text" bind:value={sm.hints}  class="form-control" id="hints" placeholder="Example input" rows="3"></textarea> -->
      <Editor    on:change={(evt)=>{
        sm.hints = evt.detail
        
        // console.log(mq.question)
        }}/>
    </div>
</div>

<div class="row" style="width:70% ;">
  <div class="col">
    <div class="form-group">
      <label for="exampleFormControlFile1">Video file upload</label>
      <input type="file" class="form-control-file" id="exampleFormControlFile1"  accept=".mp4" on:change={(e)=>{
        file = e.target.files[0]
  
      }}>
    

    </div>
  </div>
  <div class="col">
    <button on:click={async ()=>{
          if(file){
            fileLink=[]
              // Upload file and metadata to the object 'images/mountains.jpg'
const storageRef = ref(storage, 'videos/' + file.name);
const uploadTask = uploadBytesResumable(storageRef, file);



// Listen for state changes, errors, and completion of the upload.
uploadTask.on('state_changed',
(snapshot) => {
// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
// console.log('Upload is ' + progress + '% done');



switch (snapshot.state) {
case 'paused':
console.log('Upload is paused');
break;
case 'running':
console.log('Upload is running');

break;
}



}, 
(error) => {
// A full list of error codes is available at
// https://firebase.google.com/docs/storage/web/handle-errors
switch (error.code) {
case 'storage/unauthorized':
// User doesn't have permission to access the object
break;
case 'storage/canceled':
// User canceled the upload
break;

// ...

case 'storage/unknown':
// Unknown error occurred, inspect error.serverResponse
break;
}
}, 
() => {
// Upload completed successfully, now we can get the download URL
getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
console.log('File available at', downloadURL);

if(downloadURL){

getUrl(downloadURL)

}




// /* set up async GET request */
// var req = new XMLHttpRequest();
// req.open("GET", downloadURL, true);
// req.responseType = "arraybuffer";

// req.onload = function(e) {
//   var workbook = XLSX.read(req.response);

//   /* DO SOMETHING WITH workbook HERE */
//   console.log(workbook)
// };

// req.send();

});
}
);
        }
    }}>Save Video</button>
  </div>
  </div>

  {#if percent}
  <div class="row" style="width:70% ;">
    <div class="col">
      Uploading ..... ({percent} %)
    </div>

    {#if fileLink.length >0}
  <div class="col">
    <a href={fileLink[0]} target="_blank">
   Video Link
    </a>
  </div>
 {/if}
  </div> 
 
  {/if}
  



 <div >
     <button class='btn btn-primary' on:click={onSubmit} data-toggle="modal" data-target="#exampleModalSQ">Submit</button>
 </div>
</section>


<!-- Modal -->
<div class="modal fade" id="exampleModalSQ" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">Preview Of Question</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {#if alert}
        <!-- content here -->
        {#if alert.type=='success'}
           <!-- content here -->
           <div class="alert alert-success alert-dismissible fade show" role="alert">
             <strong>
               {alert.msg}
             </strong> 
             <button type="button" class="close" data-dismiss="alert" aria-label="Close">
               <span aria-hidden="true">&times;</span>
             </button>
           </div>
        {:else if alert.type=='danger'}
           <!-- else content here -->
           <div class="alert alert-danger alert-dismissible fade show" role="alert">
             <strong>
               {alert.msg}
             </strong> 
             <button type="button" class="close" data-dismiss="alert" aria-label="Close">
               <span aria-hidden="true">&times;</span>
             </button>
           </div>
        {/if}
     {/if}

      {#if data}
           <!-- content here -->
           <div >
              <strong>
                  {@html data.question}
              </strong>
           </div>

         

           <div class="row">
               <div class="col">
                   <div style="color:green">
                       Correct Answers:- 
                       <ul>
                          {#each data.correctAnswers as item}
                               <!-- content here -->
                               <li>
                                 {item}
                               </li>
                          {/each}
                      </ul>
                   </div>
               </div>
           </div>

           <div class="row">
               <div class="col">
                   <p>Hints:-</p>
                   <div>
                       {@html data.hints[0].hint}
                   </div>
               </div>
           </div>

           <section>
            <p><strong>Video:-  <a href={fileLink[0]} target="_blank">
              Video Link
              </a></strong></p>
            
          </section>
          
      {/if}
      </div>
      {#if alert}
      <!-- content here -->
      {#if alert.type !='success'}
      <!-- content here -->
      <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary"  on:click={submitData}>Save changes</button>
        </div>
   {/if}

   {#if alert.type =='success'}
      <!-- content here -->
      <button type="button" on:click={refresh} class="btn btn-secondary" data-dismiss="modal">Close</button>
   {/if}

   {:else}
      <!-- else content here -->
      <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary"  on:click={submitData}>Save changes</button>
        </div>
   {/if}
    </div>
  </div>
</div>

