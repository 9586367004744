<script>
	import Question from './../questions/Question.svelte';
	import CreateAssignmentPart2 from './createAssignment_part2.svelte';
	import TreuFalse from './AssignmentBuilder/Treu-false.svelte';
	import TrueFalse from './../questions/TrueFalse.svelte';
	import Short from './AssignmentBuilder/Short.svelte';
  import { link, navigate } from "svelte-routing";

    export let location;
    location = location;
    let viewQ;
    let allTest=[];
    let classList=[];
    let part;
    let editTopicData;
    let editSection;

    let allSections =[]
    let allSecList=[]
    let  select =''
    let selectTopic;
    let sect;
    let draft=[]
    let d_id;
    let d_n='Create New Assignment'
    let se
    let questionsList =[]

    let step2 = false

    let alert;
    let deleteViewQ;
    let delViewAlert;

    import TeacherSideBar from "./TeacherSideBar.svelte";
    import TeacherDashboardHeader from "./TeacherDashboardHeader.svelte";

    import MultipleChoice from "./AssignmentBuilder/MultipleChoice.svelte";
    import QuestionEditor from "./AssignmentBuilder/QuestionEditor.svelte";
    import Long from "./AssignmentBuilder/Long.svelte";
    import { get_all_data, draft_assignment, add_test_to_testBank, delete_test_to_testBank} from "../db/exams";

    import { onMount } from "svelte";
    import Match from './AssignmentBuilder/Match.svelte';
   
  
    import readXlsxFile from 'read-excel-file'


    var file=''
    var fileData=''
    var rowList=[]
    var header=[]
    var bodyRow = []
    var access=''

    onMount(async ()=>{
      await  get_data()
      
      se = 'disabled'

    })

    async function UploadFile(e){
    // console.log(e.target.files[0])
    file = e.target.files[0].name
    fileData =e.target.files[0]
    }
    let test=null
    let rN =0

    async function UploadStatus(){
       test=0
        rN =0
      if(fileData){
        //  await uploadExcelFile(fileData)
          readXlsxFile(fileData).then((rows) => {
    // `rows` is an array of rows
    // each row being an array of cells.

    console.log(rows)
    header = rows[0]
    
    console.log(header)
    console.log(header.length)

         

    if(header.length > 0){
      let countArray =0

      for (let index = 0; index < header.length; index++) {
        const element = header[index];
        

        if(select == 'MC' && header.length === 15){
          // console.log('MC')
          // console.log('element', element)

          let checkArray = ["id","topic","assignment","ccs","dok","claim", "Question",  "A" , "B", "C" , "D", "Correct" , "Hint 1", "Hint 2", "Hint 3","Video"]

       

          let s = checkArray.includes(element)

          if(s){
            countArray = countArray+1
          }

          console.log(countArray)
          console.log("header", header)


          if(countArray == header.length){
           
            

          
              access='On'

            rowList = rows
            

           
          }else{
            access='Off'
            rowList = []
          }

           
        }else if(select == 'TF' && header.length === 19){
        
////////////////////////////////

let checkArray = ["id", "topic",  "assignment" , "ccs", "dok" , "claim", "Question" ,"Option 1", "Option 2", "Option 3", "Option 4" , 'Option 5', 'Ans 1', 'Ans 2', "Ans 3","Ans 4","Ans 5",'Hint 1',"Hint 2"]

       

let s = checkArray.includes(element)

if(s){
  countArray = countArray+1
}

console.log(countArray)

if(countArray == header.length){
  access='On'
  rowList = rows
}else{
  access='Off'
  rowList = []
}

////////////////////////////
        }else if(select == 'SQ' && header.length === 9){
       


            /////////////////////////          

            let checkArray = ['id', 'topic', 'assignment', 'ccs', 'dok', 'claim', 'Question', 'Answer', 'Hints']

       

let s = checkArray.includes(element)

if(s){
  countArray = countArray+1
}

console.log(countArray)

if(countArray == header.length){
  access='On'
  rowList = rows
}else{
  access='Off'
  rowList = []
}


            /////////////////////////
        }else if(select == 'MQ' && header.length == 27){
   

            /////////////////////////////////

            let checkArray =   ['id', 'topic', 'assignment', 'ccs', 'dok', 'claim', 'Question', 'Left Option 1', 'Left Option 2', 'Left Option 3', 'Left Option 4', 'Left Option 5', 'Left Option 6', 'Right Option 1', 'Right Option 2', 'Right Option 3', 'Right Option 4', 'Right Option 5', 'Right Option 6', 'Correct Match Right 1', 'Correct Match Right 2', 'Correct Match Right 3', 'Correct Match Right 4', 'Correct Match Right 5', 'Correct Match Right 6', 'Hint 1', 'Hint 2']
            // let checkArray =   ['id', 'topic', 'assignment', 'ccs', 'dok', 'claim', 'Question', 'Left Option 1', 'Left Option 2', 'Left Option 3', 'Left Option 4', 'Left Option 5', 'Left Option 6', 'Right Option 1', 'Right Option 2', 'Right Option 3', 'Right Option 4', 'Right Option 5', 'Right Option 6', 'Correct Match Right 1', 'Correct Match Right 2', 'Correct Match Right 3', 'Correct Match Right 4', 'Correct Match Right 5', 'Correct Match Right 6', 'Hint 1', 'Hint 2', null , null]
            // let checkArray =   ['id', 'topic', 'assignment', 'ccs', 'dok', 'claim', 'Question', 'Left Option 1', 'Left Option 2', 'Left Option 3', 'Right Option 1', 'Right Option 2', 'Right Option 3', 'Correct Match Right 1', 'Correct Match Right 2', 'Correct Match Right 3', 'Hint 1', 'Hint 2']
         

       

let s = checkArray.includes(element)

if(s){
  countArray = countArray+1
}

console.log(countArray)

if(countArray == header.length){
  access='On'
  rowList = rows
}else{
  access='Off'
  rowList = []
}





            //////////////////////
        }else{
          access = 'Off'
        }
        
        console.log('access', access)
        
      }
    }
   

  


  })

       
      }
      
    }


    async function get_data(){
      let d =  await get_all_data()
      draft = d.draftAssignment.sort((a,b)=>{
          return new Date(b.time) - new Date(a.time)
      })

      allTest = d.testBank

      if(d_id){
        questionsList = allTest.filter(f=> f.ass_id == d_id)

         console.log(questionsList)
      }
      

      allSections = d.section
      
      // console.log('allsections', allSections)
      
      classList = d.classList

    }

    async function create_new_assign_draft(){
        let s = await draft_assignment()
         if(s){
           await get_data()
         }

         d_id = s
         d_n ='New Assignmnet Creeated'
        console.log(s)
    }


    async function dateTime(d){
       let s= new Date(d.crate.seconds*1000).toDateString()
       let t = new Date(d.time).toLocaleTimeString()
     return {
         date: s,
         time: t
     }
    }

    async function clearDraft(){
        d_id=null
        d_n='Create New Assignment'
        
         selectTopic='';
         sect='';
    }

    async function selectDraft(i){
        let s= new Date(i.crate.seconds*1000).toDateString()
       let t = new Date(i.time).toLocaleTimeString()

        d_id=i.id
        d_n= `${s} ${t}`

        questionsList = allTest.filter(f=> f.ass_id == d_id)

       console.log(questionsList)
      //  await goToAss()

    }


    async function clearSelect(){
      select=''
      alert=null
    }

    async function selectSection(e){
        console.log(selectTopic)

        if(selectTopic){
          let h = allSections.filter(f=> f.id == selectTopic )

          allSecList = h[0].sections

        }else{
            se = 'disabled'

        }
    }

    async function saveFormDate(data , type){
      
      console.log(data)
      console.log(type)


      let topic;
      let section;
      let ass_id;
      let assignment =''
      let video =''


      if(selectTopic &&  sect){
        let t = allSections.filter(f => f.id == selectTopic )
        let s = t[0].sections.filter(f=> f.key == sect)

        topic = t[0]
        section=s[0]
      }

      if(d_id){
        let i = draft.filter(f=> f.id == d_id)
        ass_id =i[0]
      }

      if(data.assignment){
        assignment = data.assignment
      }

      if(data.video){
        video = data.video
      }


   switch (type) {
     case 'fill-small':

   
    
     let newDataSmall = { }

   if(data.topic){
    newDataSmall ={
  answers: data.correctAnswers,
  claim: data.claim,
  correctAnswers:data.correctAnswers,
  dok:data.dok,
  hints:data.hints,
  question:data.question,
   type:type,
   video: video,
  //  section:section.section,
  //  section_id: section.key,
  // topic_id:topic.id,
  topic: data.topic,
  ass_id: ass_id.id,
  assignment:assignment
  
  
}
    }else{
newDataSmall ={
  answers: data.correctAnswers,
  claim: data.claim,
  correctAnswers:data.correctAnswers,
  dok:data.dok,
  hints:data.hints,
  question:data.question,
   type:type,
    video: video,
   section:section.section,
   section_id: section.key,
  topic_id:topic.id,
  topic: topic.topic,
  ass_id: ass_id.id,
  assignment:assignment

  
  
}
    }

    console.log(newDataSmall)

   let s1= await add_test_to_testBank(newDataSmall)


if(s1){
  alert = {
    type:'success',
    msg:'The question is added with assignmnet.'
  }

  await  get_data()


 

}else{
  alert = {
    type:'danger',
    msg:'The question is not added with assignmnet for some errors.'
  }

}

       break;
   
       
     default:


     let newData = {}

if(data.topic){
  newData = {
  answers: data.answers,
claim: data.claim,
correctAnswers:data.correctAnswers,
dok:data.dok,
hints:data.hints,
question:data.question,
type:type,
 video: video,
// section:section.section,
// section_id: section.key,
// topic_id:topic.id,
topic: data.topic,
ass_id: ass_id.id,
 assignment:assignment
}
}else{
newData = {
  answers: data.answers,
claim: data.claim,
correctAnswers:data.correctAnswers,
dok:data.dok,
hints:data.hints,
question:data.question,
type:type,
 video: video,
section:section.section,
section_id: section.key,
topic_id:topic.id,
topic: topic.topic,
ass_id: ass_id.id,
 assignment:assignment
}
}
console.log(newData)

let s= await add_test_to_testBank(newData)


if(s){
  alert = {
    type:'success',
    msg:'The question is added with assignmnet.'
  }

  await  get_data()


 

}else{
  alert = {
    type:'danger',
    msg:'The question is not added with assignmnet for some errors.'
  }

}

       break;
   }

     

    }


    async function goToAss(){
      step2 = !step2
    }

    async function viewQuestion(i){
      viewQ = i
    }

    async function disableView(){
      viewQ=null
    }

    async function deleteViewQuestion(d){
      deleteViewQ = d
    }

    async function deleteViewQuestionCancle(){
      deleteViewQ = null
    }


    async function deleteViewQuestionCnfirm(d){
      await delete_test_to_testBank(d)

      await get_data()

       await deleteViewQuestionCancle()

       delViewAlert = 'k'

    }

    async function a(){
      delViewAlert=null
    }

async function clearTSub(){

  await clearDraft()
  await clearSelect()
  questionsList=[]
   deleteViewQ= null
     delViewAlert=null

 setTimeout(async () => {
  step2 = !step2
  await  get_data()
 }, 1000);
}


let cor=[]
async function saveFromUploadFileExcel(dataRows, type){
  cor =[]
  console.log(dataRows)
  console.log(type)
   test = 0
  switch (type) {
    case 'MC':
     
      let newData =[]

     for (let index = 1; index < dataRows.length; index++) {
      var element = dataRows[index];
      var p=[]
        for (let i = 0; i < element.length; i++) {
        var s = element[i];
        
        if(s){
          p.push(String(s))
        }else{
          p.push('$')
        }
       }


       element = p


      let ans = [{
          group:1,
        data:element[7].split('$').join(' '),
        key:'a',
        no:1
        },
        {
          group:1,
        data:element[8].split('$').join(' '),
        key:'b',
        no:2
        },
        {
          group:1,
        data:element[9].split('$').join(' '),
        key:'c',
        no:3
        },
        {
          group:1,
        data:element[10].split('$').join(' '),
        key:'d',
        no:4
        },
      ]

      let correct = []
      let c=0
     
  // console.log('correct[0]', correct)

      if(test == 0){
        newData.push({
        answers:ans,
      claim:element[5].split('$').join(' '),
      correctAnswers:[element[11].split('$').join(' ')],
      dok:element[4].split('$').join(' '),
      hints:[{hint:element[12].split('$').join(' ')}],
      question:element[6].split('$').join(' '),
      type:'multiple-choice',
      video: element[13].split('$').join(' '),
      assignment: element[2].split('$').join(' '),
      topic: element[1].split('$').join(' ')

      })
      
     }

    

      }

   

      newData.forEach( async (f)=>{
     await saveFormDate(f, 'multiple-choice')
       file=''
       
       rowList=[]
       access = ''
     })

      break;

      case 'MQ':

      let newMQData =[]
     
      for (let index = 1; index < dataRows.length; index++) {
        var element = dataRows[index];
         var data=''
         var p=[]
        // console.log(element)

       for (let i = 0; i < element.length; i++) {
        var s = element[i];
        
        if(s){
          p.push(String(s))
        }else{
          p.push('$')
        }
       }


       element = p


      //  console.log(element)


        let ansMQ=[]

       if(element[7] != "$"){
        ansMQ.push({
         key:'a',
         no:1,
         group:1,
         data: element[7].split('$').join(' ')
        })
       }

       if(element[8] != "$"){
        ansMQ.push(
          {
         key:'b',
         no:2,
         group:1,
         data: element[8].split('$').join(' ')
        }
        )
       }

       if(element[9] != "$"){
        ansMQ.push(
          {
         key:'c',
         no:3,
         group:1,
         data: element[9].split('$').join(' ')
        }
        )
       }

       if(element[10] != "$"){
        ansMQ.push(
          {
         key:'d',
         no:4,
         group:1,
         data: element[10].split('$').join(' ')
        }
        )
       }

       if(element[11] != "$"){
        ansMQ.push(
          {
         key:'e',
         no:5,
         group:1,
         data: element[11].split('$').join(' ')
        }
        )
       }

       if(element[12] != "$"){
        ansMQ.push(
          {
         key:'f',
         no:6,
         group:1,
         data: element[12].split('$').join(' ')
        }
        )
       }

       if(element[13] != "$"){
        ansMQ.push(
          {
         key:'x',
         no:1,
         group:2,
         data: element[13].split('$').join(' ')
        }
        )
       }

       if(element[14] != "$"){
        ansMQ.push(
          {
         key:'y',
         no:2,
         group:2,
         data: element[14].split('$').join(' ')
        }
        )
       }

       if(element[15] != "$"){
        ansMQ.push(
          {
         key:'z',
         no:3,
         group:2,
         data: element[15].split('$').join(' ')
        }
        )
       }

       if(element[16] != "$"){
        ansMQ.push(
          {
         key:'u',
         no:4,
         group:2,
         data: element[16].split('$').join(' ')
        }
        )
       }

       if(element[17] != "$"){
        ansMQ.push(
          {
         key:'v',
         no:5,
         group:2,
         data: element[17].split('$').join(' ')
        }
        )
       }

       if(element[18] != "$"){
        ansMQ.push(
          {
         key:'w',
         no:6,
         group:2,
         data: element[18].split('$').join(' ')
        }
        )
       }


      
       let correctMQ = [
        element[19].split('$').join(' '), element[20].split('$').join(' '), element[21].split('$').join(' '), element[22].split('$').join(' '), element[23].split('$').join(' '),element[24].split('$').join(' '),
       ]

     let correctList =[
      element[13], element[14], element[15], element[17], element[18], element[19]
     ]
     
     var st =''
    

    
   

    newMQData.push({
      answers: ansMQ,
         claim:element[5].split('$').join(' '),
         correctAnswers:correctMQ,
         dok:element[4].split('$').join(' '),
         hints:[{hint:[element[25].split('$').join(' '), element[26].split('$').join(' ') ]}],
         question: element[6].split('$').join(' '),
         type: "match",
         video: '',
         assignment: element[2].split('$').join(' '),
         topic: element[1].split('$').join(' ')
    })

      


      }

      // console.log(newMQData)

      newMQData.forEach( async (f)=>{
     await saveFormDate(f, 'match')
       file=''
       rowList=[]
       access = ''
     })
     







      break


      case 'TF':

      let newTQData =[]


      for (let index = 1; index < dataRows.length; index++) {
        var element = dataRows[index];
        var p=[]
        for (let i = 0; i < element.length; i++) {
        var s = element[i];
        
        if(s){
          p.push(String(s))
        }else{
          p.push('$')
        }
       }


       element = p
        
        let ansTF=[
          {
            key:'a',
            no:1,
            data:element[7].split('$').join(' '),
            ans:element[12].split('$').join(' ')
          },
          {
            key:'b',
            no:2,
            data:element[8].split('$').join(' '),
            ans:element[13].split('$').join(' ')
          }
          ,
          {
            key:'c',
            no:3,
            data:element[9].split('$').join(' '),
            ans:element[14].split('$').join(' ')
          }
          ,
          {
            key:'d',
            no:4,
            data:element[10].split('$').join(' '),
            ans:element[15].split('$').join(' ')
          },
          {
            key:'e',
            no:4,
            data:element[11].split('$').join(' '),
            ans:element[16].split('$').join(' ')
          }
        ]

        let correctMQ = []

        ansTF.forEach((f=>{
          if(f.ans == 'T' || f.ans == 'True' ||f.ans == 'true' || f.ans == 'TRUE' || f.ans == 't'  ){
            correctMQ.push(f.key.toUpperCase()+":T" )
          }else if(f.ans == 'F' || f.ans == 'False' ||f.ans == 'false' || f.ans == 'FALSE' || f.ans == 'f'  ){
            correctMQ.push(f.key.toUpperCase()+":F" )
          }
        }))

      newTQData.push({
        
 answers:ansTF,
 claim:element[5].split('$').join(' '),
 correctAnswers:correctMQ,
 question: element[6].split('$').join(' '),
 type:'true-false',
 hints:[{hint:[element[17].split('$').join(' '), element[18].split('$').join(' ')]}],
//  video: element[19].split('$').join(' '),
 dok:element[4].split('$').join(' '),
 assignment: element[2].split('$').join(' '),
 topic: element[1].split('$').join(' ')

      })


      }

      newTQData.forEach( async (f)=>{
     await saveFormDate(f, 'true-false')
       file=''
       rowList=[]
       access = ''
     })



      break;
  
      case 'SQ':
      let newSQData =[]



      for (let index = 1; index < dataRows.length; index++) {
        var element = dataRows[index];

        var p=[]
        for (let i = 0; i < element.length; i++) {
        var s = element[i];
        
        if(s){
          p.push(String(s))
        }else{
          p.push('$')
        }
       }


       element = p

        newSQData.push({
        question: element[6].split('$').join(' '),
        correctAnswers: [element[7].split('$').join(' ')],
        hints:[{hint:[element[8].split('$').join(' ')]}],
        type:'fill-small',
        dok:element[4].split('$').join(' '),
        video: '',
        claim:element[5].split('$').join(' '),
        assignment: element[2].split('$').join(' '),
        topic: element[1].split('$').join(' ')


        })

      }

      newSQData.forEach( async (f)=>{
     await saveFormDate(f, 'fill-small')
       file=''
       rowList=[]
       access = ''
     })


      break;


    default:
      break;
  }

}

    </script>


<TeacherSideBar location={location} activeOption={'SYSTEMADMIN_STANDARDS'}>
    <TeacherDashboardHeader title="Create an assignment" writeUp="Here teacher can assign a lession for students of a class."></TeacherDashboardHeader>


    <!-- content here -->
    <div class="row">
        <div class="col">
            <a  href={`/asignment/buildAssignments`} use:link class="btn btn-primary" style="float: right; margin-right:50px">
                Create Section
            </a>
        </div>
        
    </div>

          <!-- Split dropup button -->
          <div class="btn-group dropup">
            {#if d_id}
                 <!-- content here -->
                 <button type="button" class="btn btn-secondary" on:click={create_new_assign_draft} disabled >
                    {d_n}
                  </button>
            {:else}
                 <!-- else content here -->
                 <button type="button" class="btn btn-secondary" on:click={create_new_assign_draft}>
                    {d_n}
                  </button>
                 
            {/if}
           
            <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <div class="dropdown-menu">
              <!-- Dropdown menu links -->
              {#each draft as item,i}
                   <!-- content here -->
              <button class="dropdown-item" type="button" on:click={selectDraft(item)}>
               {#await dateTime(item)}
                   <!-- dateTime() is pending -->
                   <div class="spinner-border text-warning" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
               {:then value}
                   <!-- dateTime() was fulfilled -->
                 {i+1} )   {value.date} {value.time}
               {:catch error}
                   <!-- dateTime() was rejected -->
               {/await}
              </button>
          
              {/each}
              <button class="dropdown-item" type="button" on:click={clearDraft}>Clear</button>
            </div>
          </div>

          {#if step2}
             <!-- content here -->
             <button type="button" class="btn btn-danger" on:click={goToAss}>
              Back
            </button>
          {/if}
          <div>
           
          </div>

    {#if step2}
       <!-- content here -->

       <CreateAssignmentPart2 {questionsList} {d_id} {d_n} {classList} {clearTSub}></CreateAssignmentPart2>

    {:else}
       <!-- else content here -->




{#if d_id}
     <!-- content here -->
<div  style="margin: 20px;">

<div class="row">
  <div class="form-row">
      <div class="col">
           <select class="custom-select" bind:value={selectTopic} on:change={selectSection}>
              <option value='' selected>Select Topic:- </option>
              {#each allSections as item}
                   <!-- content here -->
              <option value="{item.id}">{item.topic}</option>

              {/each}
            
            </select>
      </div>
      <div class="col">

          {#if selectTopic}
               <!-- content here -->
               <select class="custom-select" bind:value={sect} >
                  <option value='' selected>Select section:- </option>
                  {#each allSecList as item}
                       <!-- content here -->
                  <option value="{item.key}">{item.section}</option>
   
                  {/each}
                
                </select>
          {:else}
               <!-- else content here -->
               <select class="custom-select" disabled>
                  <option value='' selected>Select section:- </option>
                  {#each allSecList as item}
                       <!-- content here -->
                  <option value="{item.key}">{item.section}</option>
   
                  {/each}
                
                </select>
          {/if}

         
     </div>
    </div>
</div>


     <!-- content here -->

  <div class="row">
      <div class="col">
          <br>
          <div class="form-group" >
              <label for="exampleFormControlSelect1">Select questions for your assignment:-</label>
              <select  class="form-control" id="exampleFormControlSelect1" style="width:40% ;" bind:value={select}>
                  <option value="" selected>Choose question</option>
                <option value="MC">Multiple Choise</option>
                <option value="TF">
                    True False
                </option>
                <option value="SQ">
                    Short Question
                </option>
                <!-- <option value='LQ'>
                    Long Question
                </option> -->
                <option value='MQ'>
                    Matching Question
                </option>
              </select>
            </div>
      </div>
     
      <div class="col">
        <br>
        {#if select}
           <!-- content here -->
           <div class="form-group" style="margin: 2%;" >
            <!-- Button trigger modal -->
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalUploadExcel">
          Upload Excel
        </button>
        </div>
        {/if}
       
       </div>
  </div>







</div>
    

{/if}


 {#if d_id}
      <!-- content here -->
      {#if select == 'MC'}
      <!-- content here -->
      
      <MultipleChoice {clearSelect} {saveFormDate} {d_id} {d_n} {alert} />



 {:else if select == 'TF'}
      <!-- else content here -->
      
      <TreuFalse {clearSelect} {saveFormDate} {d_id} {d_n} {alert}/>
     
 {:else if select == 'SQ'}
      <!-- else content here -->
      

      <Short {clearSelect} {saveFormDate} {d_id} {d_n} {alert}/>

     
       
 {:else if select == 'LQ'}
      <!-- else content here -->
      

      <Long/>

 {:else if select == 'MQ'}
      <!-- else content here -->
      
      <Match {clearSelect} {saveFormDate} {d_id} {d_n} {alert}/>

 {/if}

 {/if}





    {/if}

 

</TeacherSideBar>



<section style="position:fixed ; right:0px; bottom:0px">
  {#if questionsList.length >0}
     <!-- content here -->
     <button class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal123-lg">Added questions ({questionsList.length})</button>
  {:else}
     <!-- else content here -->
     <button class="btn btn-primary" disabled >Added questions (0)</button>
  {/if}
 
</section>


<div class="modal fade bd-example-modal123-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Added Questions</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {#if delViewAlert}
        <!-- content here -->
        <div class="alert alert-success alert-dismissible fade show" role="alert">
         <strong>Qusetion is deleted successfully.</strong>
         <button type="button" class="close" data-dismiss="alert" aria-label="Close" on:click={a}>
           <span aria-hidden="true">&times;</span>
         </button>
       </div>
     {/if}

     {#if deleteViewQ}
        <!-- content here -->
        <section style="margin-top: 60px; text-align:center">
           <p>
             <strong>
               @) Do you want to delete ?
             </strong>
           </p>
           <div class="btn-group" role="group" aria-label="Basic example">
             <button style="margin:10px ;" type="button" class="btn btn-secondary" on:click={deleteViewQuestionCancle}>Cancle</button>
             <button style="margin:10px ;" type="button" class="btn btn-danger" on:click={deleteViewQuestionCnfirm(deleteViewQ)}>Delete</button>

           </div>
        </section>
     {/if}

       {#if questionsList.length >0}
          <!-- content here -->

          <section style="height:450px ; overflow-x:scroll">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>
                      #
                    </th>
                    <th>
                      Topic
                    </th>
                    <th>
                      Question
                    </th>
                    <th>
                      Type
                    </th>
                    <th>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {#each questionsList as item,i}
                     <!-- content here -->
                     <tr>
                       <td>
                         {i+1}
                       </td>
                       <td>
                         {item.topic}
                       </td>
                       <td>
                         {item.question}
                       </td>
                       <td>
                         {item.type}
                       </td>
                       <!-- <td>
                         <button class='btn btn-primary btn-sm'>Edit</button>
                       </td> -->
                       <td>
                        <button class='btn btn-warning btn-sm' on:click={viewQuestion(item)} >View</button>
                      </td>
                      <td>
                        <button class='btn btn-danger btn-sm' on:click={deleteViewQuestion(item)}>Delete</button>
                      </td>
                     </tr>
                  {/each}
                </tbody>
              </table>
            </div>
          </section>

          <section style="float:right; margin-top:20px">
            <button class='btn btn-primary' data-dismiss="modal" on:click={goToAss} >Now create an assignment</button>
          </section>
        
       

        <section style="margin-top:100px ; padding:20px">
          
          {#if viewQ}
             <!-- content here -->
             <h4>
              <strong>
                Question preview :-
                 <button class="btn btn-danger" on:click={disableView}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                  </svg>
                </button>
              </strong>
            </h4>
             <section>
              {#if viewQ.type == 'multiple-choice'}
                 <!-- content here -->
  
                 <section>
                   <div class="row">
                     <div class="col">
                      <div>
                        Q) {@html viewQ.question}
                      </div>
                     </div>
                   </div>
  
                   <div class="row">
                    {#if viewQ.answers[0] || viewQ.answers[1]}
                    <div class="col">
                      <ul>
                        <li>
                        ({viewQ.answers[0].key}) {@html viewQ.answers[0].data}
                        </li>
                        <li>
                         ({viewQ.answers[1].key}) {@html viewQ.answers[1].data}
                         </li>
                      </ul>
                    </div>
                    {/if}
                    
                    {#if viewQ.answers[2] || viewQ.answers[3]}
                    <div class="col">
                      <ul>
                        <li>
                          ({viewQ.answers[2].key}) {@html viewQ.answers[2].data}
                          </li>
                          <li>
                            ({viewQ.answers[3].key}) {@html viewQ.answers[3].data}
                            </li>
                      </ul>
                     </div>
                      {/if}


                   
                   </div>
  
  
                   <div class="row">
                     <div class="col">
                       <div style="color:green ; ">
                         Correct Answer:{viewQ.correctAnswers[0]}
                        
                       </div>
                     </div>
                   </div>
  
                   <div class="row">
                     <p>Hints:- </p> <br>
                     <div class="m-2">
                       {@html 
                        viewQ.hints[0].hint
                        }
                     </div>
                   </div>

                  <div class="row">
                    <p>Video Link:-</p>
                    <div>
                      <!-- {@html 
                       viewQ.hints[0].hint
                       } -->
                       <a href={viewQ.video} target="_blank">Link</a>
                    </div>
                  </div>
                 </section>
                
  
              {:else if viewQ.type == 'true-false'}
                 <!-- else content here -->

                 <section>
                  <div class="row">
                    <div class="col">
                     <div>
                       Q) {@html viewQ.question}
                     </div>
                    </div>
                  </div>
 
                  <div class="row">
                    <div class="col">
                      <ul>
                        {#each viewQ.answers as item}
                           <!-- content here -->
                           <li>
                            ({item.key}) {@html item.data}
                            </li>
                        {/each}
                      
                       
                      </ul>
                    </div>
                  </div>
 
 
                  <div class="row">
                    <div class="col">
                      <div style="color:green ; ">
                        Correct Answer:
                        {#each viewQ.correctAnswers as item}
                           <!-- content here -->
                           <li>
                             {item}
                           </li>
                        {/each}
                      
                       
                      </div>
                    </div>
                  </div>
 
                  <div class="row">
                    <p>Hints:- </p> <br>
                    <div class="m-2">
                      {@html 
                       viewQ.hints[0].hint
                       }
                    </div>
                  </div>

                  <div class="row">
                    <p>Video Link:-</p>
                    <div>
                      <!-- {@html 
                       viewQ.hints[0].hint
                       } -->
                       <a href={viewQ.video} target="_blank">Link</a>
                    </div>
                  </div>

                </section>

                 {:else if viewQ.type == 'fill-small'}
                 <!-- else content here -->

                 <section>
                  <div class="row">
                    <div class="col">
                     <div>
                       Q) {@html viewQ.question}
                     </div>
                    </div>
                  </div>
 
                
 
 
                  <div class="row">
                    <div class="col">
                      <div style="color:green ; ">
                        Correct Answer:
                        {#each viewQ.correctAnswers as item}
                           <!-- content here -->
                           <li>
                             {item}
                           </li>
                        {/each}
                      
                       
                      </div>
                    </div>
                  </div>
 
                  <div class="row">
                    <p>Hints:-</p>
                    <div>
                      {@html 
                       viewQ.hints[0].hint
                       }
                    </div>
                  </div>

                  <div class="row">
                    <p>Video Link:-</p>
                    <div>
                      <!-- {@html 
                       viewQ.hints[0].hint
                       } -->
                       <a href={viewQ.video} target="_blank">Link</a>
                    </div>
                  </div>
                </section>
  
                 {:else if viewQ.type == 'match'}
                 <!-- else content here -->

                 <section>
                  <div class="row">
                    <div class="col">
                     <div>
                       Q) {@html viewQ.question}
                     </div>
                    </div>
                  </div>
 
                  <div class="row">
                    <div class="col">
                      <ul>
                        {#each viewQ.answers as item,i}
                           <!-- content here -->
                           {#if i < 4}
                              <!-- content here -->
                              <li>
                                ({item.key}) {@html item.data}
                                </li>
                           {/if}
                          
                        {/each}
                      
                       
                      </ul>
                    </div>
                    <div class="col">
                      <ul>
                        {#each viewQ.answers as item,i}
                           <!-- content here -->
                           {#if i >= 4 && i < 9}
                              <!-- content here -->
                              <li>
                                ({item.key}) {@html item.data}
                                </li>
                           {/if}
                          
                        {/each}
                      
                       
                      </ul>
                    </div>
                  </div>
 
 
                  <div class="row">
                    <div class="col">
                      <div style="color:green ; ">
                        Correct Answer:
                        {#each viewQ.correctAnswers as item}
                           <!-- content here -->
                           <li>
                             {item}
                           </li>
                        {/each}
                      
                       
                      </div>
                    </div>
                  </div>
 
                  <div class="row">
                    <p>Hints:-</p>
                    <div>
                      {@html 
                       viewQ.hints[0].hint
                       }
                    </div>
                  </div>

                  <div class="row">
                    <p>Video Link:-</p>
                    <div>
                      <!-- {@html 
                       viewQ.hints[0].hint
                       } -->
                       <a href={viewQ.video} target="_blank">Link</a>
                    </div>
                  </div>

                </section>
  
               
  
              {/if}
            </section>
          {/if}
        
        </section>


       {:else}
          <!-- else content here -->
          <p>
            No added questions.
          </p>
       {/if}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModalUploadExcel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Upload Excel For 

	{#if select == 'MC'}
	Multiple Choise
	{:else if select == 'TF'}
		True False
    {:else if select == 'SQ'}
		Short Question
    {:else if select == 'MQ'}
		Matching Question
	{/if}

        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="input-group">
          <div class="custom-file">
            <input type="file" class="custom-file-input" id="inputGroupFile04" 
            accept=".xlsb, .xla, .xlam , .xlsx"  on:change={UploadFile} >

            <label class="custom-file-label" for="inputGroupFile04">
              {#if file}
	             {file}
	             {:else }
		            Choose File
	              {/if}

            </label>
          </div>
         
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" on:click={UploadStatus}>Confirm</button>
          </div>
        </div>
        {#if cor }
        {#if cor.length > 1 }

        {#each cor as c, i}
        {#if i==1 }
        <div class="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>{c}</strong>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" on:click={()=>{
            cor=[]
          }}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {/if}
       
        {/each}  
       
        {/if}
        {/if}
     
       


       
        <div>
          <div class="alert alert-warning alert-dismissible fade show" role="alert">
            <strong>Excel file format of {#if select == 'MC'}
              Multiple Choise
              {:else if select == 'TF'}
                True False
                {:else if select == 'SQ'}
                Short Question
                {:else if select == 'MQ'}
                Matching Question
              {/if} .</strong> 

              {#if select == 'MC'}
 <a href="https://firebasestorage.googleapis.com/v0/b/smartermath-beta.appspot.com/o/Format%20List%2FDemo%2FMultiple_Choice_Question_1.xlsx?alt=media&token=3d5249c9-dc0a-47de-8bdc-1dc80f48a937" target="__blank">Download Now</a>	
	{:else if select == 'TF'}
  <a href="https://firebasestorage.googleapis.com/v0/b/smartermath-beta.appspot.com/o/Format%20List%2FDemo%2FTrue-False_1.xlsx?alt=media&token=2a6a0ce3-90bc-4699-b429-45c6fe9aaea5" target="__blank">Download Now</a>
    {:else if select == 'SQ'}
    <a href="https://firebasestorage.googleapis.com/v0/b/smartermath-beta.appspot.com/o/Format%20List%2FDemo%2FShort-question.xlsx?alt=media&token=6696eb86-b113-43b9-9dcf-f8fc77905d94" target="__blank">Download Now</a>
    {:else if select == 'MQ'}
    <a href="https://firebasestorage.googleapis.com/v0/b/smartermath-beta.appspot.com/o/Format%20List%2FDemo%2FMatching-Question.xlsx?alt=media&token=b2b1bc67-c4df-4034-9010-b6d554ce4186" target="__blank">Download Now</a>
	{/if}

            <!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close" on:click={()=>{
              access =''
            }}>
              <span aria-hidden="true">&times;</span>
            </button> -->
          </div>
        </div>
        


       

        {#if access == 'Off' }
        <div>
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>Excel file format of {#if select == 'MC'}
              Multiple Choise
              {:else if select == 'TF'}
                True False
                {:else if select == 'SQ'}
                Short Question
                {:else if select == 'MQ'}
                Matching Question
              {/if} is wrong!</strong> download the format. 

              {#if select == 'MC'}
 <a href="https://firebasestorage.googleapis.com/v0/b/smartermath-beta.appspot.com/o/Format%20List%2FDemo%2FMultiple_Choice_Question_1.xlsx?alt=media&token=3d5249c9-dc0a-47de-8bdc-1dc80f48a937" target="__blank">Download Now</a>	
	{:else if select == 'TF'}
  <a href="https://firebasestorage.googleapis.com/v0/b/smartermath-beta.appspot.com/o/Format%20List%2FDemo%2FTrue-False_1.xlsx?alt=media&token=2a6a0ce3-90bc-4699-b429-45c6fe9aaea5" target="__blank">Download Now</a>
    {:else if select == 'SQ'}
    <a href="https://firebasestorage.googleapis.com/v0/b/smartermath-beta.appspot.com/o/Format%20List%2FShort-question.xlsx?alt=media&token=0e9ac037-7a86-4426-89f1-2f68986a2527" target="__blank">Download Now</a>
    {:else if select == 'MQ'}
    <a href="https://firebasestorage.googleapis.com/v0/b/smartermath-beta.appspot.com/o/Format%20List%2FMatching-Question.xlsx?alt=media&token=210b5ef8-cc8e-4cfa-aaee-e8e6690829e0" target="__blank">Download Now</a>
	{/if}

            <button type="button" class="close" data-dismiss="alert" aria-label="Close" on:click={()=>{
              access =''
            }}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        {/if}
 

        <div>
          {#if rowList.length>0}
             
          <div class="table-responsive" style="height:300px ; overflow:scroll">
            <table class="table table-sm">
              <thead>
               
                <tr>
                 
                  {#each rowList[0] as header}
                  <th scope="col">{header}</th>
                  {/each}   
                
                </tr>
              </thead>
              <tbody>
            

             
                {#each rowList as row, i}
                {#if i > 0}
                <tr>
                {#each row as r, i}
                
               
                  <th scope="row">{r}</th>
               
                {/each}
                 
                
              </tr>
               
                 {/if}
              
               
                {/each} 
               
           

              </tbody>
            </table>
          </div>

              {/if}
        </div>
     
      </div>


      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" on:click={()=>{
          file=''
           test = null
          rowList=[]
          access =''
        }}>Close</button>
        <button type="button" class="btn btn-primary" on:click={saveFromUploadFileExcel(rowList,select )}>Save Questions</button>
      </div>
    </div>
  </div>
</div>