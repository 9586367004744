<script>
	import AssignmentView from './AssignmentView.svelte';
	import { onMount } from 'svelte';
    import TeacherSideBar  from "./TeacherSideBar.svelte";
    import { get_all_data, draft_assignment, add_test_to_testBank, delete_test_to_testBank} from "../db/exams";
    

    export let class_id;
    export let asign_id;
    let ass;
    let exam;
    let testList;


    onMount(async ()=>{
      console.log(class_id)
      console.log(asign_id)
      await get_data()
    })

    async function get_data(){
        let s = await get_all_data()
        console.log(s)

        let c = s.classList.filter(f=> f.id == class_id )
        let ass1 =  s.ass.filter(f=> f.id == asign_id )
        ass=ass1[0]

        console.log(ass)
        
        let exam1 = s.exam.filter(f=> f.id == ass.exam_id)
        exam=exam1[0]

         console.log(exam)

         let test = s.testBank

         testList =test



    }


    async function testfind(d){
      if(d){
         let t =  testList.filter(f=> f.id == d.question_id)
            console.log(t[0])
         return t[0]
      }
    }


    async function due(d){
       return new Date(d.seconds*1000).toDateString()
    }

</script>

<TeacherSideBar {class_id}>
    <div class="container">
      
            <h4>
                Assignment Details 
            </h4>
        </div>
        <div  style="padding:20px ;">
            <p>Total Questions:- 
                {#if ass}
                     <!-- content here -->
                     {ass.totalQuestions}
                {/if}
            </p>
            <p>Due Date:- 

                {#if ass}
                     <!-- content here -->
                     {#await due(ass.dueDate)}
                    <!-- due(ass.dueDate) is pending -->
                {:then value}
                    <!-- due(ass.dueDate) was fulfilled -->
                    {value}
                {:catch error}
                    <!-- due(ass.dueDate) was rejected -->
                {/await}
                {/if}
                
            </p>
        </div>
       

            {#if exam}
            <!-- content here -->
           
            {#each exam.questions as item}
                 <!-- content here -->
                 {#await testfind(item)}
                     <!-- promise is pending -->
                 {:then value}
                  <section>
                      <p>Q) Question No:- {item.order} </p>
                   <AssignmentView {value} ></AssignmentView>
                  </section>
                  <br>
                     <!-- promise was fulfilled -->
                 {:catch error}
                     <!-- promise was rejected -->
                 {/await}
            {/each}
           

             {/if}

     
  



</TeacherSideBar>
