const db = firebase.firestore();
import {current_user_id} from '../auth';
import {as_array, as_object, as_single_object, unique} from './dbUtil'
import {get_student_classes, get_assignments_for_student, get_class_assignments_for_student} from './general';
import {get_standards} from './standards';
import { compute_rest_props, assign } from 'svelte/internal';
 import { dataHash } from "./Import/import";


export async function get_all_section(){
  let s = as_array(await db.collection('testBankSections').get())

  return s
}

export async function add_new_section_data(topic , section){
   if(topic && section){
      let a = Math.floor(Math.random()* new Date())

      let has = await dataHash(a)
      let s = as_array(await db.collection('testBankSections').get())

     let d= {
        slNo: s.length*1+1,
        topic: topic,
        sections:[
          {
            key:has,
            section:section,
            no:1
          }
          ]
     }

     console.log(s)

      return await db.collection('testBankSections').add(d)

   }
}

export async function delete_sections_all_data(id){
   if(id){
     await db.collection('testBankSections').doc(id).delete()

     let s = as_object(await db.collection('testBankSections').doc(id).get())

     if(s){
       return 
     }else{
       return 'data deleted'
     }
   }
}

export async function get_unit_section_data(data){
  let s = as_object(await db.collection('testBankSections').doc(data.id).get())

  if(s){
    return s
  }
}

export async function draft_assignment(){

  let d = {
      crate: new Date(),
      time: new Date().getTime()
  }

  return (await db.collection('draftAssignment').add(d)).id 


}

export async function add_section_names(data, value){
  if(data){
    let s = as_object(await db.collection('testBankSections').doc(data.id).get())

    if(s){
      if(value){
        let ar = s.sections

        let a = Math.floor(Math.random()* new Date())

      let has = await dataHash(a)

         if(ar){
           ar.push({
             key:has,
             section:value,
             no: s.sections.length*1+1
           })
         }

         setTimeout(async () => {
           let newD = {
             topic: data.topic,
             sections:ar
           }

           await db.collection('testBankSections').doc(data.id).update(newD)
           
         }, 1000);


         return s
        


      }
    }

  }
}



export async function delete_unit_section(data, key){
  if(data){
    let s = as_object(await db.collection('testBankSections').doc(data.id).get())

    if(s){
      let ar = s.sections

      let d = ar.filter(f => f.key != key)

      if(d){
        setTimeout(async () => {
          let newD = {
            topic: data.topic,
            sections:d
          }

          await db.collection('testBankSections').doc(data.id).update(newD)
          
        }, 1000);

        
      }
      return s
    }
  }
}

export async function edit_section_topic (data, value){
  if(value && data){
    let s = as_object(await db.collection('testBankSections').doc(data.id).get())

    if(s){
      setTimeout(async () => {
        let newD = {
          topic: value,
          sections:data.sections
        }

        await db.collection('testBankSections').doc(data.id).update(newD)
        
      }, 1000);

      return s
    }
  }
}

export async function get_all_data() {
let se = as_array(await db.collection('testBankSections').get())
console.log('section:' , se)

let testBank = as_array(await db.collection('testBank').get())
console.log('testBank:' , testBank)

let exam = as_array(await db.collection('exam').get())

console.log('exam', exam)


let all_draft =  as_array(await db.collection('draftAssignment').get())
let ass =  as_array(await db.collection('assignments').get())


console.log('draftAssignment', all_draft)
console.log('ass', ass)


let classList =  as_array(await db.collection('classes').get())
console.log('classList', classList)


return {
  section:se,
  testBank: testBank,
  exam:exam,
  draftAssignment:all_draft,
  ass:ass,
  classList:classList
}

}

//////// add classes ////////////

export async function add_exams(data){
  if(data){
    return (await db.collection('exam').add(data)).id
  }
}


///////// add classes /////////////

//////// add assignment ////////////

export async function add_ass(data , id){

  
    // let s = as_object(await db.collection('assignments').doc(id).get())

    // if(s){
    //   await db.collection('assignments').doc(id).delete()

    //  await db.collection('assignments').doc(id).set(data)

    //  await db.collection('draftAssignment').doc(id).delete()
    //  return   as_object(await db.collection('assignments').doc(id).get())

    // }else{
    //  await db.collection('assignments').doc(id).set(data)
    //  await db.collection('draftAssignment').doc(id).delete()

    //  return   as_object(await db.collection('assignments').doc(id).get())

    if(data){
      return (await db.collection('assignments').add(data)).id
    }
    

    
  
}


///////// add assignment /////////////

////////// add test in testBank //////////

export async function add_test_to_testBank (data){
  if(data){
    console.log(data)
    return (await db.collection('testBank').add(data)).id
  }
}


/////////////// delete test bank //////////////////

export async function delete_test_to_testBank (data){
  if(data){
    return await db.collection('testBank').doc(data.id).delete()
  }
}

/////////////// delete test bank //////////////////


export async function buildTestByTopicID(topic_id) {
  // Pull the topic
  //let topic = (await db.collection("testBankSections").doc(topic_id).get()).data();
  //console.log('topic', topic);
  //for(let i =0; i < topic.sections.length; i++){
  //  let section = topic.sections[i];
  //  console.log('section',i,section);
    // Pull questions for this section
 // }
  let questions = as_array(await db.collection("testBank").where("topic_id","==",topic_id).get());
}

export async function buildTestBySectionID(section_id, class_id, name, assignmentType, dueDate) {
  // Build the Exam
  //--------------------------------
  // Pull the section
  let questions = as_array(await db.collection("testBank").where("section_id","==",section_id).get());
  console.log('questions', questions,section_id);
  let topicId = questions[0].topic_id;
  let section = as_object(await db.collection("testBankSections").doc(topicId).get());
  let sectionName = section.sections.find(f=>f.key===section_id).section;
  let examQuestions = []

  for(let j=0; j< questions.length; j++) {
    let qst = questions[j];
    let newQst = {
    order: j+1,
    points: 1,
    question_id: qst.id
    }

    examQuestions.push(newQst);
  };

  let exam = {
    name: sectionName,
    questions: examQuestions,
    type: 'NormalTest'
  }

  let examId = (await db.collection("exam").add(exam)).id;

  // Pull all students in the class
  let classInfo = as_object(await db.collection("classes").doc(class_id).get());

  // Assign The Exam
  let assignment = {
    assignmentType : assignmentType,
    class_id: class_id,
    dueDate: dueDate,
    exam_id: examId,
    name: name,
    task_id: '',
    students: classInfo.students
  }
  return await db.collection("assignments").add(assignment);
}


export async function delete_assign(assignment_id){
  let student_id = await current_user_id();
  console.log(assignment_id,student_id)
  return   await db.collection("assignments").doc(assignment_id).collection("answers").doc(student_id).delete()

}


// Answers
export async function save_answers(assignment_id, correct, question, answer) {
  let student_id = await current_user_id();
  console.log(assignment_id, correct, question, answer)

  let section = ''
let section_id =''
let standard = ''

let topid_id =''

if(question.topic_id){
  topid_id = question.topic_id
}

if(question.section){
  section = question.section
}
if(question.standard){
  standard = question.standard
}
if(question.section_id){
  section_id = question.section_id
}

  let data = {
    question_id: question.id,
    topid_id: topid_id ,
    section: section,
    section_id: section_id,
    dok: question.dok,
    claim: question.claim,
    answerGiven: answer,
    answerCorrect: correct,
    questiontType: question.type,
    correctAnswers: question.correctAnswers,
    standard:standard
  }

  console.log(data)
  
  // Does the answer already exist? (Only the first attempt is saved)
  let assignment = as_object(await db.collection("assignments").doc(assignment_id).get());
  let answersOnFile = as_object(await db.collection("assignments").doc(assignment_id).collection("answers").doc(student_id).get());
  
  console.log(answersOnFile)
  console.log(assignment)

  let correctAnswers=0
  let percentageCorrect=0
  let totalQuestions = assignment.totalQuestions

  if(answersOnFile){
    if(answersOnFile.correctAnswers){
      correctAnswers = answersOnFile.correctAnswers

    }else{
      correctAnswers =0
    }

    if(answersOnFile.percentageCorrect){
    percentageCorrect = answersOnFile.percentageCorrect
      

    }else{
      percentageCorrect =0
    }

  }

  if(correct){

    if(answersOnFile.questions){
      let s = answersOnFile.questions.filter(f => f.question_id === question.id)
      console.log(s)
     if(s.length > 0){
       if(s[0].answerCorrect){
         correctAnswers =correctAnswers -1
 
       }else {
         correctAnswers =correctAnswers +0
 
       }
 
     }
    }

    

    correctAnswers =correctAnswers + 1


    percentageCorrect = Math.round((correctAnswers /  totalQuestions) * 100)
  }else{
    // s = answersOnFile.questions.filter(f => f.question_id === question.id)
    if(answersOnFile.questions){
      let s = answersOnFile.questions.filter(f => f.question_id === question.id)
      console.log(s)
     if(s.length > 0){
       if(s[0].answerCorrect){
         correctAnswers =correctAnswers -1
  
       }else {
         correctAnswers =correctAnswers +0
  
       }
  
     }
    }
 
   
      correctAnswers =correctAnswers + 0

    percentageCorrect = Math.round((correctAnswers /  totalQuestions) * 100)
  }



  let newanswersOnFile = {}

  if(answersOnFile){
    newanswersOnFile ={
      correctAnswers: correctAnswers,
      id: answersOnFile.id,
      percentageCorrect:percentageCorrect,
      questions:answersOnFile.questions,
      standards:answersOnFile.standards,
      totalQuestions:totalQuestions
    }
   let s =[]
   let s1=[]
    if(newanswersOnFile.questions || newanswersOnFile.standards){
       s = newanswersOnFile.questions.filter(f => f.question_id != question.id)
      s1 = newanswersOnFile.standards.filter(f => f.standard != question.standard)
    }

   


    if(s){
      s.push(data)

      s1.push({
        correctAnswers:correctAnswers,
        percentageCorrect:percentageCorrect,
        totalQuestions:totalQuestions,
        standard:standard
      })

    }

  
      newanswersOnFile ={
        correctAnswers: correctAnswers,
        id: answersOnFile.id,
        percentageCorrect:percentageCorrect,
        questions:s,
        standards:s1,
        totalQuestions:totalQuestions
      }
      console.log(newanswersOnFile)
 

   

  }




  // SAVE RESULTS
setTimeout(async () => {
  // return await db.collection("assignments").doc(assignment_id).collection("answers").doc(student_id).set(newanswersOnFile);
  return await db.collection("assignments").doc(assignment_id).collection("answers").doc(student_id).set(newanswersOnFile);
}, 1500);
}

export async function get_single_student_assignment_pathway_results(student_id) {
  student_id = student_id || await current_user_id();
  let classReturnSet = {
    standards: []
  }
    // Get Student Classes
    let classes = await get_student_classes(student_id);

    for(let i=0; i < classes.length; i++) {
      let cls = classes[i];
      let assignments = await get_class_assignments_for_student(cls.id, student_id);
      for(let j=0; j < assignments.length; j++) {
        let assgn = assignments[j];
        let assignResult = await get_student_answers(assgn.id, student_id);
        if(assignResult.standards) {
          assignResult.standards.forEach(std=>{
            // Have we already found this standard on another assignment
            let existingStd = classReturnSet.standards.find(f=>f.standard == std.standard);

            if(!existingStd) {
              existingStd = {correctAnswers: 0, percentageCorrect: 0, standard: std.standard, totalQuestions: 0};
              classReturnSet.standards.push(existingStd)
            };
            existingStd.correctAnswers += std.correctAnswers;
            existingStd.totalQuestions += std.totalQuestions;
            existingStd.percentageCorrect = Math.round((existingStd.correctAnswers/existingStd.totalQuestions)*100); 
            console.log("existingStd", existingStd);
          });
        }
      }
    }

    let standards = await get_standards();
    classReturnSet.standards.forEach(std=>{
      let stdDetail = standards.find(f=>f.id == std.standard) ?? {category: 'Not Defined', description: 'Not Defined', grade: 0};
      std.category = stdDetail.category;
      std.description = stdDetail.description;
      std.grade = stdDetail.grade;
    })

    console.log("resultSet", classReturnSet);
    return classReturnSet;
}

export async function get_single_student_assignment_results(student_id) {
  student_id = student_id || await current_user_id();
  let resultSet = [];
  // Get Student Classes
  let classes = await get_student_classes(student_id);
  for(let i=0; i < classes.length; i++) {
    let cls = classes[i];
    
    let classReturnSet = {
      courseName: cls.courseName,
      name: cls.name,
      assignments: []
    }
    resultSet.push(classReturnSet);

    let assignments = await get_class_assignments_for_student(cls.id, student_id);
    for(let j=0; j < assignments.length; j++) {
      let assgn = assignments[j];
      let assignResult = await get_student_answers(assgn.id, student_id);
      if (assignResult && assignResult.correctAnswers) {
        assignResult.attempted = true
      } else {
        assignResult = {
          attempted: false,
          correct: 0,
          percentageCorrect: 0,
          totalQuestions: 0
        }
      }
      let assignmentReturnSet = {
        id: assgn.id,
        name : assgn.name,
        assignmentType: assgn.assignmentType,
        dueDate: assgn.dueDate,
        attempted: assignResult.attempted,
        correct: assignResult.correct,
        percentageCorrect: assignResult.percentageCorrect,
        totalQuestions: assignResult.totalQuestions
      }
      classReturnSet.assignments.push(assignmentReturnSet);
    }
  }
  return resultSet;
} 

export async function get_student_answers(assignment_id, student_id) {
  
  console.log(assignment_id, student_id)

 let resultSet = as_object(
    await db.collection("assignments").doc(assignment_id).collection("answers").doc(student_id).get()
    // doc(student_id)
    );
    console.log("get_student_answers", resultSet);
    return resultSet;
}

export async function load_answers(assignment_id) {
    let answers = as_array(await db.collection("assignments").doc(assignment_id).collection("answers").get());
    return answers;
}

export async function get_exam_record(exam_id) {
  let exam = as_object(await db.collection("exam").doc(exam_id).get());
  return exam;
}

export async function get_exam(exam_id, assignment_id) {
  
    let returnRecord = {};
  
    // Pull the primary exam record
    let exam = (await db.collection("exam").doc(exam_id).get()).data();

    returnRecord = {
      assignment_id: assignment_id,
      exam_id: exam_id,
      name : exam.name,
      number_of_questions: exam.questions.length,
      questions: [],
      standards: exam.standards
    }
  
    // Pull the question details from the Test Bank
    for(let question=0; question < exam.questions.length; question++){
      let examQuestion = exam.questions[question];
      let questionDoc = await db.collection("testBank").doc(examQuestion.question_id).get()
      let qst = questionDoc.data();
      qst.id = examQuestion.question_id;
      qst.index = question+1;
      returnRecord.questions.push(qst);
    }


    console.log('returnRecord', returnRecord)
  
    return returnRecord;
  }