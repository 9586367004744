<script>

  import { navigate } from "svelte-routing";
  import {
    sign_in_with_google,
    sign_in_with_email_and_password
  } from "./auth.js";

  import { list_student_courses, list_teacher_classes, get_student_classes, get_teacherRecord, get_studentRecord } from "./db/general";
  import { auth_store, is_teacher, is_student, is_nobody } from "./auth.js";

  async function getDefaultClass() {

    if(is_teacher($auth_store)) {
      let teacherClasses = await list_teacher_classes();
      return teacherClasses[0].id;
    } else if (is_student($auth_store)) {
      let studentClasses = await get_student_classes();
      return studentClasses[0].id;
    }
  }

  async function login_with_google() {
    await sign_in_with_google();
    document.getElementById("login-modal-close").click();
    let classId = await getDefaultClass();
    navigate("/" + classId);
  }

  let login_email;
  let login_password;
  async function login_with_password() {
    await sign_in_with_email_and_password(login_email, login_password);
    document.getElementById("login-modal-close").click();
    let classId = await getDefaultClass();
    navigate("/" + classId);
  }

  // TODO: delete this
  function set_demo_user(_email) {
    login_email = _email;
    if(login_email != 'student6@students.school.org'){
      login_password = "password";
    }else{
      login_password = "123456";
    }
    // login_password = "password";
    class_code = "";
  }
</script>

<style>
  .loginTitle {
    font-weight: bold;
    color: #0084ff;
    padding-left:100px;
  }

  .loginButton {
    background-color: #0084ff;
    color: white;
    font-weight:bold;
    width: 200px;
    border-radius: 20px;

  }
</style>
<div
  class="modal fade"
  id="login-modal"
  tabindex="-1"
  aria-labelledby="login-modal-label"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

      <h2 class="loginTitle">SMARTER MATH</h2>
      <button
          id="login-modal-close"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <!--<div class="modal-header">
        <button
          type="button"
          class="btn btn-success"
          on:click={login_with_google}>
          Login with Google
        </button>
        <button
          id="login-modal-close"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>-->
      </div>

      <form on:submit|preventDefault={login_with_password}>
        <div class="modal-body">
          <div class="mb-3">
            <!--<label for="email-field" class="form-label">Email</label>-->
            <input
              type="email"
              placeholder="Email or Username"
              class="form-control"
              id="email-field"
              required
              bind:value={login_email} />
          </div>
          <div class="mb-3">
            <!--<label for="password-field" class="form-label">Password</label>-->
            <input
              type="password"
              placeholder="Password"
              class="form-control"
              id="password-field"
              required
              bind:value={login_password} />
          </div>
          <div class="mb-3">
            <!--<label for="password-field" class="form-label">Password</label>-->
            <input
              type="text"
              placeholder="Class Code"
              class="form-control"
              id="class_code"/>
          </div>

        </div>

        <div class="modal-footer" style="margin-right:25px;">
          <!--<button type="button" class="btn btn-light">Forgot password?</button>
          <button type="button" class="btn btn-outline-primary">
            Register
          </button>
          <button type="submit" class="btn btn-primary">Login</button>-->
          <button
            type="button"
            class="btn loginButton"
            on:click={login_with_google}>
            <i class="fab fa-google"></i> Login with Google
          </button>
          <button type="submit" class="btn loginButton">Submit</button>
        </div>

        <div class="modal-footer" style="background-color: #dad99e;">
          <button
            type="button"
            class="btn btn-light"
            on:click={() => set_demo_user('mr.la@school.org')}>
            Mr. La
          </button>
          <button
            type="button"
            class="btn btn-light"
            on:click={() => set_demo_user('mr.tim@school.org')}>
            Mr. Tim
          </button>
          <button
            type="button"
            class="btn btn-light"
            on:click={() => set_demo_user('maria@students.school.org')}>
            Maria
          </button>
          <button
            type="button"
            class="btn btn-light"
            on:click={() => set_demo_user('student6@students.school.org')}>
            Mike
          </button>
          <button
            type="button"
            class="btn btn-light"
            on:click={() => set_demo_user('alice@students.school.org')}>
            Alice
          </button>
          <button
            type="button"
            class="btn btn-light"
            on:click={() => set_demo_user('bob@students.school.org')}>
            Bob
          </button>
        </div>

      </form>

    </div>
  </div>
</div>
