<script lang="js">
  export let location;
  location = location;
  export let assignment_id;
  export let student_id;
  import { current_user_id,current_user_email } from "../auth.js";
  import { get_assignment } from "../db/general"
  import { get_student_answers , get_exam , delete_assign} from "../db/exams";
  
  import StudentSideBar from "./StudentSideBar.svelte";
  import NormalTest from "../tests/NormalTest.svelte";
  import PerformanceTest from "../tests/PerformanceTest.svelte";
import { each } from "svelte/internal";
import { formatDate } from "../db/dbUtil.js";
import Time from "svelte-time";

  async function fetch_test(assignment) {
    const response = await fetch("/data/" + assignment.name + ".json");
    console.log(response)
    return await response.json();
  }
  current_user_id().then((d)=>{
    // console.log(d)
    student_id =d
  });

  async function date_string(d){

    console.log(d)
    return new Date(d.seconds*1000).toDateString()
  }

 
</script>


<style>
  th,
  td {
    text-align: right;
  }
</style>

<StudentSideBar >

  {#await get_assignment(assignment_id)}
  <div class="spinner-border text-warning" role="status">
    <span class="sr-only">Loading...</span>
  </div>
{:then assignment}
  <!-- <pre>{JSON.stringify(assignment, null, 2)}</pre> -->
  <h2>Assignment Name: {assignment.name}</h2>
 


  <!-- {#await fetch_test(assignment)}
    <div class="spinner-border text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  {:then test} -->
  <!-- <pre>{JSON.stringify(test, null, 2)}</pre> -->


    {#await get_student_answers(assignment_id, student_id)}
      <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    {:then answers}
      <!-- <pre>{JSON.stringify(answers, null, 2)}</pre> -->
      <table>
        <tr>
          <th>Answered:</th>
          <td>{answers.totalQuestions} questions</td>
        </tr>
        <tr>
          <th>Correct:</th>
          <td>{answers.correctAnswers} questions</td>
        </tr>
        <tr>
          <th>Total Score:</th>
          <td>{answers.percentageCorrect} / 100 
          
            
          </td>
        </tr>
       <tr>
          <th>Due Date:</th>
          {#await date_string(assignment.dueDate)}
            <!-- promise is pending -->
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          {:then value}
          {value}
            <!-- promise was fulfilled -->
            <!-- promise was rejected -->
          {/await}
        </tr>
      </table>

      

      {#await get_exam (assignment.exam_id , assignment_id)}
      <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      {:then exam } 
      <!-- <pre>{JSON.stringify(exam, null, 2)}</pre> -->
      
      <section>
        <strong>
        Exam Name :- {exam.name}

        </strong>
        <br>
        <p>
          <b>Answared Details:-</b>
        </p>
      </section>

<ul>
  {#each exam.questions as item , i}
<li>
  <p ><b>
    Question No:-  {i+1}  
  </b></p>
  {@html item.question}
   
  <br>

  <!-- Answare of question -->

  {#if item.type == 'multiple-choice'}
     <!-- content here -->

     <section style="width: 500px">
       
       <section style="float:left">
         <ul>
           {#each item.answers as an}
              <!-- content here -->
              {#if an.key == 'a'}
                 <!-- content here -->
                 <li>
                 ({an.key}) {@html an.data}
   
                </li>
              {/if}
              {#if an.key == 'b'}
              <!-- content here -->
              <li>
              ({an.key}) {@html an.data}

             </li>
           {/if}
           {/each}
        
          
         </ul>
       </section>
       
       <section style="float:right;">
        <ul>
          {#each item.answers as an}
             <!-- content here -->
             {#if an.key == 'c'}
                <!-- content here -->
                <li>
                ({an.key}) {@html an.data}
  
               </li>
             {/if}
             {#if an.key == 'd'}
             <!-- content here -->
             <li>
             ({an.key}) {@html an.data}

            </li>
          {/if}
          {/each}
       
         
        </ul>
         
       </section>
      </section>
      <br><br><br>
<section>
 

  
  {#each item.answers as cAns}
     <!-- content here -->

     {#each answers.questions as gAns}
       {#if gAns.answerGiven == item.correctAnswers[0] && gAns.question_id == item.id}
          <!-- content here -->
        
           {#if cAns.key == gAns.answerGiven.toLowerCase()}
              <!-- content here -->
              <p style="color:green ;"><b>Given Answare:- {@html cAns.data} </b></p>      
           {/if}
        
       {:else if  gAns.answerGiven != item.correctAnswers[0] && gAns.question_id == item.id}
          <!-- else content here -->
          
            {#if cAns.key == gAns.answerGiven.toLowerCase()}
               <!-- content here -->
           <p style="color:red ;"><b>Given Answare:-  {@html cAns.data} </b></p>   
            {/if}
        

           
            {#if cAns.key == item.correctAnswers[0].toLowerCase()}
               <!-- content here -->
               <p style="color:green ;"><b>Correct Answare:- {@html cAns.data}</b></p>     
            {/if}
          
       {/if}
     {/each}
   
     
  {/each}
  {#each item.hints as hint}
  <!-- content here -->
 
  <!-- content here -->
  <section>
    Hints:-
    <section>
      {@html hint.hint}
    </section>
  </section>

{/each}
</section>
  
  {:else if item.type == 'match' }

  <section style="width:100% ;">
    
    <section >
      <div class="row">
        <div class="col-sm-6">
          <ul>
            <ul>
              {#each item.answers as an}
                 <!-- content here -->
                 {#if an.key == 'a'}
                    <!-- content here -->
                    <li>
                    ({an.key}) {@html an.data}
      
                   </li>
                 {/if}
                 {#if an.key == 'b'}
                 <!-- content here -->
                 <li>
                 ({an.key}) {@html an.data}
   
                </li>
              {/if}
              {#if an.key == 'c'}
              <!-- content here -->
              <li>
              ({an.key}) {@html an.data}

             </li>
           {/if}
           {#if an.key == 'd'}
           <!-- content here -->
           <li>
           ({an.key}) {@html an.data}

          </li>
        {/if}
              {/each}
           
             
            </ul>
        </div>
        <div class="col-sm-6">
          <ul>
            {#each item.answers as an}
               <!-- content here -->
               {#if an.key == 'w'}
                  <!-- content here -->
                  <li>
                  ({an.key}) {@html an.data}
    
                 </li>
               {/if}
               {#if an.key == 'x'}
               <!-- content here -->
               <li>
               ({an.key}) {@html an.data}
 
              </li>
            {/if}
            {#if an.key == 'y'}
            <!-- content here -->
            <li>
            ({an.key}) {@html an.data}

           </li>
         {/if}
         {#if an.key == 'z'}
         <!-- content here -->
         <li>
         ({an.key}) {@html an.data}

        </li>
      {/if}
            {/each}
         
           
          </ul>
        </div>
      </div>
    </section>
   </section>
   <br><br><br>
<section>
</section>

  {#each answers.questions as gAns}
     <!-- content here -->
     {#if gAns.question_id == item.id && gAns.answerCorrect == true}
        <!-- content here -->

        <p style="color:green ;">Given Answare:- {gAns.answerGiven}</p>
     {:else if  gAns.question_id == item.id && gAns.answerCorrect == false}
        <!-- else content here -->
        <p style="color:red ;">Given Answare:- {gAns.answerGiven}</p>
        <p style="color:green ;">Correct Answare:- {gAns.correctAnswers}</p>

     {/if}
  {/each}

  

  {#each item.hints as hint}
  <!-- content here -->
 
  <!-- content here -->
  <section>
    Hints:-
    <section>
      {@html hint.hint}
    </section>
  </section>

{/each}

  {:else if item.type == 'true-false'}
     <!-- else content here -->
     <section >
      
      <section >
        <ul>
          {#each item.answers as a}
             <!-- content here -->
             <li>
             ({a.key}) {@html a.data} 
             </li>
          {/each}
        </ul>
      </section>
      
    
     </section>

     {#each answers.questions as gAns}
     <!-- content here -->
     {#if gAns.question_id == item.id && gAns.answerCorrect == true}
        <!-- content here -->

        <p style="color:green ;">Given Answare:- {gAns.answerGiven}</p>
     {:else if  gAns.question_id == item.id && gAns.answerCorrect == false}
        <!-- else content here -->
        <p style="color:red ;">Given Answare:- {gAns.answerGiven}</p>
        <p style="color:green ;">Correct Answare:- {gAns.correctAnswers}</p>

     {/if}
  {/each}



  

  {#each item.hints as hint}
     <!-- content here -->
    
     <!-- content here -->
     <section>
       Hints:-
       <section>
         {@html hint.hint}
       </section>
     </section>

  {/each}

  {:else if item.type == "fill-small" }

  {#each answers.questions as gAns}
  <!-- content here -->
  {#if gAns.question_id == item.id && gAns.answerCorrect == true}
     <!-- content here -->

     <p style="color:green ;">Given Answare:- {gAns.answerGiven}</p>
  {:else if gAns.question_id == item.id &&  gAns.answerCorrect == false}
     <!-- else content here -->
     <p style="color:red ;">Given Answare:- {gAns.answerGiven}</p>
     <p style="color:green ;">Correct Answare:- {gAns.correctAnswers}</p>

  {/if}
{/each}

{#each item.hints as hint}
<!-- content here -->

<!-- content here -->
<section>
 Hints:-
 <section>
   {@html hint.hint}
 </section>
</section>

{/each}



     <br><br><br>
    <section>
  </section>

  {/if}

  
 

  <br/> <br>
</li> 


 {/each} 
 
</ul>
    


 

      {/await}
      


      {/await}
    
      {/await}

      <!-- <table>
        <tr>
          <th class="p-2">#id</th>
          <th class="p-2">Completed</th> 
          <th class="p-2">Answer</th>
          <th class="p-2">Correct</th>
        </tr>
        {#each test as question}
          <tr>
            <td class="p-2">{question.id}</td>
            <td class="p-2" style="text-align: right">
              <input
                class="form-check-input"
                type="checkbox"
                checked={!!answers.answers[question.id]}
                disabled />
            </td> 
            {#if answers.answers[question.id]}
              <td class="p-2">{answers.answers[question.id].answer}</td>
              <td class="p-2">{answers.answers[question.id].correct}</td>
            {/if}
          </tr>
        {/each}
      </table> -->

 

     




</StudentSideBar>