import { save_answers } from "./db/exams";
import { current_user_email } from "./auth.js";

export default class Judge {
  constructor(assignment_id) {
    this.assignment_id = assignment_id;
    this.answers = {};
  }

  validateAnswer(question, answer) {
    let answers = [];
    // Is this a single or multiple answer segment
    if(question.correctAnswers){
      if(question.correctAnswers.length > 1) {
        // We have a multi part answer so we need to split that answer
        answer.split('|').forEach(f=>{
          if(f !=''){
            answers.push(f);
          }
          
        });
      }else {
        answers.push(answer);
      }

      console.log(answer)
  
      let answerCorrect = true;
      answers.forEach((ans)=>{
        if(!question.correctAnswers.includes(ans)) {
          answerCorrect = false; 
        };
      });
  
      return answerCorrect;
    }
   
  }

  on_update(total, question, answer) {
    // Has the student provided all the answers needed for the question?
    console.log('on_update Called', total, question, answer);
    
    let correctAnswers = question.correctAnswers;
    const studentAnswer = answer.toUpperCase();

    console.log(this.answers)

    // this.answers[question.id] = {
    //   answer: my_answer,
    //   correct: my_answer == correct_answer,
    // };
    let answered = 0;
    let correct = 0;
    for (const id in this.answers) {
      answered += 1;
      if (this.answers[id].correct) correct += 1;
    }



    // save_answers(this.assignment_id, correctAnswers, question, studentAnswer );
  }
}
