

<script>
  import Editor from "cl-editor/src/Editor.svelte"
  import { initializeApp } from "firebase/app";

import { getStorage , ref, uploadBytesResumable, getDownloadURL} from "firebase/storage";

import { firebaseConfig } from "../../storage";



export let saveFormDate;
export let alert;
export let d_id;
export let d_n;
export let clearSelect;


  // Initialize Firebase
  const app = initializeApp(firebaseConfig);


// Initialize Cloud Storage and get a reference to the service
 const storage = getStorage(app);

     var percent =''
     var fileLink=[]

     var file=''
     
function getUrl(b){
  setTimeout(() => {
  fileLink.push(b)
    console.log(fileLink)
  }, 1000);

  
}

 let leftArraySerise = [{key:'a' , no: 1},{key:'b' , no: 2}, {key:'c' , no: 3}, {key:'d' , no: 4}, {key:'e' , no: 5}, {key:'f' , no: 6}]
 let rightArraySerise = [{key:'u' , no: 1},{key:'v' , no: 2},{key:'w' , no: 3},{key:'x' , no: 4},{key:'y' , no: 5}, {key:'z' , no: 6}]

 

let leftCRList =[]
let rightCRList =[]

let leftCorrect=''
let rightCorrect=''

let leftCRListAns =[]
let rightCRListAns =[]
let correctAnswers =[]
let data;

    let mt = {
        
        quetsion:'',
        leftOptions:[],
        rightOptions:[],

        correct:{
            left:[],
            right:[]
        },
        hints:''

    }

    let left =''
    let leftArray=[]
    function AddLeft(){

        if(left && leftArraySerise.length <= 6 ){
            let l = leftArraySerise[0]
            
            mt.leftOptions.push({
              key:l.key,
              no:l.no,
              group:1,
              data:left
            })


           

            left=''

            setTimeout(() => {
                leftArraySerise = leftArraySerise.filter(s=> s.no != l.no)
               let d = leftArraySerise.sort((a , b )=>{
                   return a.no - b.no
               })

               leftArraySerise = d

               leftArray = mt.leftOptions

               let a = mt.leftOptions.sort((a,b)=>{
                return a.no - b.no

               })

               leftArray = a
               leftCRList = a

            }, 1000);
        }
    }

    let right =''
    let rightArray=[]
    function AddRight(){
        if(right &&  rightArraySerise.length <= 6){
            let r = rightArraySerise[0]

            mt.rightOptions.push(
                {
              key:r.key,
              no:r.no,
              group:2,
              data:right
            }
            )
           
            right=''

            setTimeout(() => {
                rightArraySerise = rightArraySerise.filter(s=> s.no != r.no)
               let d = rightArraySerise.sort((a , b )=>{
                   return a.no - b.no
               })

               rightArraySerise = d

               rightArray = mt.rightOptions

               let a = mt.rightOptions.sort((a,b)=>{
                return a.no - b.no

               })

               rightArray = a
               rightCRList =a
            }, 1000);

        }
    }


    function deleteLeft(e){
        if(e){
            let d = mt.leftOptions.filter(f=> f.no != e.no)
            mt.leftOptions = d
            leftArray = d

            leftArraySerise.push({
                key: e.key,
                no: e.no
            })

            setTimeout(() => {
               let d=  leftArraySerise.sort((a , b)=>{
                   return a.no - b.no
               })
               leftArraySerise=d
            }, 1000);

        }
    }

    function deleteRight(e){
        if(e){
            let d = mt.rightOptions.filter(f=> f.no != e.no)
            mt.rightOptions = d
            rightArray = d

            rightArraySerise.push({
                key: e.key,
                no: e.no
            })

            setTimeout(() => {
               let d=  rightArraySerise.sort((a , b)=>{
                   return a.no - b.no
               })
               rightArraySerise=d
            }, 1000);
        }
    }
    
    let cursorX=0
    let cursorY=0
    function clickPosition(e){
    cursorX = e.pageX;
    cursorY = e.pageY;
    console.log(cursorX,cursorY)
    }

    function clickPositionDraw(e){
      let x=e.pageX
      let y =e.pageY
      console.log(x,y)
// var c = document.getElementById("myCanvas");
// var ctx = c.getContext("2d");
// ctx.beginPath();
// ctx.moveTo(cursorX, cursorY);
// ctx.lineTo(x, y);
// ctx.stroke();
    }


    async function leftEventCorrect(){
    
        if(leftCorrect){
        leftCRListAns=leftCRListAns.filter(f => f.no != leftCorrect.no)

            leftCRListAns.push({
                key:  leftCorrect.key,
                data: leftCorrect.data,
                no: leftCorrect.no
            })




           let s = leftCRList.filter(f=> f.no != leftCorrect.no )

           leftCRList = s.sort((a,b)=>{
               return a.no - b.no
           })

           setTimeout(() => {
          
            leftCRListAns = leftCRListAns
            leftCorrect=''
           }, 1000);


        }
    }

    async function rightEventCorrect(){
        if(rightCorrect){
        rightCRListAns=rightCRListAns.filter(f => f.no != rightCorrect.no)

            rightCRListAns.push({
                key:  rightCorrect.key,
                data: rightCorrect.data,
                no: rightCorrect.no
            })

            let s = rightCRList.filter(f=> f.no != rightCorrect.no )

            rightCRList = s.sort((a,b)=>{
                return a.no - b.no
           })

           setTimeout(() => {
            rightCRListAns = rightCRListAns
            rightCorrect=''
           }, 1000);
        }
    }


    async function deleteAns(i,j){

        if(i){
            let a1 = leftCRListAns.filter(f=> f.no != i.no)
            let s1 = leftCRList.filter(f=> f.no != i.no)
            s1.push(   {
              key:i.key,
              no:i.no,
              group:1,
              data:i.data
            } )


          

          setTimeout(() => {
            leftCRList=s1

            leftCRList = leftCRList.sort((a,b)=>{
              return a.no - b.no
           })

            leftCRListAns = a1.sort((a,b)=>{
              return a.no -b.no
          })

          }, 1000);


        }

        if(j){
            let a2 = rightCRListAns.filter(f=> f.no != j.no)
    let s2 = rightCRList.filter(f=> f.no != j.no)


      

            s2.push({
                key:j.key,
              no:j.no,
              group:2,
              data:j.data 
            })

      
            setTimeout(() => {
                
                rightCRList=s2

       
          rightCRList = rightCRList.sort((a,b)=>{
        return a.no - b.no
          })

        
          rightCRListAns = a2.sort((a,b)=>{
              return a.no -b.no
          })

          
          
            }, 1000);
        }


        




    }


    async function onSubmit(){
       
  
       let ans = []

       for (let index = 0; index < mt.leftOptions.length; index++) {
          
           ans.push(mt.leftOptions[index])
       }
       for (let i = 0; i < mt.rightOptions.length; i++) {
              
           ans.push(mt.rightOptions[i])
             
            }
       console.log(ans)


        

       for (let index = 0; index < leftCRListAns.length; index++) {
          for (let i = 0; i < rightCRListAns.length; i++) {
              if(index == i){
                correctAnswers.push(leftCRListAns[index].key.toUpperCase()+ ":"+ rightCRListAns[i].key.toUpperCase() )
              }
              
          }
           
       }
   
       console.log(correctAnswers)


       data = {
         answers: ans,
         claim:1,
         correctAnswers:correctAnswers,
         dok:1,
         hints:[{hint:mt.hints}],
         question: mt.quetsion,
         type: "match",
         video:fileLink[0],
       }

       
       console.log(data)
      

      
        
    }

    async function submitData(){
        if(data){
           await saveFormDate(data, "match")
       }
    }

    async function refresh(){
        mt = {
        
        quetsion:'',
        leftOptions:[],
        rightOptions:[],

        correct:{
            left:[],
            right:[]
        },
        hints:''

    }
     leftArraySerise =  [{key:'a' , no: 1},{key:'b' , no: 2}, {key:'c' , no: 3}, {key:'d' , no: 4}, {key:'e' , no: 5}, {key:'f' , no: 6}]

  rightArraySerise = [{key:'u' , no: 1},{key:'v' , no: 2},{key:'w' , no: 3},{key:'x' , no: 4},{key:'y' , no: 5}, {key:'z' , no: 6}]

 leftCRList =[]
 rightCRList =[]

 leftCorrect=''
 rightCorrect=''

 leftCRListAns =[]
 rightCRListAns =[]
 correctAnswers =[]

  left =''
 leftArray=[]

  right =''
 rightArray=[]
  data=null

 setTimeout(async () => {

   await clearSelect()
 }, 1000);
    }

</script>



<section>
    <h3>Create Match Question</h3>
    
     <div>
        <div class="form-group">
            <label for="exampleFormControlTextarea1">Write your question ?</label>
            <!-- <textarea bind:value={mt.quetsion} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea> -->
            <Editor   on:change={(evt)=>{
                mt.quetsion = evt.detail
                
                // console.log(mq.question)
                }}/>
          </div>
     </div>
    
     <div class='row' style="width:50%; float:left" >
         <div class="col">
            <div class="form-group">
                <label for="formGroupExampleInput">Write Left Side Data</label>
                <input bind:value={left} type="text" class="form-control" id="formGroupExampleInput" placeholder="Example input">
              </div>
         </div>

         <div class="col">
            <button class='btn btn-primary' style="margin: 30px;" on:click={AddLeft}>ADD</button>

         </div>

       
       
     </div>
     <div class='row' style="width:50%; float:right" >
        <div class="col">
           <div class="form-group">
               <label for="formGroupExampleInput1">Write Right Side Data</label>
               <input bind:value={right} type="text" class="form-control" id="formGroupExampleInput1" placeholder="Example input">
             </div>
        </div>

        <div class="col">
           <button class='btn btn-primary' style="margin: 30px;" on:click={AddRight}>ADD</button>

        </div>
      
    </div>
    
     <div class="row" style="width:50%; float:left">
         <ol>
             {#each leftArray as item}
                  <!-- content here -->
                  <li>
                     ({item.key}) {item.data}  <span style="cursor:pointer ;" on:click={deleteLeft(item)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                      </svg></span>
                  </li>
             {/each}

         </ol>
     </div>

    

    <div class="row" style="width:50%; float:right">
        
            <ol>
                {#each rightArray as item}
                     <!-- content here -->
                     <li>
                        ({item.key})  {item.data}  <span style="cursor:pointer ;" on:click={deleteRight(item)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                           <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                         </svg></span>
                     </li>
                {/each}
   
            </ol>
    </div>
    
    </section>

    <section>
      

        <section>
            <h4>
                <strong>
                    Correct Match:-
                </strong>
            </h4>

            <form>
                <div class="row">
                  <div class="col">
                    <select class="form-control" bind:value={leftCorrect} on:change={leftEventCorrect}>
                        <option value="" selected>Choose Left Option</option>
                        {#each leftCRList as item}
                             <!-- content here -->
                             <option value={item}>{item.data}</option>
                        {/each}
                      </select>
                  </div>
                  <div class="col">
                    <select class="form-control" bind:value={rightCorrect} on:change={rightEventCorrect}>
                        <option value="" selected>Choose Right Option</option>
                        {#each rightCRList as item}
                        <!-- content here -->
                        <option value={item}>  {item.data}  </option>

                   {/each}
                      </select>
                  </div>
                </div>
              </form>
        </section>

    </section>

    {#if leftCRListAns.length > 0 && rightCRListAns.length > 0}
         <!-- content here -->

         <section>

            <div class="row">
                <div class="col">
                    <ul>
                        {#each leftCRListAns as i, s1}
                        <li>
                            ({i.key}) {i.data}   <span style="cursor: pionter ;" on:click={deleteAns(i, null)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                  </svg>
                            </span>
                        </li>
                        {/each}
                      </ul>
                </div>

                <div class="col">
                    <div 
                    style="
                        border-left: 1px solid green;
                        height: 100px;
                      "></div>
                </div>
               
                <div class="col">
                    <ul>
                        {#each rightCRListAns as j, s1}
                        <li>
                        ({j.key}) {j.data}   <span style="cursor: pionter ;" on:click={deleteAns(null, j)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                              </svg>
                        </span>
                        </li>
                        {/each}
                      </ul>
                </div>
           
            </div>

           


         </section>
        
    {/if}





    <section>
        <div class="form-group">
            <label for="hints">Hints:-</label>
            <!-- <textarea class="form-control" id="hints" rows="3" bind:value={mt.hints}></textarea> -->
            <Editor  on:change={(evt)=>{
                mt.hints = evt.detail
                
                // console.log(mq.question)
                }}/>
          </div>
    </section>


    <div class="row" style="width:70% ;">
        <div class="col">
          <div class="form-group">
            <label for="exampleFormControlFile1">Video file upload</label>
            <input type="file" class="form-control-file" id="exampleFormControlFile1"  accept=".mp4" on:change={(e)=>{
              file = e.target.files[0]
        
            }}>
          
  
          </div>
        </div>
        <div class="col">
          <button on:click={async ()=>{
                if(file){
                  fileLink=[]
                    // Upload file and metadata to the object 'images/mountains.jpg'
const storageRef = ref(storage, 'videos/' + file.name);
const uploadTask = uploadBytesResumable(storageRef, file);



// Listen for state changes, errors, and completion of the upload.
uploadTask.on('state_changed',
(snapshot) => {
  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
   percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  // console.log('Upload is ' + progress + '% done');

  

  switch (snapshot.state) {
    case 'paused':
      console.log('Upload is paused');
      break;
    case 'running':
      console.log('Upload is running');
    
      break;
  }


  
}, 
(error) => {
  // A full list of error codes is available at
  // https://firebase.google.com/docs/storage/web/handle-errors
  switch (error.code) {
    case 'storage/unauthorized':
      // User doesn't have permission to access the object
      break;
    case 'storage/canceled':
      // User canceled the upload
      break;

    // ...

    case 'storage/unknown':
      // Unknown error occurred, inspect error.serverResponse
      break;
  }
}, 
() => {
  // Upload completed successfully, now we can get the download URL
  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    console.log('File available at', downloadURL);
  
    if(downloadURL){
      
      getUrl(downloadURL)
    
    }

  
  
 
// /* set up async GET request */
// var req = new XMLHttpRequest();
// req.open("GET", downloadURL, true);
// req.responseType = "arraybuffer";

// req.onload = function(e) {
//   var workbook = XLSX.read(req.response);

//   /* DO SOMETHING WITH workbook HERE */
//   console.log(workbook)
// };

// req.send();

  });
}
);
              }
          }}>Save Video</button>
        </div>
        </div>

        {#if percent}
        <div class="row" style="width:70% ;">
          <div class="col">
            Uploading ..... ({percent} %)
          </div>

          {#if fileLink.length >0}
        <div class="col">
          <a href={fileLink[0]} target="_blank">
         Video Link
          </a>
        </div>
       {/if}
        </div> 
       
        {/if}

<section>
    <div style="width:100%">
        <button class='btn btn-primary'  on:click={onSubmit} data-toggle="modal" data-target="#exampleModalMQ" >Submit</button>
    </div>
</section>
    

<!-- Modal -->
<div class="modal fade" id="exampleModalMQ" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalCenterTitle">Preview Of Question</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            {#if alert}
            <!-- content here -->
            {#if alert.type=='success'}
               <!-- content here -->
               <div class="alert alert-success alert-dismissible fade show" role="alert">
                 <strong>
                   {alert.msg}
                 </strong> 
                 <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                   <span aria-hidden="true">&times;</span>
                 </button>
               </div>
            {:else if alert.type=='danger'}
               <!-- else content here -->
               <div class="alert alert-danger alert-dismissible fade show" role="alert">
                 <strong>
                   {alert.msg}
                 </strong> 
                 <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                   <span aria-hidden="true">&times;</span>
                 </button>
               </div>
            {/if}
         {/if}
        {#if data}
             <!-- content here -->
            
             <div >
                <strong>
                    {@html data.question}
                </strong>
             </div>

             <div class="row">
                 <div class="col">
                     <ul>
                        {#each leftArray as item}
                        <!-- content here -->
                       <li>
                           ({item.key}) {@html item.data}
                       </li>
                   {/each}
                     </ul>
                 
                 </div>
                 <div class="col">
                    {#each rightArray as item}
                    <!-- content here -->
                    <li>
                        ({item.key}) {@html item.data}
                    </li>
               {/each}
                 </div>
             </div>

             <div class="row">
                 <div class="col">
                     <div style="color:green">
                         Correct Answers:- 
                         <ul>
                            {#each data.correctAnswers as item}
                                 <!-- content here -->
                                 <li>
                                   {item}
                                 </li>
                            {/each}
                        </ul>
                     </div>
                 </div>
             </div>

             <div class="row">
                 <div class="col">
                     <p>Hints:-</p>
                     <div>
                         {@html data.hints[0].hint}
                     </div>
                 </div>
             </div>
             <section>
                <p><strong>Video:-  <a href={fileLink[0]} target="_blank">
                  Video Link
                  </a></strong></p>
                
              </section>
        {/if}
        </div>
        {#if alert}
        <!-- content here -->
        {#if alert.type !='success'}
        <!-- content here -->
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary"  on:click={submitData}>Save changes</button>
          </div>
     {/if}

     {#if alert.type =='success'}
        <!-- content here -->
        <button type="button" on:click={refresh} class="btn btn-secondary" data-dismiss="modal">Close</button>
     {/if}

     {:else}
        <!-- else content here -->
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary"  on:click={submitData}>Save changes</button>
          </div>
     {/if}

       
      </div>
    </div>
  </div>