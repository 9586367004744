
<script>
  import { link, navigate } from "svelte-routing";

	import { onMount } from 'svelte';
  import TeacherSideBar from "./TeacherSideBar.svelte";
  import { get_all_data, draft_assignment, add_test_to_testBank, delete_test_to_testBank} from "../db/exams";
  
  
    export let class_id;
    let classDetails;
    let assignment=[]

    onMount(async ()=>{
        console.log(class_id)
        await get_data()
    })

    async function get_data(){
        let s = await get_all_data()
        console.log(s)

        let c = s.classList.filter(f=> f.id == class_id )
        let ass =  s.ass.filter(f=> f.class_id == class_id )
        
        classDetails = c[0]
        assignment = ass


    }


    async function  dueDateToString(d){
  // console.log(d)
   return  new Date(d.seconds *1000).toDateString()
  }


</script>

<TeacherSideBar {class_id}>
   
    <div class="container">
        <div class="row">
            <h4>
                View Class Progress here:-
            </h4>
        </div>
    <div class="row">
        <div class="table-responsive table-hover">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Assignment Name</th>
                  <th scope="col">Dua Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {#each assignment as item, i}
                   <!-- content here -->
        
                   <tr>
                    <th scope="row">{i+1}</th>
                    <td>{item.name}</td>
                    <td>
                    {#await dueDateToString(item.dueDate)}
                      <!-- promise is pending -->
                    {:then value}
                    {value}
                      <!-- promise was fulfilled -->
                    {:catch error}
                      <!-- promise was rejected -->
                    {/await}
                    </td>
                    <td>
                      <!-- svelte-ignore missing-declaration -->
                      <a href="/class/{item.class_id}/assignment/{item.id}"  use:link>View</a>
                    </td>
                  </tr>
                {/each}
              
             
            </table>
          </div>
    </div>
    </div>

</TeacherSideBar>

