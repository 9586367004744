export function as_object(doc) {
  console.log(doc.data())
    return { id: doc.id, ...doc.data() };
  }
  
export function as_array(query) {
  const result = [];
  query.forEach((item) => result.push({ id: item.id, ...item.data() }));
  return result;
}

export function as_single_object(doc) {
  return { id: doc.docs[0].id, ...doc.docs[0].data()};
}

export function unique(arr) {
  var u = {}, a = [];
  for(var i = 0, l = arr.length; i < l; ++i){
      if(!u.hasOwnProperty(arr[i])) {
          a.push(arr[i]);
          u[arr[i]] = 1;
      }
  }
  return a;
}

export function formatDate(date, format) {
  format = format || 'MM-DD-YYYY';
  let dateObject = new Date(date.seconds*1000)
  return moment(dateObject).format(format);
}