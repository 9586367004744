const db = firebase.firestore();
import {current_user_id} from '../auth';
import {as_object, as_array, as_single_object} from './dbUtil'

// ------------------------------------------------------------------------------------------------------------------------------
// Students
// ------------------------------------------------------------------------------------------------------------------------------

export async function all_students(){
  let s = as_array (await db.collection('students').get())

  return s
}

export async function one_students(id){
  let s = as_object (await db.collection('students').doc(id).get())
  console.log(s)
  return s
}

export async function addClasses(stu, data){

   if(data.classId){
    let s = as_object(await db.collection('classes').doc(data.classId).get())
    let s1 = as_object(await db.collection('students').doc(stu.id).get())
    
    if(s1){
      let ch = s1.classes

      if(ch){
        let h= ch.filter(f => f.classID == data.classId )

        if(h.length > 0){
          return s1
        }else{
          if(s){
            let st = s.students
            if(!st){
              st=[]
            }
            st.push(stu.id)
           setTimeout(async () => {
            let d = {
              courseName: s.courseName,
           
              course_id:s.course_id,
           
              create: s.create,
              name: s.name,
               order:1,
              students:st,
              // teacher_id:s.teacher_id
               }
      
               await db.collection('classes').doc(data.classId).update(d)
      
           }, 1000);
          }
      
          if(s1){
            let c = s1.classes
            if(!c){
              c=[]
            }
            c.push({class:s.name , classID: s.id})
      
           setTimeout( async () => {
            let d={
              firstName:s1.firstName,
              lastName:s1.lastName,
              email:s1.email,
              UUID:s1.UUID,
              classes:c
            }
      
            await db.collection('students').doc(stu.id).update(d)
          
           }, 1000);

          
            return as_object(
              await db.collection('students').doc(stu.id).get()
          
             )

             
          
          }
        }
      }else{
        if(s){
          let st = s.students
          if(!st){
            st=[]
          }
          st.push(stu.id)
         setTimeout(async () => {
          let d = {
            courseName: s.courseName,
         
            course_id:s.course_id,
         
            create: s.create,
            name: s.name,
             order:1,
            students:st,
            // teacher_id:s.teacher_id
             }
    
             await db.collection('classes').doc(data.classId).update(d)
    
         }, 1000);
        }
    
        if(s1){
          let c = s1.classes
          if(!c){
            c=[]
          }
          c.push({class:s.name , classID: s.id})
    
         setTimeout( async () => {
          let d={
            firstName:s1.firstName,
            lastName:s1.lastName,
            email:s1.email,
            UUID:s1.UUID,
            classes:c
          }
    
          await db.collection('students').doc(stu.id).update(d)
         
         }, 1000);
        
          return as_object(
            await db.collection('students').doc(stu.id).get()
        
           )
       
        }
      }
    }


 
   }else{
    let s1 = as_object(await db.collection('students').doc(stu.id).get())
 return s1
   }

  

}



export async function deleteClass_student(stu, cls){
  if(stu && cls){
    let s = as_object(await db.collection('classes').doc(cls).get())
    let s1 = as_object(await db.collection('students').doc(stu.id).get())

    if(s){
      let p=[]
      if(s.students){
        p= s.students.filter(f=> f != s1.id)

       
          let d = {
            courseName: s.courseName,
         
            course_id:s.course_id,
         
            create: s.create,
            name: s.name,
             order:1,
            students:p,
            // teacher_id:s.teacher_id
             }
    
             await db.collection('classes').doc(s.id).update(d)
            
      }

    }

    if(s1){
      let p=[]
      if(s1.classes){
        p= s1.classes.filter(f=> f.classID != cls)

       
          let d={
            firstName:s1.firstName,
            lastName:s1.lastName,
            email:s1.email,
            UUID:s1.UUID,
            classes:p
          }
    
          await db.collection('students').doc(stu.id).update(d)
         
       
      }
    }

    return  as_object(await db.collection('students').doc(stu.id).get())

    

  }
}


export async function get_student_by_id(id) {
  console.log(id)
  let s=  as_object(await db.collection("students").doc(id).get())
  console.log(s)

  if(s){
    return s; 
  }

 

}




export async function get_student(student_email) {
  return await as_single_object(await db.collection("students").where("email","==",student_email).get()); 
}


export async function get_studentRecord(student_id) { 
  if(!student_id) {
    student_id = await current_user_id();
  }
  return await as_object(await db.collection("students").doc(student_id).get());
}

export async function get_teacher(teacher_email) {
  return await as_single_object(await db.collection("teachers").where("email","==",teacher_email).get()); 
}

export async function get_teacherRecord(teacher_id) {
  if(!teacher_id) {
    teacher_id = await current_user_id();
  }
  return await as_object(await db.collection("teachers").doc(teacher_id).get());
}

export async function new_student(student) {
  if(student){
    // let s= as_object(await db.collection("students").doc( student.email).get())

  
      return await db.collection("students").add(student);
    
  }
  
}

export async function new_student_edit(student, data) {
  if(student){
    // let s= as_object(await db.collection("students").doc( student.email).get())

  
     await db.collection("students").doc(student.id).update(data);

     return as_object(await db.collection("students").doc( student.id).get())
    
  }
  
}

export async function get_class_students(class_id) {
  let students = [];
  let classRec = await get_class(class_id);
  for(let i=0; i < classRec.students; i++) {
    let student = await get_studentRecord(classRec.students[i].id);
    console.log(student);
  }
}

// ------------------------------------------------------------------------------------------------------------------------------
// Courses
// ------------------------------------------------------------------------------------------------------------------------------
export async function list_student_courses() {
  let studentId = await current_user_id();
  let classList = await get_student_classes(studentId);
  let courses = [];
  for(let classNum=0; classNum < classList.length; classNum++){
    //console.log('id',classList[classNum].course_id);
    let course = as_object(await db.collection("courses").doc(classList[classNum].course_id).get());
    classList[classNum].students = []; // Remove Student collection for security
    course.class = classList[classNum];
    courses.push(course);
  }
  return courses;
}

export async function get_all_course(){
  let s= as_array(await db.collection("courses").get())
  console.log(s)
  return as_array(await db.collection("courses").get())
}

export async function add_course(data){
  return await db.collection('courses').add(data)
}

export async function put_course(course_id,data){
  let s =  await db.collection('courses').doc(course_id).get()

  if(s){
    await  db.collection('courses').doc(course_id).update(data)
    return s
  }
}

export async function get_one_course(id){
  let s = as_object( await db.collection('courses').doc(id).get() )
  
  return s
}

// ------------------------------------------------------------------------------------------------------------------------------
// Classes
// ------------------------------------------------------------------------------------------------------------------------------
export async function list_teacher_classes(teacher_id) {
  teacher_id = teacher_id === undefined ? await current_user_id(): teacher_id; // Assume Current ID if one is not provided
  let returnArray = as_array(await db.collection("classes").where("teacher_id", "==", teacher_id).get()).sort((a,b)=>{
    if(a.order < b.order) return -1;
    if(a.order > b.order) return 1;
    return 0;
  });
  return returnArray;
}

export async function get_all_classes() {
  return as_array(await db.collection("classes").get()) 
}


export async function new_class(data) {
 

  return await db.collection("classes").add(data);
}

export async function get_class(class_id) {
  return as_object(await db.collection("classes").doc(class_id).get());
}

export async function put_class(class_id, data) {
    await db.collection("classes").doc(class_id).update(data);

  return as_object(await db.collection("classes").doc(class_id).get());

}

export async function new_assignment(data) {
  return await db.collection("assignments").add(data);
}

// ------------------------------------------------------------------------------------------------------------------------------
// Assignments
// ------------------------------------------------------------------------------------------------------------------------------

export async function all_assignment(){
  let s= as_array(await db.collection('assignments').get())
  console.log(s)
  return as_array(await db.collection('assignments').get())
}

export async function get_assignment(assignment_id) {
  
  return as_object(await db.collection("assignments").doc(assignment_id).get());
}

export async function get_my_assignments(student_email) {
  return as_array(await db.collection("assignments").where("student_emails", "array-contains", student_email).get());
}

export async function get_assignments_for_student(student_id) {
  student_id = student_id || await current_user_id();
  return as_array(await db.collection("assignments").where("students", "array-contains", student_id).get());
}

export async function get_student_classes(student_id) {
  student_id = student_id || await current_user_id();
  let courses = as_array(await db.collection("courses").get());
  let classes =  as_array(await db.collection("classes")
                .where("students","array-contains", student_id)
                .get()).sort((a,b) => (a.order > b.order) ? 1:-1)
  classes.forEach(cls=>{
    cls.courseName = courses.find(f=>f.id == cls.course_id).name;
  })
  return classes;
}

export async function get_my_classes(student_email) {
  return as_array(await db.collection("classes").where("student_emails", "array-contains", student_email).get());
}

export async function get_class_assignments(class_id) {

  let classList = as_array(await db.collection("assignments").where("class_id", "==", class_id).get());
  console.log('classList', class_id, classList);
  return classList;
}

export async function get_class_assignments_for_student(class_id, student_id) {
  student_id = student_id || await current_user_id();
  let assignments = as_array(
    await db
      .collection("assignments")
      .where("class_id", "==", class_id)
      .where("students", "array-contains", student_id)
      .get()
  );
  return assignments;
}


