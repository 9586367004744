

import { initializeApp } from "firebase/app";
import { getStorage , ref, uploadBytesResumable, getDownloadURL} from "firebase/storage";


// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
export var firebaseConfig = {
  // ...
  storageBucket: 'gs://smartermath-beta.appspot.com'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Storage and get a reference to the service
 const storage = getStorage(app);

export var progress=''

export var fileLink =''

// export async function uploadExcelFile(file){
//  fileLink =''
// if(file){
//     // Upload file and metadata to the object 'images/mountains.jpg'
// const storageRef = ref(storage, 'videos/' + file.name);
// const uploadTask = uploadBytesResumable(storageRef, file);
 


// // Listen for state changes, errors, and completion of the upload.
// uploadTask.on('state_changed',
//   (snapshot) => {
//     // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
//      progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//     // console.log('Upload is ' + progress + '% done');

    

//     switch (snapshot.state) {
//       case 'paused':
//         console.log('Upload is paused');
//         break;
//       case 'running':
//         console.log('Upload is running');
//         uploadExcelFileProgress(progress)
//         break;
//     }

 
    
//   }, 
//   (error) => {
//     // A full list of error codes is available at
//     // https://firebase.google.com/docs/storage/web/handle-errors
//     switch (error.code) {
//       case 'storage/unauthorized':
//         // User doesn't have permission to access the object
//         break;
//       case 'storage/canceled':
//         // User canceled the upload
//         break;

//       // ...

//       case 'storage/unknown':
//         // Unknown error occurred, inspect error.serverResponse
//         break;
//     }
//   }, 
//   () => {
//     // Upload completed successfully, now we can get the download URL
//     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
//       // console.log('File available at', downloadURL);
//    uploadExcelFileLink(downloadURL)
// // /* set up async GET request */
// // var req = new XMLHttpRequest();
// // req.open("GET", downloadURL, true);
// // req.responseType = "arraybuffer";

// // req.onload = function(e) {
// //   var workbook = XLSX.read(req.response);

// //   /* DO SOMETHING WITH workbook HERE */
// //   console.log(workbook)
// // };

// // req.send();

//     });
//   }
// );
// }
 



// }
// async function uploadExcelFileProgress(p){
//   progress = p
//   console.log('Upload is ' + progress + '% done');
//   return progress
// }


// async function uploadExcelFileLink(p){
//   fileLink = p
//   console.log('Upload file is ' + fileLink );
//   return fileLink
// }

