<script>
    import TeacherSideBar from "./TeacherSideBar.svelte";
    import { onMount } from 'svelte';

   export let class_id;
   export let assignment_id;
   
   let data;
   let assignment;
   let exam;

   import { get_student_answers,get_exam } from "../db/exams";
   import { get_assignment } from "../db/general"

   onMount(async ()=>{
      data = await get_student_answers(assignment_id,class_id)

      console.log(data)
      assignment = await get_assignment(assignment_id)
     
      console.log(assignment)


      if(assignment){
       exam = await get_exam(assignment.exam_id,assignment_id)
      console.log(exam)

      }

     

   })

</script>

<TeacherSideBar>
   
{#if data}
     <!-- content here -->
     <section style="padding:20px ;">
         <h4>
             <strong>
                 Correct Questions:- {data.correctAnswers}
             </strong>
         </h4>
         <h5>
             <strong>
                 Total Questions:- {data.totalQuestions}
             </strong>
         </h5>

         <h4>
            <strong>
               Total Score:- {data.percentageCorrect} / 100
            </strong>
        </h4>
     </section>


     {#if assignment}
          <!-- content here -->

          <section>
    
            {#await get_exam(assignment.exam_id,assignment_id)}
                <!-- get_exam() is pending -->
                <div class="spinner-border text-warning" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
            {:then v}
                <!-- get_exam() was fulfilled -->
                <section>
                    <strong>
                    Exam Name :- {exam.name}
            
                    </strong>
                    <br>
                    <p>
                      <b>Answared Details:-</b>
                    </p>
                  </section>

                  <section>
                      {#each data.questions as d,i}
                           <!-- content here -->
                          {#each exam.questions as e}
                               <!-- content here -->

                               {#if d.question_id == e.id}
                                    <!-- content here -->
                                    <section>
                                        <p>Q) {i+1}</p>
                                        {@html e.question}
      
                                       
                                        {#if d.questiontType ==='multiple-choice'}
                                             <!-- content here -->
                                        <p>Options:-</p>

                                        <ul>
                                            {#each e.answers as a}
                                                 <!-- content here -->
                                                 <li>
                                                    ({a.key}) {@html a.data}
                                                 </li>
                                            {/each}
                                        </ul>


                                        <br>

                                        {#if d.answerCorrect == true}
                                             <!-- content here -->
                                             
                                             {#each e.answers as i}
                                                  <!-- content here -->
                                                {#if i.key == d.answerGiven.toLowerCase()}
                                                     <!-- content here -->
                                                     <div style="color: green;">
                                                        Given answer:-       {@html i.data}

                                                     </div>
                                                {/if}
                                             {/each}
                                            {:else}
                                                 <!-- else content here -->
                                                 {#each e.answers as i}
                                                 <!-- content here -->
                                               {#if i.key == d.answerGiven.toLowerCase()}
                                                    <!-- content here -->
                                                    <div style="color: red;">
                                                   Given answer:-    {@html i.data}

                                                    </div>
                                               {/if}

                                               {#if i.key == d.correctAnswers[0].toLowerCase()}
                                                    <!-- content here -->
                                                    <div style="color: green;">
                                                        Correct answer:-      {@html i.data}

                                                    </div>
                                               {/if}
                                            {/each}
                                        {/if}
                                            
                                        {/if}
                                        
                                      
                                     {#if d.questiontType ==='match'}
                                          <!-- content here -->
                                          <p>Options:-</p>

                                          <div class="row">
                                              <div class="col-sm-6">
                                                <ul>
                                                    {#each e.answers as item}
                                                         <!-- content here -->
                                                         {#if item.key == 'a'}
                                                              <!-- content here -->
                                                              <li>
                                                             ({item.key})   {@html item.data}
                                                              </li>
                                                         {/if}

                                                         {#if item.key == 'b'}
                                                         <!-- content here -->
                                                         <li>
                                                            ({item.key}) {@html item.data}
                                                         </li>
                                                    {/if}
                                                    {#if item.key == 'c'}
                                                    <!-- content here -->
                                                    <li>
                                                        ({item.key}) {@html item.data}
                                                    </li>
                                                    
                                               {/if}
                                               {#if item.key == 'd'}
                                               <!-- content here -->
                                               <li>
                                                ({item.key}) {@html item.data}
                                               </li>
                                          {/if}
                                                    {/each}
                                                    
                                                   
                                                </ul>
                                              </div>
                                              <div class="col-sm-6">
                                                <ul>
                                                    {#each e.answers as item}
                                                         <!-- content here -->
                                                         {#if item.key == 'w'}
                                                              <!-- content here -->
                                                              <li>
                                                                ({item.key})    {@html item.data}
                                                            </li>
                                                         {/if}

                                                         {#if item.key == 'x'}
                                                         <!-- content here -->
                                                         <li>
                                                            ({item.key})   {@html item.data}
                                                       </li>
                                                    {/if}
                                                    {#if item.key == 'y'}
                                                    <!-- content here -->
                                                    <li>
                                                        ({item.key})  {@html item.data}
                                                  </li>
                                               {/if}
                                               {#if item.key == 'z'}
                                               <!-- content here -->
                                               <li>
                                                ({item.key})  {@html item.data}
                                             </li>
                                          {/if}
                                                    {/each}
                                                   
                                                   
                                                </ul>
                                              </div>
                                          </div>
                                         

                                          <div >
                                              {#if d.answerCorrect == true}
                                                   <!-- content here -->
                                                   <p style="color: green ;">
                                                  Given Answer:-     {d.answerGiven}
                                                   </p>

                                                   {:else}
                                                        <!-- else content here -->
                                                        <p style="color: red ;">
                                                            Given Answer:-     {d.answerGiven}
                                                             </p>
                                                             
                                                             <p style="color: green ;">
                                                                Correct Answer:-     {d.correctAnswers}
                                                                 </p>
              
           
                                              {/if}
                                          </div>
                                     {/if}


                                     {#if d.questiontType ==='true-false'}
                                     <p>Options:-</p>

                                     <ul>
                                         {#each e.answers as a}
                                              <!-- content here -->
                                              <li>
                                                ({a.key})  {@html a.data}
                                              </li>
                                         {/each}
                                     </ul>

                                    
                                     <div class="row">
                                        {#if d.answerCorrect == true}
                                             <!-- content here -->
                                             <p style="color: green ;">
                                             Given Answer:- {d.answerGiven}
                                             </p>

                                             {:else}
                                                  <!-- else content here -->
                                                  <p style="color: red ;">
                                                      Given Answer:- {d.answerGiven}
                                                       </p>
                                                       
                                                       <p style="color: green ;">
                                                          Correct Answer:- {d.correctAnswers}
                                                           </p>
        
     
                                        {/if}
                                    </div>

                                     {/if}
                                   
                                    </section>
                                  
                                      


                              

                               {/if}
                            
                          {/each}

                      {/each}
                  </section>

            
            {:catch error}
                <!-- get_exam() was rejected -->
            {/await}
            </section>
     {/if}



{/if}

</TeacherSideBar>


