<script>
    export let value;
</script>

<div class="container">
   
        {#if value.type == 'multiple-choice'}
             <!-- content here -->
            <div>
                {@html value.question}
            </div>
 <br>
            <div class="row">
                <div class="col">
                    <ul>
                        {#each value.answers as item}
                        <!-- content here -->
                        {#if item.key == 'a'}
                             <!-- content here -->
                             <li>
                                 ({item.key}) {@html item.data}
                             </li>
                        {/if}
                        {#if item.key == 'b'}
                        <!-- content here -->
                        <li>
                            ({item.key}) {@html item.data}
                        </li>
                   {/if}
                   {/each}
                
                    </ul>
                </div>
                <div class="col">
                    <ul>
                        {#each value.answers as item}
                        <!-- content here -->
                        {#if item.key == 'c'}
                             <!-- content here -->
                             <li>
                                 ({item.key}) {@html item.data}
                             </li>
                        {/if}
                        {#if item.key == 'd'}
                        <!-- content here -->
                        <li>
                            ({item.key}) {@html item.data}
                        </li>
                   {/if}
                   {/each}
                
                    </ul>
                </div> 
            </div>


            <div>
                <div style="color: green ;">
                    Correct Answers:- 
                    <ul>
                        {#each value.correctAnswers as item}
                             <!-- content here -->
                             <li>
                                 {item}
                             </li>
                        {/each}
                    </ul>
                </div>
            </div>

            <div>
                <div>
                    <p>Hints</p>
                </div>
                <div>
                    {@html value.hints[0].hint}
                </div>
            </div>
    
        {/if}

        {#if value.type == 'match'}
        <!-- content here -->
        <div>
            {@html value.question}
        </div>
<br>
        <div class="row">
            <div class="col">
                <ul>
                    {#each value.answers as item}
                    <!-- content here -->
                    {#if item.key == 'a'}
                         <!-- content here -->
                         <li>
                             ({item.key}) {@html item.data}
                         </li>
                    {/if}
                    {#if item.key == 'b'}
                    <!-- content here -->
                    <li>
                        ({item.key}) {@html item.data}
                    </li>
               {/if}
               {#if item.key == 'c'}
               <!-- content here -->
               <li>
                   ({item.key}) {@html item.data}
               </li>
          {/if}
          {#if item.key == 'd'}
          <!-- content here -->
          <li>
              ({item.key}) {@html item.data}
          </li>
     {/if}
               {/each}
            
                </ul>
            </div>
            <div class="col">
                <ul>
                    {#each value.answers as item}
                    <!-- content here -->
                    {#if item.key == 'w'}
                         <!-- content here -->
                         <li>
                             ({item.key}) {@html item.data}
                         </li>
                    {/if}
                    {#if item.key == 'x'}
                    <!-- content here -->
                    <li>
                        ({item.key}) {@html item.data}
                    </li>
               {/if}
               {#if item.key == 'y'}
               <!-- content here -->
               <li>
                   ({item.key}) {@html item.data}
               </li>
          {/if}
          {#if item.key == 'z'}
          <!-- content here -->
          <li>
              ({item.key}) {@html item.data}
          </li>
     {/if}
               {/each}
            
                </ul>
            </div> 
        </div>


        <div>
            <div style="color: green ;">
                Correct Answers:- 
                <ul>
                    {#each value.correctAnswers as item}
                         <!-- content here -->
                         <li>
                             {item}
                         </li>
                    {/each}
                </ul>
            </div>
        </div>

        <div>
            <div>
                <p>Hints</p>
            </div>
            <div>
                {@html value.hints[0].hint}
            </div>
        </div>

   {/if}

   {#if value.type == 'true-false'}
   <!-- content here -->
   <div>
    {@html value.question}
</div>
<br>
<div class="row">
    <div class="col">
        <ul>
            {#each value.answers as item}
            <!-- content here -->
           
                 <!-- content here -->
                 <li>
                     ({item.key}) {@html item.data}
                 </li>
          
       {/each}
    
        </ul>
    </div>
   
</div>


<div>
    <div style="color: green ;">
        Correct Answers:- 
        <ul>
            {#each value.correctAnswers as item}
                 <!-- content here -->
                 <li>
                     {item}
                 </li>
            {/each}
        </ul>
    </div>
</div>

<div>
    <div>
        <p>Hints</p>
    </div>
    <div>
        {@html value.hints[0].hint}
    </div>
</div>

{/if}

{#if value.type == 'fill-small'}
   <!-- content here -->
   <div>
    {@html value.question}
</div>



<div>
    <div style="color: green ;">
        Correct Answers:- 
        <ul>
            {#each value.correctAnswers as item}
                 <!-- content here -->
                 <li>
                     {item}
                 </li>
            {/each}
        </ul>
    </div>
</div>

<div>
    <div>
        <p>Hints</p>
    </div>
    <div>
        {@html value.hints[0].hint}
    </div>
</div>

{/if}

  
</div>