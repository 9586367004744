
<script >
	import QuestionEditor from './QuestionEditor.svelte';
	import Question from './../../questions/Question.svelte';
	import Short from './Short.svelte';
  import Editor from "cl-editor/src/Editor.svelte"
import { initializeApp } from "firebase/app";

import { getStorage , ref, uploadBytesResumable, getDownloadURL} from "firebase/storage";

import { firebaseConfig } from "../../storage";

  export let saveFormDate
  export let alert
  export let clearSelect

  // import QuestionEditor from "../AssignmentBuilder/QuestionEditor.svelte";

  // Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Storage and get a reference to the service
 const storage = getStorage(app);

     var percent =''
     var fileLink=[]
    let validate = null
    let message ='' 
    let data;
      let file=''
    let mq = {
    

     question:'',

     options:[],

     currect:'',

     position:'',
     hints:''

    }

let option=''
let d=[]
let  correct = {
    key:'',
    no: ''
  }

let anse=[{key:'a', no:1},{key:'b', no:2}, {key:'c', no:3} , {key:'d', no:4}]

function getUrl(b){
  setTimeout(() => {
  fileLink.push(b)
    console.log(fileLink)
  }, 1000);

  
}

function addOption(){
  

  if(mq.options.length <= 4){
    let b=anse[0].key

    if(option){
      mq.options.push({
        group:1,
        data:option,
        key:anse[0].key,
        no:anse[0].no
      })

      console.log(mq.options)
      d= mq.options.sort((a,b)=>{
        return a.no -b.no
      })

      mq.options=d

      option =''
      anse = anse.filter(f=> f.key != b)
  }
  }

  
}

function deleteOption(e){
    console.log(e)
  let f=   mq.options.filter(f=> f.key != e.key)

  anse.push({key:e.key, no:e.no})

setTimeout(() => {
  anse = anse.sort((a,b)=>{
    return a.no - b.no
  })

  mq.options = f
  d = f

  if(correct.no == e.key){
    correct = {
    key:'',
    no: ''
  }

  mq.currect=''
  }

 

  console.log(anse)
}, 1000);
 

}

async function validateResult(){
 
   let type='multiple-choice'
  if(mq.question && mq.options.length > 0  && mq.currect){
    data={
      answers: mq.options,
      claim:'1',
      correctAnswers:[mq.currect.toUpperCase()],
      dok:'1',
      hints:[{hint:mq.hints}],
      question:mq.question,
      type:type,
      video:fileLink[0]
    }

    console.log(data)
    

  }



}


function keyEnter (e){
// console.log(e)
if(e.key == 'Enter'){
  addOption()
}
}

async function chooseCorrectList(){
console.log(mq.currect)
if(mq.currect){
  let a= d.filter(f=> f.key == mq.currect)
  if(a){
    correct = {
    key: a[0].data,
    no: a[0].key
  }
  }
}else{
    correct = {
    key:'',
    no: ''
  }
  }


  
 
 
}


 let colors = ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466']



    async function saveChange(){
    if(data && mq.question && mq.options.length >0 && mq.currect){
      await saveFormDate(data, data.type )
    }
  }


  async function refresh(){
    mq = {
    

    question:'',

    options:[],

    currect:'',

    position:'',
    hints:''

   }

   data=null
   anse=[{key:'a', no:1},{key:'b', no:2}, {key:'c', no:3} , {key:'d', no:4}]
   correct = {
    key:'',
    no: ''
  }

 option=''
 d=[]

 message ='' 

 setTimeout(async () => {
   await clearSelect()
 }, 1000);

  }
</script>




<section>
  
  {#if validate === true }
<!-- content here -->
<div class="alert alert-sucess" role="alert">
  {message}
</div>
{:else if validate === false }
<!-- else content here -->
<div class="alert alert-danger" role="alert">
  {message}
</div>
{/if}

<h3>Create Multiple Choice Question</h3>

     <!-- <div class="form-group">
        <label for="exampleFormControlTextarea1">Write question?</label>
       <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" bind:value={mq.question}></textarea> 
         <Editor  on:change={(evt)=>{
          mq.question = evt.detail
          
          console.log(mq.question)
          }}/> 
      </div> -->

     <section>
        <h6>
          Write question?
        </h6>

        <Editor   {colors} on:change={(evt)=>{
          mq.question = evt.detail
          
          // console.log(mq.question)
          }}/>
           <!-- <QuestionEditor></QuestionEditor>  -->
          
      </section> 

     <div class="row" style="width:40% ;">
         <div class="col">
            <div class="form-group">
                <label for="formGroupExampleInput">Add options</label>
                <input type="text" class="form-control" id="formGroupExampleInput" placeholder="Example input" bind:value={option}
                on:keypress={keyEnter}
                >
              </div>
         </div>
         <div class="col">
             <button class="btn btn-primary" style="margin:30px ;" on:click={addOption}>ADD</button>
         </div>
     </div>



   
    
        <h4><strong>Options :- </strong></h4>
        <ul>
            {#each d as item}
                  <!-- content here  -->
                  {#if item.key == 'a'}
                     <!-- content here -->
                     <li>
                     ({item.key}) {item.data} <span style="cursor:pointer ;" on:click={deleteOption(item)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                         <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                       </svg></span>
                  </li>
                  {/if}

                  {#if item.key == 'b'}
                  <!-- content here -->
                  <li>
                    ({item.key}) {item.data} <span style="cursor:pointer ;" on:click={deleteOption(item)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                    </svg></span>
               </li>
               {/if}

               {#if item.key == 'c'}
               <!-- content here -->
               <li>
                ({item.key}) {item.data} <span style="cursor:pointer ;" on:click={deleteOption(item)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                   <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                 </svg></span>
            </li>
            {/if}

            {#if item.key == 'd'}
            <!-- content here -->
            <li>
              ({item.key}) {item.data} <span style="cursor:pointer ;" on:click={deleteOption(item)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg></span>
         </li>
         {/if}
                
            {/each}
        </ul>



        <div class="form-group">
          <label for="hints">Write hints</label>
          <!-- <textarea class="form-control" id="hints" rows="3" bind:value={mq.hints}></textarea> -->
          <Editor   {colors} on:change={(evt)=>{
            mq.hints = evt.detail
           // console.log(mq.hints)
            }}/>
        </div>
        <div class="row" style="width:70% ;">
          <div class="col">
            <div class="form-group">
              <label for="exampleFormControlFile1">Video file upload</label>
              <input type="file" class="form-control-file" id="exampleFormControlFile1"  accept=".mp4" on:change={(e)=>{
                file = e.target.files[0]
          
              }}>
            
    
            </div>
          </div>
          <div class="col">
            <button on:click={async ()=>{
                  if(file){
                    fileLink=[]
                      // Upload file and metadata to the object 'images/mountains.jpg'
const storageRef = ref(storage, 'videos/' + file.name);
const uploadTask = uploadBytesResumable(storageRef, file);
 


// Listen for state changes, errors, and completion of the upload.
uploadTask.on('state_changed',
  (snapshot) => {
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
     percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    // console.log('Upload is ' + progress + '% done');

    

    switch (snapshot.state) {
      case 'paused':
        console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
      
        break;
    }

 
    
  }, 
  (error) => {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {
      case 'storage/unauthorized':
        // User doesn't have permission to access the object
        break;
      case 'storage/canceled':
        // User canceled the upload
        break;

      // ...

      case 'storage/unknown':
        // Unknown error occurred, inspect error.serverResponse
        break;
    }
  }, 
  () => {
    // Upload completed successfully, now we can get the download URL
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      console.log('File available at', downloadURL);
    
      if(downloadURL){
        
        getUrl(downloadURL)
      
      }

    
    
   
// /* set up async GET request */
// var req = new XMLHttpRequest();
// req.open("GET", downloadURL, true);
// req.responseType = "arraybuffer";

// req.onload = function(e) {
//   var workbook = XLSX.read(req.response);

//   /* DO SOMETHING WITH workbook HERE */
//   console.log(workbook)
// };

// req.send();

    });
  }
);
                }
            }}>Save Video</button>
          </div>
          </div>

          {#if percent}
          <div class="row" style="width:70% ;">
            <div class="col">
              Uploading ..... ({percent} %)
            </div>

            {#if fileLink.length >0}
          <div class="col">
            <a href={fileLink[0]} target="_blank">
           Video Link
            </a>
          </div>
         {/if}
          </div> 
         
          {/if}
          
        
       
 

     <div class="row" style="width:70% ;">
         <div class="col">
            <div class="form-group">
                <label for="correct">Correct option</label>
                <select class="form-control" id="correct" bind:value={mq.currect} on:change={chooseCorrectList}>
                    <option value="" selected>Select One</option>
                    {#each d as item}
                         <!-- content here -->
                         <option value={item.key} >{item.data}</option>

                    {/each}
                  </select>
            </div>
{#if correct.key}
   <!-- content here -->
   <p class="text-success" >
    Correct answer: ({correct.no}) {correct.key}
  </p>
{/if}
           
         </div>

         <div class="col">
            <button type="button" class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg" style="margin:30px ; float:right" on:click={validateResult} > Save & Preview</button>
         </div>
     </div>


</section>




<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Preview Of Question</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {#if alert}
           <!-- content here -->
           {#if alert.type=='success'}
              <!-- content here -->
              <div class="alert alert-success alert-dismissible fade show" role="alert">
                <strong>
                  {alert.msg}
                </strong> 
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
           {:else if alert.type=='danger'}
              <!-- else content here -->
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>
                  {alert.msg}
                </strong> 
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
           {/if}
        {/if}
       {#if data}
          <!-- content here -->
          <section style="font-size:larger; font-weight:bold">
          (Q)  {@html data.question} 
          </section>

          <section>
            <p>
              <strong>
                Options:-
              </strong>
            </p>

            {#if data.answers}
               <!-- content here -->
               <div class="row">
                <div class="col">
                  <ul>
                    {#each data.answers as item}
                       <!-- content here -->
                       {#if item.key=='a'}
                          <!-- content here -->
                          <li>
                            ({item.key}) {item.data}
                          </li>
                       {/if}
                       {#if item.key=='b'}
                       <!-- content here -->
                       <li>
                         ({item.key}) {item.data}
                       </li>
                    {/if}
                    {/each}
                 
                
                  </ul>
                </div>
                <div class="col">
                  <ul>
                    {#each data.answers as item}
                       <!-- content here -->
                       {#if item.key=='c'}
                          <!-- content here -->
                          <li>
                            ({item.key}) {item.data}
                          </li>
                       {/if}
                       {#if item.key=='d'}
                       <!-- content here -->
                       <li>
                         ({item.key}) {item.data}
                       </li>
                    {/if}
                    {/each}
                 
                
                  </ul>
                </div>
                 
  
              
              </div>
  
            {/if}
          
          </section>

          <section>
            <h5>
              <strong class="text-success">
                 Correct Answer:- ({correct.no}) {correct.key}
              </strong>
            </h5>
          </section>

          <section>
            <p><strong>Hints:-</strong></p>
            <section>
              {@html data.hints[0].hint}
            </section>
          </section>

          <section>
            <p><strong>Video:-  <a href={fileLink[0]} target="_blank">
              Video Link
              </a></strong></p>
            
          </section>

       {:else}
          <!-- else content here -->
          <p class="text-danger">Question is not set for some problem</p>
       {/if}
      </div>
      {#if alert}
         <!-- content here -->
         {#if alert.type !='success'}
         <!-- content here -->
         <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close & Edit</button>
          <button type="button" class="btn btn-primary" on:click={saveChange}>Save changes</button>
        </div>
      {/if}

      {#if alert.type =='success'}
         <!-- content here -->
         <button type="button" on:click={refresh} class="btn btn-secondary" data-dismiss="modal">Close</button>
      {/if}

      {:else}
         <!-- else content here -->
         <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close & Edit</button>
          <button type="button" class="btn btn-primary" on:click={saveChange}>Save changes</button>
        </div>
      {/if}
     
    
    </div>
  </div>
</div>