<script>
	import { onMount } from 'svelte';
 

  

  export let location;
  location = location;
  export let class_id;
  let classes=[]
  let students=[]
  let selectClass=[]
  let stu;
  let data ={
    classId:'',
    student_id:''
  }
let alert;
let alertClass;
  let classList =[]

  import TeacherSideBar from "./TeacherSideBar.svelte";
  import {get_class_students, get_all_classes, get_one_course, all_students, add_course, addClasses, deleteClass_student, new_student, new_student_edit} from '../db/general'

  let firstName, lastName;
  let email;

  onMount( async ()=>{
    classes = await get_all_classes()
    students = await all_students()

    let s = students.sort((a,b)=>{
      return b.slNo - a.slNo
    })

   // console.log(s)

    students=s

  })

  function select_all() {
    for (const student of students) {
      student.selected = true;
    }
    students = students;
  }
  function select_none() {
    for (const student of students) {
      student.selected = false;
    }
    students = students;
  }
  function remove() {
    if (!confirm("Are you sure?")) return;
    students = students.filter(student => !student.selected);
  }
  function add_student() {
    // jQuery("#add-student-modal").modal("hide");
    console.log("@add_student", new_student_name, new_student_email);
  }


  async function studentAddClass(d){
    stu =null
    stu = d
    data.classId=''
    alert=null
    alertClass=null
  }

  async function addC(s1){
    alertClass=null
     console.log(s1)
     if(data.classId){
      stu = await addClasses(stu, data)
    if(stu){
      classes = await get_all_classes()
      students = await all_students()
      let s = students.sort((a,b)=>{
        return b.slNo - a.slNo
    })

   
    data.classId=''

   // console.log(s)

    students=s

      let a = students.filter(f=> f.id == s1.id)

       stu =a[0]

       if (s1.classes) {
        if(stu.classes.length != s1.classes.length){
        alertClass=true
       }
       }else{
        alertClass=true
       }
     
    }
    console.log(stu)
     }

  }

 async function delete_class(s2,cls){
   alertClass=null
  if(s2 && cls){
    let sl = await deleteClass_student(s2,cls)

    if(sl){
     
     setTimeout(async() => {
      classes = await get_all_classes()
      students = await all_students()
    

   // console.log(s)
   let s = students.sort((a,b)=>{
      return b.slNo - a.slNo
    })

   // console.log(s)

    students=s

      
      let a = students.filter(f=> f.id == sl.id)

       stu = a[0]
     }, 1000);
    }
  }

  }

 async function addStudent(){
     students = await all_students()


    if(firstName && lastName && email){
    
      let h = students.filter(f=> f.email == email)

      if(h.length ===0){
        let student ={
        firstName:firstName,
        lastName:lastName,
        email:email,
        UUID:'N/A',
        create: new Date(),
        slNo: students.length*1 + 1
      }

      let n = await new_student(student)

      if(n){
        alert=true
       classes = await get_all_classes()
       students = await all_students()
      
       let s = students.sort((a,b)=>{
        return b.slNo - a.slNo
    })

   // console.log(s)
    firstName='',
        lastName='',
        email='',

    students=s

      }else{
        alert=false
      }
      }else{
        alert =false
      }
     

    }
  }

  async function editst(d){
    firstName= d.firstName;
      lastName= d.lastName;
      email=d.email
      alert=null
      stu=d
  
  }


  async function editstudent(d){

    if(firstName && lastName && email){
      students = await all_students()
     

     let student ={
       firstName:firstName,
       lastName:lastName,
       email:email,
       UUID:d.UUID,
       create: new Date(),
       slNo: d.slNo

     }


     let a = await new_student_edit(d, student) 

     if(a){
       alert=true
      classes = await get_all_classes()
      students = await all_students()
      let s = students.sort((a,b)=>{
       return b.slNo - a.slNo
   })

  // console.log(s)
    firstName='',
       lastName='',
       email='',

   students=s

    
  

     }


    }

  }


async function clearDataForm(){
     firstName= '';
      lastName= '';
      email=''
      alert=null
}



</script>


<TeacherSideBar {class_id}>

  <section style="padding:20px ;">
    <div>
      <h4 style="float:left ;">
        <strong>
          Students Details Page:-
        </strong>
      </h4>

      <button class="btn btn-primary" style="float: right;" data-toggle="modal" data-target="#exampleModal1" on:click={clearDataForm}>Add Student</button>
    </div>
   
    <br>

  <section style="margin:5px">
    <div class="table-responsive-md">
      <table class="table table-hoverable">
       <thead>
         <tr>
           <th>
             #
           </th>
           <th>
             Name
           </th>
           <th>
             Email
           </th>
           <th>
            Classes
           </th>
           <th>
             Action
           </th>
          
         </tr>
       </thead>

       <tbody>
        
         {#each students as item,i}
            <!-- content here -->
            <tr>
              <td>
               {i+1}
              </td>
              <td>
                {item.firstName}  {item.lastName} 
              </td>
              <td>
                {item.email}
              </td>
              <td>
                {#if item.classes}
                   <!-- content here -->
                   <ol>
                    {#each item.classes as c}
                    <!-- content here -->
                    <li>
                      {c.class}
                    </li>
                 {/each}
                   </ol>
                   

                {:else}
                   <!-- else content here -->
                   No classes
                {/if}
              
              </td>
              <td>
                <button class='btn btn-primary' data-toggle="modal" data-target="#exampleModal" on:click={studentAddClass(item)}>Add Class</button>

              </td>
              <td>
                <button class="btn btn-warning" data-toggle="modal" data-target="#exampleModal2" on:click={editst(item)}>Edit</button>
              </td>
            </tr>
         {/each}
       </tbody>
      </table>
    </div>
  </section>


  </section>

 




  <!-- {#await get_class_students(class_id)}
    <div class="spinner-border text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  {:then assignment}

  <h2>
    Students
    <a href="/">#CAFEBABE</a>
  </h2>

  <table>
    <tr>
      <th>Name</th>
      <th>&nbsp;&nbsp;&nbsp;</th>
      <th>Email</th>
      <th>&nbsp;&nbsp;&nbsp;</th>
      <th style="text-align: center">
        <button class="btn btn-link" on:click={select_all}>all</button>
        <button class="btn btn-link" on:click={select_none}>none</button>
      </th>
    </tr>
    {#each students as student (student.email)}
      <tr>
        <td>{student.name}</td>
        <td />
        <td>{student.email}</td>
        <td />
        <td style="text-align: center">
          <input type="checkbox" bind:checked={student.selected} />
        </td>
      </tr>
    {/each}
    <br />
    <tr>
      <td>
        <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#add-student-modal">
          Add Student
        </button>

      </td>
      <td />
      <td>
        <button class="btn btn-danger" on:click={remove}>
          Remove Students
        </button>
      </td>
    </tr>
  </table>
  {/await} -->

</TeacherSideBar>

<div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addClassLabel">Add Student</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {#if alert == true}
      <!-- content here -->
      <div class="alert alert-success " role="alert">
       Student added succefully !
       
     </div>
   {:else if alert == false}
      <!-- else content here -->
      <div class="alert alert-danger " role="alert">
       Student not added succefully ! because email is already registered.
     
     </div>
   {/if}
      <div >
        <div class="modal-body">
          <div class="form-group">
            <label for="class-name">First Name</label>
            <input
              class="form-control"
              id="class-name"
              required
              bind:value={firstName} />
          </div>
          <div class="form-group">
            <label for="class-name1">Last Name</label>
            <input
              class="form-control"
              id="class-name1"
              required
              bind:value={lastName} />
          </div>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="class-name">Email</label>
            <input
              class="form-control"
              id="class-name"
              type="email"
              required
              bind:value={email} />
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" on:click={addStudent}>Add</button>
          <button  class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close" >Close</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addClassLabel">Edit Student</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {#if alert == true}
         <!-- content here -->
         <div class="alert alert-success " role="alert">
          Student added succefully !
   
        </div>
      {:else if alert == false}
         <!-- else content here -->
         <div class="alert alert-danger " role="alert">
          Student not added succefully ! because email is already registered.
         
        </div>
      {/if}
      <div >
        <div class="modal-body">
          <div class="form-group">
            <label for="class-name5">First Name</label>
            <input
              class="form-control"
              id="class-name5"
              required
              bind:value={firstName} />
          </div>
          <div class="form-group">
            <label for="class-name12">Last Name</label>
            <input
              class="form-control"
              id="class-name12"
              required
              bind:value={lastName} />
          </div>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="class-name3">Email</label>
            <input
              class="form-control"
              id="class-name3"
              type="email"
              required
              bind:value={email} />
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" on:click={editstudent(stu)}>Edit</button>
          <button  class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close" >Close</button>

        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">ADD CLASSES</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {#if stu}
           <!-- content here -->
           <div class="row">
            <div class="col">
              Name: {stu.firstName}  {stu.lastName} 
            </div>
            <div class="col">
             Email: {stu.email} 
           </div>
          </div>

          <hr>

          <section>
            {#if alertClass== true}
               <!-- content here -->
               <div class="alert alert-success alert-dismissible fade show" role="alert">
                Class added succesfully !
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            {/if}
            <div class="form-inline">
              
              <div class="form-group mx-sm-3 mb-2">
                <label for="inputPassword2" class="sr-only">Select Classes</label>
                <select class="custom-select" id="inputPassword2" bind:value={data.classId}>
                  <option value='' selected>Select Classes</option>
                  {#each classes as item}
                     <!-- content here -->
                     <option value={item.id}>{item.name}</option>
                  {/each}
                </select>

              </div>
              <button type="submit" class="btn btn-primary mb-2" on:click={addC(stu)}>Add</button>
            </div>
          </section>

          <section>
            {#if stu.classes}
               <!-- content here -->
               <div class="table-responsive">
                <table class="table">
                   <thead>
                     <tr>
                       <th>
                         #
                       </th>
                       <th>
                         Class
                       </th>
                       <th>
                         Action
                       </th>
                     </tr>
                   </thead>

                   <tbody>
                     {#each stu.classes as item,i}
                        <!-- content here -->
                        <tr>
                            <td>{i+1}</td>
                            <td>
                              {item.class}
                            </td>
                            <td>
                              <button class='btn btn-danger' on:click={delete_class(stu, item.classID)}>Delete</button>
                            </td>
                        </tr>
                     {/each}
                   </tbody>
                </table>
              </div>
            {/if}
          </section>

        {/if}
      
    


      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>
