<script>
  export let close_callback;
  export let show_video;
  import Popup from "./Popup.svelte";
</script>

<Popup {close_callback} show_it={show_video}>
  <div slot="header">VIDEO</div>
  <div> 
    <video width="800" controls>
        <source src="https://firebasestorage.googleapis.com/v0/b/smartermath-beta.appspot.com/o/Video%2FEx%201%20%20Determine%20the%20Domain%20and%20Range%20of%20the%20Graph%20of%20a%20Function.mp4?alt=media&token=b83c47dd-059c-4e79-9ff7-27e72ae36ce0" type="video/mp4">
        <!-- Your browser does not support video from Smarter-Math.com -->
    </video>   
  </div>
</Popup>