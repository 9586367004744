<script>
    export let title;
    export let writeUp;
</script>
<style>
    @font-face {
        font-family: gothamBlack;
        src: url(assets/fonts/Gotham-Black.otf);
    }

    @font-face {
        font-family: openSansBold;
        src: url(assets/fonts/OpenSans-Bold.ttf); 
    }

    @font-face {
        font-family: openSans;
        src: url(assets/fonts/OpenSans-Regular.ttf); 
    }

    .subTitle {
        color: #0084ff;
        font-family: openSansBold,"Times New Roman";
        font-weight: bold;
        font-size: 14pt;
    }
    .title {
        color: #10c31c;
        font-family: gothamBlack,"Times New Roman";
        font-weight: bold;
        font-size: 18pt;  
        text-transform: uppercase;
    }
    .writeUp {
        color: black;
        font-family: openSans,"Times New Roman";
        font-size: 12pt;
    }
</style>

<div style="padding-top:15px; padding-bottom:10px;">
    <div class="subTitle">Teacher Dashboard</div>
    <div class="title">{title}</div>
    <div class="writeUp">{writeUp}</div>
</div>