<script>
    export let location;
    location = location;

    import TeacherSideBar from "./TeacherSideBar.svelte";
    import TeacherDashboardHeader from "./TeacherDashboardHeader.svelte";
    import {get_grades, get_standards} from "../db/standards";
    import { onMount } from "svelte";
    
    let stateStandards_cache = {};
    let gradeSelected;

    async function getStandards() {
        stateStandards_cache = await get_standards();
        return stateStandards_cache;
    }

    function gradeChanged() {
        alert(gradeSelected);
    }

    // Setup Text Editor
    onMount(async ()=> {
        CKEDITOR.replace('standardsEditor');
    });

</script>

<TeacherSideBar location={location} activeOption={'SYSTEMADMIN_STANDARDS'}>
    <TeacherDashboardHeader title="Standards Management" 
    writeUp="Welcome to the Standards Management system.  This feature is only available to System Administrators and is used to manage the Standards Database."
    ></TeacherDashboardHeader>

    <h4>New Standard</h4>
    const grades = 
    
    <form>
        {#await getStandards()}
        <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        {:then standards}
         <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">Grade</span>
            </div>
            <!-- svelte-ignore a11y-no-onchange -->
            <select class="form-control" id="grade" aria-label="domain" bind:value={gradeSelected} on:change={gradeChanged()}>
                {#each standards.map(gradeList => {return {id: gradeList.id, grade: gradeList.id}}) as grd}
                    <option value={grd}>{grd.grade}</option>
                {/each}
            </select>
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" id="button-addon2"><i class="fas fa-plus"></i></button>
            </div>

            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Domain</span>
                </div>
                <select class="form-control" id="domain" aria-label="domain">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" id="button-addon2"><i class="fas fa-plus"></i></button>
                </div>
            </div>

        </div>
        {:catch error}
        <div class="alert alert-danger">{error.message}</div>
        {/await}

        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">Standard</span>
            </div>
            <input type="text" class="form-control" aria-label="Standard Code">
        </div>

        <textarea name="standardsEditor" id="standardsEditor">&lt;p&gt;Initial editor content.&lt;/p&gt;</textarea>

        <button type="submit" class="btn btn-primary">Submit</button>
        


    </form>

</TeacherSideBar>