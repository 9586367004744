<script >
	import { onMount } from 'svelte';
  import { link, navigate } from "svelte-routing";

  export let location;
  location = location;
  export let class_id;
  let alert
  let alertCourse
  let allClass;
  let all_course=[];
  let edit = false
  let editCC = false
  let editC;
  let ediTC;
  let stu;


  import TeacherSideBar from "./TeacherSideBar.svelte";
  import { list_teacher_classes, new_class , list_student_courses, get_all_course, get_all_classes , add_course, put_class, put_course, one_students} from "../db/general";
  
  



  let code = Math.floor(Math.random()*new Date())

  let data1={
  

    course_id:  '',

   courseName:'',
   name:'',
   

  }
  
  onMount(async () => {
	  all_classes()

     all_course  = await get_all_course()
	});

  async function all_classes(){
     let c = await get_all_classes()

    let d=  c.sort((a , b)=>{
        return b.slNo - a.slNo 
     })

     allClass= d
   }




  async function submit(){
    let s = await get_all_course()
    let c = await get_all_classes()

    console.log(s)

    if(s.length >0 && data1.course_id && data1.name ){
      let d= s.filter(f=> f.id == data1.course_id)

      if(d.length >0 ){

         let data2 = {
           course_id:data1.course_id,
           courseName:d[0].name,
           name:data1.name,
           create: new Date(),
           slNo: c.length*1+1
         }
      
      let n =   await new_class(data2)

      if(n){
        alert ='done'
    
        all_classes()

        data1={
  

     course_id:  '',

    courseName:'',
       name:'',
 

       }

      }

      // return await all_classes()
      }
    }

  }

  function disAlert(){
    alert=null
  }
  function disCAlert(){
    alertCourse=null
  }

 let course={
   name:'',
   create:new Date()
 }
 async function addCourse(){
   if(course.name){
    let s = await add_course(course)

if(s){
  alertCourse ='done'

  all_course  = await get_all_course()
  setTimeout(() => {
    course.name=null
  }, 1000);
     
}
   }
  
  }
  

 async function onEdit(data){
  edit = true
   data1.name = data.name
   data1.course_id=data.course_id
   ediTC=data
 
  }

  async function updateClass(data){
    if(edit){
    
      let s = await get_all_course()

console.log(s)

if(s.length >0 && data1.course_id && data1.name ){
  let d= s.filter(f=> f.id == data1.course_id)
  //  console.log(d)

  if(d.length >0 ){

     let data2 = {
       course_id:data1.course_id,
       courseName:d[0].name,
       name:data1.name,
       create: new Date(),
       slNo:data.slNo
     }
  
  let n =    await put_class(data.id, data2)
// console.log(n)
  if(n){
    alert ='done'
    edit=false
    ediTC=null

    all_classes()
    data1={
  

    course_id:  '',

    courseName:'',
     name:'',
 

     }

    
  }

  // return await all_classes()
  }
}
      // let s = await put_class(data.id, )
    }
  }


 async function editCourse(data){
  editCC = true
   editC=data
   course.name= data.name
  }

  async function putCourse(data){
   if(course.name){
    let s = await put_course(data.id,course)

if(s){
  alertCourse ='done'
  editCC = false
   editC=null
  all_course  = await get_all_course()
  course.name=''
     
}
   }
  
  }
  

  async function viewStudents(d){
    stu=null
    if(d){
     
    stu = d
    }
   
  }

</script>

<TeacherSideBar {class_id}>

  <div class="cointainer" style="padding:20px ;">
    <div class="row">
      <h4>
        <strong>
          Create Class Here:-
        </strong>
      </h4>
 </div>

   {#if alert}
      <!-- content here -->
      <div class="alert alert-success" role="alert" style="width:60% ;">
        Class added succesfully. <span style="float: right; courser:pointer" on:click={disAlert}>Close</span>
      </div>
   {/if}

 <div class="row">
  <div class="form-inline">
   
    <div class="form-group mx-sm-3 mb-2">
      <label for="inputPassword2" class="sr-only">Enter Class Name</label>
      <input bind:value={data1.name} type="text" class="form-control" id="inputPassword2" placeholder="Enter Class Name">
    </div>
    <div class="form-group mx-sm-3 mb-2">
    {#await get_all_course()}
      <!-- get_all_course() is pending -->
      <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>

    {:then value}
    <!-- <pre>{JSON.stringify(value, null, 2)}</pre> -->

      <!-- get_all_course() was fulfilled -->
      <label for="exampleFormControlSelect1" class="sr-only">Example select</label>
    <select class="form-control" id="exampleFormControlSelect1" bind:value={data1.course_id}>
      <option value="" selected>Choose Course</option>
       {#each all_course as item}
          <!-- content here -->
          <option value={item.id}>{item.name}</option>
       {/each}
    </select>
    {:catch error}
      <!-- get_all_course() was rejected -->
    {/await}
    </div>
    {#if edit}
       <!-- content here -->
    <button  class="btn btn-primary mb-2" on:click={updateClass(ediTC)} style="margin: 5px;">Edit</button>

    {:else}
       <!-- else content here -->
    <button  class="btn btn-primary mb-2" on:click={submit} style="margin: 5px;">Submit</button>

    {/if}
    <button  class="btn btn-primary mb-2" style="margin: 5px;" data-toggle="modal" data-target="#exampleModalCenter" >Add Course</button>

  </div>

  
 </div>
  </div>

  <div class="row" style="padding:20px ;">
    <section style="width: 100%;">
      {#await all_classes()}
        <!-- all_classes() is pending -->
        <div class="spinner-border text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      {:then value}
        <!-- all_classes() was fulfilled -->
        
        <h4>
          <strong>List Of All Classes</strong>
        </h4>
        <div class="table-responsive">
          <table class="table" data-pagination="true">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Class Name</th>
          <th scope="col">Course Name</th>
          <th scope="col">Student No.</th>

          <th scope="col">Action</th>


        </tr>
      </thead>
      <tbody>
       
          {#each allClass as item, i}
          <tr>
             <!-- content here -->
             <th scope="row"> {i+1}
            </th>
             <td>{item.name}</td>
             <td>{item.courseName}</td>
             <td>
               {#if item.students}
                  <!-- content here -->
                  {item.students.length}
                  {:else}
                     <!-- else content here -->
                     Not added
               {/if}
              
             </td>
          

             <td>
               <button style="margin: 2px;" class="btn btn-primary" on:click={onEdit(item)} >Edit</button>
               <button style="margin: 2px;" class="btn btn-success"  data-toggle="modal" data-target="#exampleModal2" on:click={viewStudents(item)}> View Student</button>
               <a style="margin: 2px;" class="btn btn-warning" href="/class/progress/{item.id}" use:link  >View Progress</a>
             </td>
             </tr>
          {/each}
        
         
        </tbody>
    
          </table>
        </div>
    
    
      {:catch error}
        <!-- all_classes() was rejected -->
      {/await}
    </section>
  </div>


</TeacherSideBar>

<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Add Course</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {#if alertCourse}
      <!-- content here -->
      <div class="alert alert-success" role="alert" >
        Course added succesfully. <span style="float: right; courser:pointer" on:click={disCAlert}>Close</span>
      </div>
   {/if}
        <div class="form-inline">
         
          <div class="form-group mx-sm-3 mb-2">
            <label for="s" class="sr-only">Enter Course Name</label>
            <input bind:value={course.name} type="text" class="form-control" id="s" placeholder="Enter Course Name">
          </div>
          {#if editCC}
             <!-- content here -->
          <button  class="btn btn-primary mb-2" on:click={putCourse(editC)}>Edit</button>

          {:else}
             <!-- else content here -->
          <button  class="btn btn-primary mb-2" on:click={addCourse}>Add</button>

          {/if}
        </div>
      </div>

      <div class="row" style="padding:20px ;">
        <section style="width: 100%;">
          {#await get_all_course()}
            <!-- all_classes() is pending -->
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          {:then value}
            <!-- all_classes() was fulfilled -->
            
            <h4>
              <strong>List Of All Courses</strong>
            </h4>
            <div class="table-responsive">
              <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Cousre Name</th>
              <th scope="col">Acion</th>

              
            </tr>
          </thead>
          <tbody>
           
              {#each all_course as item, i}
              <tr>
                 <!-- content here -->
                 <th scope="row">{i+1}</th>
                 <td>{item.name}</td>
                 <td>
                   <button class="btn btn-primary" on:click={editCourse(item)}>Edit</button>
                </td>
                
                 </tr>
              {/each}
            
             
            </tbody>
        
              </table>
            </div>
        
        
          {:catch error}
            <!-- all_classes() was rejected -->
          {/await}
        </section>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        
      </div>
    </div>
  </div>
</div>




<!-- Modal -->
<div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Students assigned for classes:-</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {#if stu}
           <!-- content here -->
           <div class="table-responsive-sm">
            <table class="table table-hoverable">
              <thead>
                <tr>
                  <th>
                    #
                  </th>
                  <th>
                    Name
                  </th>
                  <th>
                    Email
                  </th>
                </tr>
              </thead>
              <tbody>
                {#if stu.students}
                   <!-- content here -->
                   {#each stu.students as item,i}
                   <!-- content here -->
                  
                     {#await one_students(item)}
                       <!-- promise is pending -->
                       <div class="spinner-border text-warning" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                     {:then value}
                       <!-- promise was fulfilled -->
                      <tr>
                        <td>
                          {i+1}
                        </td>
                        <td>
                          {value.firstName} {value.lastName} 
                        </td>
                        <td>
                          {value.email}

                        </td>
                      </tr>
                     {:catch error}
                       <!-- promise was rejected -->
                     {/await}
                
                {/each}
                {/if}
              
              </tbody>
            </table>
          </div>
        {/if}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>