<script>
  export let question;
  export let on_update;
  let answer = [];
  function on_change(event) {
    console.log('fill-small', event.target.value)
    // answer[event.target.name] = event.target.value;
    answer = [event.target.value];

    on_update(question, answer[0]);
  }
</script>

<style>
  input {
    margin: 0.5em 1em;
  }
</style>

{@html question.question}
<br />

<!-- {#each 'abcdef' as letter} -->
  <!-- {#if question[letter]} -->
    <div>
      <label class="p-row inline-p">
        <!-- {@html question[letter]}
        &nbsp; -->
        <input
          type="text"
          name="letter"
          placeholder="Write your answer"
          on:change={on_change} 
          
          />
      </label>
    </div>
  <!-- {/if} -->
<!-- {/each} -->
