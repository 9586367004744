
<script>
  import Editor from "cl-editor/src/Editor.svelte"

	import MultiSelect from 'svelte-multiselect'
  import { initializeApp } from "firebase/app";

import { getStorage , ref, uploadBytesResumable, getDownloadURL} from "firebase/storage";

import { firebaseConfig } from "../../storage";

  export let saveFormDate;
export let alert;
export let d_id;
export let d_n;
export let clearSelect;

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);


// Initialize Cloud Storage and get a reference to the service
 const storage = getStorage(app);

     var percent =''
     var fileLink=[]
     var file=''
    let serise = [
        {key: 'a', no:1},
        {key: 'b', no:2},
        {key: 'c', no:3},
        {key: 'd', no:4},
        {key: 'e', no:5},
        {key: 'f', no:6},
        {key: 'g', no:7},
        {key: 'h', no:8},
        {key: 'i', no:9},
        {key: 'j', no:10},
    ]
    let an =''
    let d=[]
    let TF = {
       question:'',
       options:[],
       correct:[],
       hints:''
    }

    let data;

    let option=''


    function getUrl(b){
  setTimeout(() => {
  fileLink.push(b)
    console.log(fileLink)
  }, 1000);

  
}

    function AddOption(){
        if(option && an && TF.options.length <= 10 ){

            let s =serise[0]


            TF.options.push({
                key: s.key,
                no:s.no,
                data: option,
                ans: an
            })

            
           setTimeout(() => {

            let v = serise.filter(f=> f.no != s.no)

            serise = v.sort((a,b)=>{
                return a.no - b.no

            })

            d = TF.options.sort((a , b)=>{
                return a.no - b.no
            })

            TF.options = d



            option=''
            an=''
           }, 1000);

        }
    }

        function deleteOp(e){
          if(e){
              let s= TF.options.filter(f=> f.no != e.no)

              TF.options=s.sort((a,b)=>{
                return a.no - b.no
              }) 
              d=s.sort((a,b)=>{
                return a.no - b.no
              }) 

              serise.push({
                key: e.key,
                no:e.no,
              })

              setTimeout(() => {
                serise = serise.sort((a,b)=>{
                return a.no - b.no

                }) 
              }, 500);
              
          }
        }
       let selectedOptions =[]


       async function onSubmit(){

        if(TF.question && TF.options.length >0){
           
            let answers = TF.options


    let correctAnswers =[]

  for (let index = 0; index < answers.length; index++) {
    const element = answers[index];

    correctAnswers.push(
        element.key.toUpperCase() +':' + element.ans
    )
    
}


data={
 answers:answers,
 claim:1,
 correctAnswers:correctAnswers,
 question: TF.question,
 type:'true-false',
 hints:[{hint:TF.hints}],
 video:fileLink[0],
 dok:'1'
}

console.log(data)

        }

    
       }
     
       async function submitData(){
        if(data){
           await saveFormDate(data, 'true-false')
       }
    }

    async function refresh(){



 setTimeout(async () => {

   serise = [
        {key: 'a', no:1},
        {key: 'b', no:2},
        {key: 'c', no:3},
        {key: 'd', no:4},
        {key: 'e', no:5},
        {key: 'f', no:6},
        {key: 'g', no:7},
        {key: 'h', no:8},
        {key: 'i', no:9},
        {key: 'j', no:10},
    ]
     an =''
     d=[]
     TF = {
       question:'',
       options:[],
       correct:[],
       hints:''
    }

     data;

     option=''

   await clearSelect()
 }, 1000);
    }

</script>

<section>
<h3>Create Your True False Question:-</h3>

 <div class="form-group">
    <label for="exampleFormControlTextarea1">Write your question ?</label>
    <!-- <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" bind:value={TF.question}></textarea> -->
    <Editor    on:change={(evt)=>{
      TF.question = evt.detail
      
      // console.log(mq.question)
      }}/>
  </div>

  <div class="row" style="width: 60%;">
      <div class="col">
        <div class="form-group">
            <label for="formGroupExampleInput">Write option:-</label>
            <textarea bind:value={option} type="text" class="form-control" id="formGroupExampleInput" placeholder="Example input" rows="2"></textarea>
          </div>
      </div>
      <div class="col">
       
            <label for="an">Write Answer:-</label>
            <select  class="form-control" bind:value={an} id='an'  >
            <option value="" selected>Select Answer</option>
            <option value="T">T</option>
            <option value="F">F</option>
          </select>
      </div>
      <div class="col">
          <button class='btn btn-primary' style="margin: 30px;" on:click={AddOption}>Add</button>
      </div>
</div>

<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="hints">Write hints:-</label>
            <!-- <textarea bind:value={TF.hints} type="text" class="form-control" id="hints" placeholder="Example input" rows="2"></textarea> -->
            <Editor    on:change={(evt)=>{
              TF.hints = evt.detail
              
              // console.log(mq.question)
              }}/>
          </div>  
    </div>
</div>

<div class="row" style="width:70% ;">
  <div class="col">
    <div class="form-group">
      <label for="file">Video file upload</label>
      <input type="file" class="form-control-file" id="file"  accept=".mp4" on:change={(e)=>{
        file = e.target.files[0]
  
      }}>
    

    </div>
  </div>
  <div class="col">
    <button on:click={async ()=>{
          if(file){
            fileLink=[]
              // Upload file and metadata to the object 'images/mountains.jpg'
const storageRef = ref(storage, 'videos/' + file.name);
const uploadTask = uploadBytesResumable(storageRef, file);



// Listen for state changes, errors, and completion of the upload.
uploadTask.on('state_changed',
(snapshot) => {
// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
// console.log('Upload is ' + progress + '% done');



switch (snapshot.state) {
case 'paused':
console.log('Upload is paused');
break;
case 'running':
console.log('Upload is running');

break;
}



}, 
(error) => {
// A full list of error codes is available at
// https://firebase.google.com/docs/storage/web/handle-errors
switch (error.code) {
case 'storage/unauthorized':
// User doesn't have permission to access the object
break;
case 'storage/canceled':
// User canceled the upload
break;

// ...

case 'storage/unknown':
// Unknown error occurred, inspect error.serverResponse
break;
}
}, 
() => {
// Upload completed successfully, now we can get the download URL
getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
console.log('File available at', downloadURL);

if(downloadURL){

getUrl(downloadURL)

}




// /* set up async GET request */
// var req = new XMLHttpRequest();
// req.open("GET", downloadURL, true);
// req.responseType = "arraybuffer";

// req.onload = function(e) {
//   var workbook = XLSX.read(req.response);

//   /* DO SOMETHING WITH workbook HERE */
//   console.log(workbook)
// };

// req.send();

});
}
);
        }
    }}>Save Video</button>
  </div>
  </div>

  {#if percent}
  <div class="row" style="width:70% ;">
    <div class="col">
      Uploading ..... ({percent} %)
    </div>

    {#if fileLink.length >0}
  <div class="col">
    <a href={fileLink[0]} target="_blank">
   Video Link
    </a>
  </div>
 {/if}
  </div> 
 
  {/if}
  

<div class="row">
  <div class="col">
     
    <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Option</th>
            <th scope="col">Answers</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
            {#each d as item}
                 <!-- content here -->
                 <tr>
                    <th scope="row">{item.key}</th>
                    <td>{item.data}</td>
                    <td>
                       {item.ans}
                    </td>
                    <td>
                        <span style="cursor:pointer ;" on:click={deleteOp(item)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                          </svg></span>
                    </td>
                   
                  </tr>
            {/each}
 
       
        </tbody>
      </table>

        <!-- <MultiSelect bind:selectedOptions options={d} /> -->
    </div>


<div class="col">
<button class='btn btn-primary' style="margin: 30px;" on:click={onSubmit} data-toggle="modal" data-target="#exampleModalTF" >Submit</button>
</div>

</div>
</section>


<!-- Modal -->
<div class="modal fade" id="exampleModalTF" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalCenterTitle">Preview Of Question</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {#if alert}
          <!-- content here -->
          {#if alert.type=='success'}
             <!-- content here -->
             <div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>
                 {alert.msg}
               </strong> 
               <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                 <span aria-hidden="true">&times;</span>
               </button>
             </div>
          {:else if alert.type=='danger'}
             <!-- else content here -->
             <div class="alert alert-danger alert-dismissible fade show" role="alert">
               <strong>
                 {alert.msg}
               </strong> 
               <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                 <span aria-hidden="true">&times;</span>
               </button>
             </div>
          {/if}
       {/if}
        {#if data}
             <!-- content here -->
             <div >
                <strong>
                    {@html data.question}
                </strong>
             </div>

             <div class="row">
                 <div class="col">
                     <ul>
                        {#each data.answers as item}
                        <!-- content here -->
                       <li>
                           ({item.key}) {@html item.data}
                       </li>
                   {/each}
                     </ul>
                 
                 </div>
             </div>

             <div class="row">
                 <div class="col">
                     <div style="color:green">
                         Correct Answers:- 
                         <ul>
                             {#each data.correctAnswers as item}
                                  <!-- content here -->
                                  <li>
                                    {item}
                                  </li>
                             {/each}
                         </ul>
                        
                     </div>
                 </div>
             </div>

             <div class="row">
                 <div class="col">
                     <p>Hints:-</p>
                     <div>
                         {@html data.hints[0].hint}
                     </div>
                 </div>
             </div>


             <section>
              <p><strong>Video:-  <a href={fileLink[0]} target="_blank">
                Video Link
                </a></strong></p>
              
            </section>
        {/if}
        </div>
        {#if alert}
        <!-- content here -->
        {#if alert.type !='success'}
        <!-- content here -->
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary"  on:click={submitData}>Save changes</button>
          </div>
     {/if}

     {#if alert.type =='success'}
        <!-- content here -->
        <button type="button" on:click={refresh} class="btn btn-secondary" data-dismiss="modal">Close</button>
     {/if}

     {:else}
        <!-- else content here -->
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary"  on:click={submitData}>Save changes</button>
          </div>
     {/if}
      </div>
    </div>
  </div>