<script>
  export let location;
  location = location;
  export let class_id;

  import { link } from "svelte-routing";
  import { get_class_assignments } from "../db/general";
  import { load_answers } from "../db/exams";
  import TeacherSideBar from "./TeacherSideBar.svelte";
  import TeacherDashboardHeader from "./TeacherDashboardHeader.svelte";

  async function fetch_assignment_data(assignment) {
    const answers = await load_answers(assignment.id);
    console.log("@assignment", assignment);
    console.log("@answers", answers);
    let total_students = assignment.student_emails.length;
    let total_completed = 0;
    for (const answer of answers) {
      console.log(answer);
      total_completed += answer.completed == answer.total ? 1 : 0;
    }

    const data = {
      due: `${assignment.due_date} - ${assignment.due_time}`,
      completed: `${total_completed} / ${total_students}`
    };
    return data;
  }
</script>

<style>
  .dateSelection {
    position: relative;
    width:250px;
    height:40px;
    border-radius: 25px;
    border:1px solid #b2b2b2;
    background-color:white;
    color: black;
    padding-left:10px;
    padding-right:20px;
  }

  .assignmentTable th {
    background-color: #efefef;
    height:40px;
    color: #767676;
    font-size: 10pt;
    padding-left:10px;
  }
  .assignmentTable td {
    font-size: 10pt;
    padding-left:10px;
  }
</style>

<TeacherSideBar {class_id} location={location} activeOption={'ASSIGNMENT_MANAGE'}>
    <TeacherDashboardHeader title="MANAGE ASSIGNMENTS" 
    writeUp="Here are the assignments your students are currently working on. 
            You can add more assignments or change existing assignments,
            and they’ll show up here"
    ></TeacherDashboardHeader>
  
  <select id="jk" class="dateSelection">
    <option>All Time</option>
    <option>Last 12 Months</option>
    <option>Last 6 Months </option>
    <option>Last 3 Months</option>
    <option>Last Month</option>
  </select>

  <ul class="nav">
    <li class="nav-item">
      <a class="nav-link active" href="#">Active</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">Saved</a>
    </li>
  </ul>

  <table border="0" style="width:100%;" class="assignmentTable">
    <thead>
      <tr>
        <th>Assignment Name</th>
        <th>Due Date & Time</th>
        <th>Assigned On</th>
        <th>Completed</th>
        <th colspan=2></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div style="font-weight: bold;">Multiply and Divide By Powers</div>
          <div style="font-weight: bold;">(Linear Functions)</div>
          <div stuyle="font-size:8pt;">Exercise - Random Question Set</div>
        </td>
        <td>September 12, 11:59 PM</td>
        <td>August 20th</td>
        <td>N/A</td>
        <td align="center"><a href="#"><i class="fad fa-2x fa-file-chart-line"></i>&nbsp;Reports</a></td>
        <td align="center">
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Actions
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
    
  </table>

  {#await get_class_assignments(class_id)}
    <div class="spinner-border text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  {:then assignments}
    <table>
      <tr>
        <th class="p-2">Standard</th>
        <th class="p-2" style="text-align: right">Due</th>
        <th class="p-2" style="text-align: right">Completed</th>
      </tr>
      {#each assignments as assignment}
        {#await fetch_assignment_data(assignment)}
          <tr>
            <td>
              <div class="spinner-border text-warning" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </td>
          </tr>
        {:then assignment_data}
          <tr>
            <td class="p-2">
              <a
                href={`/class/${class_id}/assignment/${assignment.id}`}
                use:link>
                {assignment.test_name}
              </a>
            </td>
            <td class="p-2" style="text-align: right">{assignment_data.due}</td>
            <td class="p-2" style="text-align: right">
              {assignment_data.completed}
            </td>
          </tr>
        {:catch error}
          <div class="alert alert-danger">{error.message}</div>
        {/await}
      {/each}
    </table>
    <!-- <pre>{JSON.stringify(assignments, null, 2)}</pre> -->
  {:catch error}
    <div class="alert alert-danger">{error.message}</div>
  {/await}
</TeacherSideBar>
