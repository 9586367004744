<script>
  export let flip;
  export let on_submit;
  export let showHintWindow;
  export let student_id;
  export let exam_id;
  export let question_id;
  export let assignment_id;

  import Calculator from "../parts/Calculator.svelte";
  import Video from "../parts/Video.svelte";
  import Ask from "../parts/Ask.svelte";

  let show_hint = false;
  let show_calculator = false;
  let show_ask = false;
  let show_material = false;
  let show_video = false;

  $: console.log(on_submit)

</script>

<style>
  .toolbar {
    color: #0084ff;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 15em;
    padding: 1em;
    background-color: white;
    z-index: 9;
  }
</style>

<div class="toolbar border-top flex-row">

  {#if flip}  
    <a href="#" on:click={() => flip(-1)}>
      <i class="fas fa-chevron-left fa-3x"></i>
    </a>
    <div class="flex-gap" />
    <a href="#" on:click={() => flip(+1)}>
      <i class="fas fa-chevron-right fa-3x"></i>
    </a>
    <div class="flex-gap" />

  {/if}

  

  <a href="#" on:click={() => {(show_calculator = !show_calculator)}}>
    <i class="fas fa-calculator fa-3x"/>
  </a>
  
  <div class="flex-gap" />
  <a href="#" on:click={() => (show_ask = !show_ask)}>
    <i class="fas fa-hand-paper fa-3x"></i>
  </a>
  <div class="flex-gap" />
  <a href="#" on:click={() => {(show_video = !show_video)}}>
    <i class="fab fa-youtube fa-3x"/>
  </a>
  <div class="flex-gap" />
  <div class="flex-gap">
    <a href="#" on:click={() => showHintWindow()}>
      <i class="fal fa-lightbulb-exclamation fa-3x"/>
    </a>
  </div>


  <!--<div class="flex-gap" />
  <button
    class="btn btn-secondary"
    on:click={() => (show_material = !show_material)}>
    Learning Material
  </button>-->

  <div class="flex-grow" />

  {#if on_submit}
    <div class="flex-gap" />
    <button class="btn btn-primary" style="border-radius:30px; width:120px;" on:click={on_submit}>Submit</button>
  {/if}
</div>

<Calculator
  {show_calculator}
  close_callback={() => (show_calculator = false)} />

<Video
  {show_video}
  close_callback={() => (show_video = false)} />

<Ask
  {show_ask}
  {student_id}
  {exam_id}
  {question_id}
  {assignment_id}
  close_callback={() => (show_ask = false)} />
