import { writable } from 'svelte/store'

export function randomizeObject(collectionToRandomize) {
  for(let i = collectionToRandomize.length - 1; i > 0; i--){
    const j = Math.floor(Math.random() * i)
    const temp = collectionToRandomize[i]
    collectionToRandomize[i] = collectionToRandomize[j]
    collectionToRandomize[j] = temp
  }
  return collectionToRandomize;
}

export function getAnswerOptionsArray(answers) {
  let answerKeys = []
  answers.forEach((answer=>{
    answerKeys.push(answer.key);
  }))
  console.log('getAnswerOptionsArray', answerKeys);
  return answerKeys;
}

export function randomizeAnswerGroup(group_id, answers) {
  // Pull all answers associated with the selected group_id
  let groupAnswers = answers.filter((answer)=>answer.group == group_id);
  return randomizeObject(groupAnswers);
}

// window.render_katex = (string) => {
//   return string.replace(/\$[^\$]*\$/g, (match) => {
//     if (match === "$$") return "$";
//     const latex = match.slice(1, match.length - 1);
//     try {
//       return katex.renderToString(latex);
//     } catch (e) {
//       console.error("@katex-error", latex);
//       console.error(e);
//       return "KATEX-ERROR(" + latex + ")";
//     }
//   });
// };

export function watcher (initialValue, watchFunction) {
	const { subscribe, update } = writable(initialValue)
	return {
		subscribe,
		set: value => {
			update(oldvalue => {
				watchFunction(oldvalue, value)
				return value
			})
		}
	}
}

