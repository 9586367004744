const db = firebase.firestore();
import {current_user_id} from '../../auth';
import {as_array, as_object} from '../dbUtil'
import { v4 as uuidv4 } from 'uuid';
import md5 from "md5";

function getHash(input) {
    return input.replace(/-/gi,"").trim().substring(0,20);
    
}

export async function saveQuestion(question) {
    var hash = getHash(uuidv4());
    return await db.collection("testBank").doc(hash).set(question);
}


export async function dataHash(data){
    var hash = md5(data);
    console.log({
        hash:hash
    })

    return hash
}