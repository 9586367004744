<script>
	
	import { onMount } from 'svelte';

    import { add_exams, add_ass} from "../db/exams";
    
     let alert={
         type:'',
         msg:''
     }

     let fixedQuestion;

    export let questionsList;
    export let d_id;
    export let d_n;
    export let classList;
    export let clearTSub
    let assName=''
    let examName=''
    let class_id=''
    let students=[]
    let courseName=''
    let className=''
    let duDate=''
    let course_id=''

    let select;
    let serialNo =[]
    let orderList =[]
    let h=[]
    let v=[]
    let ass_type=''

    onMount( async ()=>{
        fixedQuestion = questionsList
        console.log("questionsList", questionsList)
        console.log(d_id)
        console.log(d_n)
        console.log(classList)


        for (let index = 1; index <= questionsList.length; index++) {
            
            serialNo.push(index)
            
        }

        setTimeout(() => {
             let d = serialNo.sort((a,b)=>{
                 return a-b
             })
             serialNo = d
        }, 1000);

    })


  async function selectToOrder(){
      if(select){
        
        let s = questionsList.filter(f=> f.id == select)

        h.push({
          order: serialNo[0],
          points:'1',
          question_id:s[0].id
        })


        questionsList = questionsList.filter(f=> f.id != select)

        setTimeout(() => {

            let a = serialNo.filter(f=> f != serialNo[0])

            serialNo=a

             let d = serialNo.sort((a,b)=>{
                 return a-b
             })
             serialNo = d
             orderList = h
             orderList = orderList.sort((a,b)=>{
                 return a.order-b.order
             })
             console.log(orderList)
             console.log(serialNo)
             console.log(questionsList)
            //  console.log(questionsList)

            select=''


        }, 1000);



      }
  }


  async function findDataL (d){
    //   console.log('d',d)

   if(d){
       let s = fixedQuestion.filter(f=> f.id === d.question_id)

       return s[0]
   }
  }


  async function reOrder (d){
     if(d){
        serialNo.push(d.order)

        let s = fixedQuestion.filter(f=> f.id == d.question_id)

        questionsList.push(s[0])

        orderList = orderList.filter(f=> f.order != d.order)

        h=orderList

   setTimeout(() => {
    let d = serialNo.sort((a,b)=>{
                 return a-b
             })
            serialNo = d

            orderList = orderList.sort((a,b)=>{
                 return a.order-b.order
             })

            questionsList=questionsList

            console.log(questionsList)
   }, 1000);


     }
  }

async function submitPart2(){


   fixedQuestion.forEach((f)=>{
    select  = f.id

    selectToOrder()
   })




   setTimeout(() => {
    if(fixedQuestion.length == orderList.length){
            submit()
    }
   }, 1500);




   
  

}
async function selectClass(){
    if(class_id){
     let c = classList.filter(f=> f.id == class_id )


     if(c.length > 0){
      
        students = c[0].students
       course_id=c[0].course_id
     courseName= c[0].courseName
     className = c[0].name
     }
    
     
    }else{
        class_id=''
    }
}

async function submit(){
    console.log('assName', assName)
    console.log('examName', examName)
    console.log('class_id', class_id)
    console.log('duDate', duDate)



    if(assName && examName && class_id && duDate){

        //  if(orderList.length == 0){

        //     for (let index = 0; index < questionsList.length; index++) {
        //         const element = questionsList[index];

        //         select = element.id

        //         selectToOrder()
                
                
        //     }
        //  }

        if(fixedQuestion.length === orderList.length){

            let newExam = {
               name:examName,
               questions: orderList,
               standards:[{
                standard: "7.EE.1",
                totalQuestions: orderList.length
               }],
               type: "NormalTest"
            }

            let e = await add_exams(newExam)

            console.log(e)


            if(e){
                let newAss={
                    assignmentType: "Exercise - Different Question Set",
                    class_id:class_id,
                    exam_id:e,
                    name:assName,
                    students:students,
                    task_id:'',
                    dueDate:new Date(duDate),
                    totalQuestions: orderList.length

                }

                let a = await add_ass(newAss, d_id)

                if(a){

                   console.log(a)
                   console.log(d_id)

                   alert ={
                       type:'success',
                       msg:'Assignmnet is succesfully set for students of '+ className
                   }



                }
            }
        }else{
            alert =       {
                       type:'danger',
                       msg:'Total question number is '+fixedQuestion.length +' but you selected '+orderList.length
                   }
        }
    }else if(!assName && examName && class_id && duDate){
    
        // for (let index = 0; index < questionsList.length; index++) {

        // }
      console.log('questionsList', questionsList)


        for (let index = 0; index < questionsList.length; index++) {
    
     
    let order = []
      let current =''
      
   

     current = questionsList[0]

     let s = questionsList.filter(f=> f.assignment == current.assignment)
      

     if(s.length > 0){
        
        s.forEach(element => {
            order.push({
          order: serialNo[0],
          points:'1',
          question_id:element.id,
          assignment:element.assignment
            })

            questionsList  = questionsList.filter(f=> f.id != element.id)


            let a = serialNo.filter(f=> f != serialNo[0])

serialNo=a

let d = serialNo.sort((a,b)=>{
return a-b
})
serialNo = d


        });

        orderList = order
orderList = orderList.sort((a,b)=>{
return a.order-b.order
})



        let newExam = {
               name:examName,
               questions: orderList,
               standards:[{
                standard: "7.EE.1",
                totalQuestions: orderList.length
               }],
               type: "NormalTest"
            }

            let e = await add_exams(newExam)

            console.log(e)


            if(e){
                let newAss={
                    assignmentType: "Exercise - Different Question Set",
                    class_id:class_id,
                    exam_id:e,
                    name:orderList[0].assignment,
                    students:students,
                    task_id:'',
                    dueDate:new Date(duDate),
                    totalQuestions: orderList.length

                }

                let a = await add_ass(newAss, d_id)

                if(a){

                   console.log(a)
                   console.log(d_id)


                   alert ={
                       type:'success',
                       msg:'Assignmnet is succesfully set for students of '+ className+' '+ questionsList.length
                   }

          
      
     }


            }
        }
  }
 

     



    }else{
        alert =       {
                       type:'danger',
                       msg:'Some data is missing.'
                   }
    }

    setTimeout(async () => {
        await dis()
    }, 1000);
}




async function dis(){
     assName=''
     examName=''
     class_id=''
     students=[]
     courseName=''
     className=''
     duDate=''
     course_id=''

     select=''
     serialNo =[]
     orderList =[]
     h=[]
     v=[]
    setTimeout(async () => {
        await clearAlert()
    }, 1500);
    setTimeout(async () => {
        await clearTSub()
    }, 2000);
}

async function clearAlert(){
    alert = {
        type:'',
        msg:''
    }
}
</script>

<section style="margin:10px ;">
    {#if alert.type == 'success'}
         <!-- content here -->
         <div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                  </svg>     {alert.msg}
            </strong> 
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" on:click={clearAlert}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
    {:else if alert.type=='danger'}
         <!-- else content here -->
         <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>    {alert.msg}
            </strong> 
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" on:click={clearAlert}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
    {/if}
</section>

<section >

   <div class="form-group">
    <label for="selectOrder">Create Order Of questions</label>
    <select class="form-control" id="selectOrder" bind:value={select} on:change={selectToOrder}>
        <option value='' selected>Create now</option>

        {#each questionsList as item,i}
             <!-- content here -->
             <option value={item.id}>{item.topic} || {item.type}</option>
        {/each}
     
     
    </select>
  </div>




</section>

 <section style="height:300px; overflow-x:scroll">
    <div class="table-responsive">
        <table class="table">
          <thead>
              <tr>
                  <th>
                      #
                  </th>
                  <th>
                      Topic
                  </th>
                  <th>
                      Type
                  </th>
                  <th>
                      Action
                  </th>
              </tr>
          </thead>
        
           <tbody>
            {#each orderList as item}
                 <!-- content here -->
                 <tr>
                     <td>
                         {item.order}
                     </td>
                     <td>
                         {#await findDataL(item)}
                             <!-- promise is pending -->
                             <div class="spinner-border text-warning" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                         {:then value}
                             <!-- promise was fulfilled -->
                             {value.topic}
                         {:catch error}
                             <!-- promise was rejected -->
                         {/await}
                     </td>
                     <td>
                        {#await findDataL(item)}
                        <!-- promise is pending -->
                        <div class="spinner-border text-warning" role="status">
                           <span class="sr-only">Loading...</span>
                         </div>
                    {:then value}
                        <!-- promise was fulfilled -->
                        {value.type}
                    {:catch error}
                        <!-- promise was rejected -->
                    {/await}
                     </td>
                      <td>
                         <button class="btn btn-danger btn-sm" on:click={reOrder(item)}>Delete</button>
                      </td>
                 </tr>
            {/each}
          </tbody>
        </table>
      </div>
 </section>


 <section style="padding: 20px;">
    <form>
        <div class="row" style="margin:10px">
            <div class="col">
                <select class="custom-select" bind:value={ass_type} >
                    <option value="" selected>Select Assignment Type</option>
                    <option value="1" >Single Assignment</option>
                    <option value="2" >Multiple Assignment</option>
                  </select>
            </div>
         
          </div>
  
        <div class="row" style="margin:10px">
            {#if ass_type == 1}
            <div class="col" >
                <input type="text" class="form-control" placeholder="Assignment Name" bind:value={assName}>
              </div>
            {/if}
         
          <div class="col">
            <input type="text" class="form-control" placeholder="Exam Name" bind:value={examName}>
          </div>
        </div>

        

        <div class="row" style="margin:10px">
            <div class="col">
                <select class="custom-select" bind:value={class_id} on:change={selectClass}>
                    <option value="" selected>Select Class</option>
                    {#each classList as item}
                         <!-- content here -->
                    <option value={item.id}>
                      <ul>
                          <li>
                              Class:- {item.name}
                          </li>
                          <li>
                              <br> <br> <br>
                          </li>
                          <li>
                              Course:- {item.courseName}
                          </li>
                      </ul>

                    </option>
                    {/each}
                  
                  </select>
            </div>
            <div class="col">
                <input type="date" class="form-control" placeholder="Exam Name" bind:value={duDate}>
              </div>
        </div>
      </form>

      {#if ass_type == 1 && orderList.length == 0 }
      <div class="col" >
        <button class="btn btn-primary btn-lg" on:click={submitPart2}>Create Now </button>
        </div>
       
      
      {/if}

      {#if ass_type == 1 && orderList.length >  0 }
      <div class="col" >
        <button class="btn btn-primary btn-lg" on:click={submit}>Create Now </button>
        
    </div> 
      {/if}

      {#if ass_type == 2 && orderList.length ==  0 }
      <div class="col" >
        <button class="btn btn-primary btn-lg" on:click={submit}>Create Now </button>
        
    </div> 
      {/if}

      {#if !ass_type && orderList.length ==  0 }
      <div class="col" >
        <button class="btn btn-primary btn-lg"  >Create Now </button>
        
    </div> 
      {/if}

    


 </section>

