const db = firebase.firestore();
import {current_user_id} from '../auth';
import {as_object, as_array} from './dbUtil'

export async function get_standards() {
    return as_array(await db.collection("stateStandards").get());
}

export async function get_grades() {
    return as_object(await db.collection("stateStandards").doc("grades").get());
}
