<script>
  export let location;
  location = location;

	import { onDestroy } from 'svelte';
  import { link, navigate } from "svelte-routing";
  import { current_user_email } from "../auth.js";
  import { list_teacher_classes, new_class } from "../db/general";
  import TeacherDashboardHeader from "./TeacherDashboardHeader.svelte";
  import TeacherSideBar from "./TeacherSideBar.svelte";
  import TeacherMessagesBar from "./TeacherMessagesBar.svelte";
  import {course_id} from "../store.js"

  let courseID;

  const unsubscribe = course_id.subscribe(value => {
    courseID = value;
  });

  onDestroy(unsubscribe);

</script>

 <TeacherSideBar location={location} activeOption={'HOME_PAGE'} >
  <!--{#await fetch_index()}
    <div class="spinner-border text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  {:then index}
    <TeacherAssignNode node={make_tree(index)} {set_test_name} />
  {:catch error}
    <div class="alert alert-danger">{error.message}</div>
  {/await}-->
<div class="container">
  <TeacherDashboardHeader title="Primary Teacher Dashboard" 
    writeUp="Welcome to the teacher dashboard, the navigation on the left allows you to manage all of your classes, assignments, student rosters, etc. "
    ></TeacherDashboardHeader>

    <TeacherMessagesBar></TeacherMessagesBar>

  {#await list_teacher_classes()}
    <div class="spinner-border text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  {:then classes}
    <table>
      <tr>
        <th>Class Name</th>
        <th style="text-align: right">Number of Students</th>
      </tr>
      {#each classes as cls}
        <tr>
          <td>
            <a href={'/class/' + cls.id} use:link>{cls.name}</a>
          </td>
          <td style="text-align: right">{cls.students.length}</td>
        </tr>
      {/each}
    </table>
  {:catch error}
    <div class="alert alert-danger">{error.message}</div>
  {/await}
</div>

</TeacherSideBar>
