<script>
  export let question;
  //export let on_update;
  export let on_update;
  let answer = {};
  function on_change(event) {
    answer[event.target.name] = event.target.value;
    on_update(question, answer);
  }
</script>

<style>
  .answer {
    min-height: 2em;
    outline: 1px dashed var(--nice-color);
    padding: 0.5em;
  }
</style>

{@html question.question}

<br />

<div class="answer" contenteditable="true">Type your answer here</div>
<div>
  <label class="p-row inline-p">
    <!-- {@html question[letter]}
    &nbsp; -->
    <input
      type="text"
      name='letter'
      placeholder="Answer here"
      on:change={on_change} 
      
      />
  </label>
</div>