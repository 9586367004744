<script>
    export let percentage;
</script>
<style>
    
</style>
{#if percentage < 70 && percentage > 0}
    <div style="color:red; font-weight:bold">Below (Below 70%)</div>
{:else if percentage > 70 && percentage < 79.9}
    <div style="color:#10c31c; font-weight:bold">Met (70-79%)</div>
{:else if percentage > 80 && percentage < 89.9}
    <div style="color:#0c6412; font-weight:bold;">Proficient (80-89%)</div>
{:else if percentage > 90}
    <div style="color:#265ad0; font-weight:bold;">Exceed 90%+</div>
{:else if percentage == 0}
    <div style="color:black; font-weight:bold;">N/A</div>
{/if}

        
        
        
        