<script>
  export let question;
  export let on_update;
  export let my_answer ;
  function on_change(event) {
    console.log(event.target.value)
    my_answer = event.target.value.toUpperCase();
    on_update(question, my_answer);
  }

  

</script>

{@html question.question}
<br />
<form>
  {#each question.answers as answer}
    <div class="form-check">
      <label class="form-check-label inline-p">
        <input
          class="form-check-input"
          type="radio"
          name="answer"
          value={answer.key}
          on:change={on_change} />
        {@html answer.data}
      </label>
    </div>
  {/each}
</form>

