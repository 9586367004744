<script>
  import { link, navigate } from "svelte-routing";
</script>

<style>
  .sidebar {
    position: fixed;
    top: 54px;
    bottom: 0;
    left: 0;
    width: 300px;
    min-width: 300px;
    display: block;
  }
  .content {
   
  }
</style>

<nav
  id="sidebarMenu"
  class="col-md-3 col-lg-2 d-md-block sidebar global-sidebar collapse
  border-right shadow-sm">

  <ul class="nav flex-column mb-2">
    <li class="nav-item" style="border-bottom: solid 1px #979797; border-top: solid 1px #979797;">
      <a style="color:white; font-weight:bold;" class="nav-link" href={'/my/learning-pathway'} use:link>
        <table>
        <tr><td style="width:60px; text-align: center;"><i class="fad fa-route fa-2x" style="color:#0084ff;"></i></td>
        <td>My Learning Pathway</td></tr></table>
      </a>
    </li>
    <li class="nav-item" style="border-bottom: solid 1px #979797">
      <a style="color:white; font-weight:bold;" class="nav-link" href={'/my/class-assignments'} use:link>
        <table><tr><td style="width:60px; text-align: center;"><i class="fad fa-function fa-2x" style="color:#0084ff;"></i></td>

        <td>My Class Assignments</td></tr></table>
      </a>
    </li>
    <li class="nav-item">
      <a style="color:white; font-weight:bold;" class="nav-link" href={'/my/reports'} use:link>
        <table>
          <tr><td style="width:60px; text-align: center;"><i class="fad fa-file-chart-line fa-2x" style="color:#0084ff;"></i></td>
          <td>My Reports</td></tr>
        </table>
      </a>
    </li>
    <!--<li class="nav-item">
      <a class="nav-link" href={'/my/settings'} use:link>My Settings</a>
    </li>-->
  </ul>

</nav>

<div class="content container" style="float:left; margin-left: 301px;">
  <slot />
</div>
