<script>
  export let test;
  export let assignment_id;
  export let questionChange;

  import { Link } from "svelte-routing";
  import Question from "../questions/Question.svelte";
  import Toolbar from "./Toolbar.svelte";
  import Judge from "../judge.js";
  import {save_answers} from "../db/exams";

  let index = 0;
  let hint_visible = "hidden";
  let last_question;
  let last_answer;
  let correctGiven;
  let saving = false;

  let student_id = "";
  let exam_id = "";
  let question_id = "";

  const judge = new Judge(assignment_id);

  function flip(diff) {
    if(correctGiven == true){
      index = (index + diff + (test.questions.length + 1)) % (test.questions.length + 1);
    console.log(test)

    last_question = null;
    last_answer = null;
    correctGiven = null
    questionChange(Math.round((index / test.questions.length) * 100));
    hint_visible = "hidden";
    upKeep();
    }
  
  }
 
  function upKeep() {
    console.log(index)
    exam_id = test.exam_id;
    question_id = test.questions[index].id;

    console.log(test)
  }

  function showHintWindow() {
    hint_visible = "visible";
  }

  function on_update(question, selectedAnswer) {
    console.log(question, selectedAnswer)
    last_question = question;
    last_answer = selectedAnswer;
    console.log(test.questions.length)
     judge.on_update(test.questions.length, question, selectedAnswer);
  }

  async function on_submit() {
    saving = true;
    let answerCorrect = judge.validateAnswer(last_question, last_answer);
    correctGiven = answerCorrect
    await save_answers(assignment_id, answerCorrect, last_question, last_answer);
    saving = false;
  }

  // async function on_submitData() {
  //   saving = true;
  //   let answerCorrect = judge.validateAnswer(last_question, last_answer);
  //   await save_answers(assignment_id, answerCorrect, last_question, last_answer);
  //   saving = false;
  // }


  upKeep();

 

</script>
  
<style>
  .savingBox {
    z-index: 9999;
    background:#646464;
    position:absolute;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    opacity: 0.8;
    text-align: center;
    vertical-align: middle;
  }

  .savingBoxHide {
    visibility: hidden;
  }

  .vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  }

</style>

<div class="savingBox" class:savingBoxHide = {!saving}>
  <div class="vertical-center">
    <table>
    <tr>
      <td><div class="spinner-border text-warning" role="status"></div></td>
      <td style="font-weight:bold; color:white; font-size:18pt;">&nbsp;&nbsp;Submitting Answer...</td>
    </tr>
    
  </div>
</div>

{#if index < test.questions.length}
  <div class="performance-test">
    <Question question={test.questions[index]} {on_update} {correctGiven} {last_question} {last_answer}  hintVisible={hint_visible} />
  </div>
{:else}
  Thank you for taking the test. Check out the
  <Link to={`/assignment/${assignment_id}/report`}>report</Link>
{/if}
<Toolbar {flip} {on_submit} {student_id} {exam_id} {question_id} {assignment_id} {showHintWindow} />
