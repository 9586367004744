const db = firebase.firestore();
import {current_user_id} from '../auth';
import {get_teacherRecord, get_class, get_studentRecord} from './general';
import {get_exam_record} from "./exams";
import {as_object, as_array} from './dbUtil'


// This can only be run from the teachers terminal
export async function get_messages_for_bar(teacher_id) {
    teacher_id = teacher_id ?? await current_user_id();
    let messages = await as_array(await db.collection("messages")
        .where("teacher_id","==",teacher_id)
        .where("closed","==",false).get()); 
    
    let responseSet = {
        totalMessages: messages.length,
        messages : []
    }
    // We only care about the "open" and latest message
    // We also want to put the messages in "Oldest to Newest order" [DESC] <-- This could be an optional setting in the future
    messages.sort((a,b)=>{
        if(a.lastUpdated > b.lastUpdated) return -1;
        if(a.lastUpdated < b.lastUpdated) return 1;
        return 0;
    }).forEach(msg => {
        // We now have the messages in the oldes to newest order but we need to grab the newest message in the conversation
       let latestMessage = msg.messageThreads.filter(f=>f.read == false).sort((a,b)=>{
            if(a.timeStamp > b.timeStamp) return -1;
            if(a.timeStamp < b.timeStamp) return 1;
            return 0;
        })[0]; // We sorted DESC so the newest should be the first item;
        responseSet.messages.push({
            id: msg.id,
            className: msg.className,
            studentLastName: msg.studentLastName,
            studentFirstName: msg.studentFirstName,
            message: latestMessage
        });
    })
    return responseSet;
}

// This is run from the students teminal
export async function saveExamMessage(subject, message, assignment_id, exam_id, question_id, student_id) {
    student_id = student_id ?? await current_user_id();
    // Pull the Assignment information
    let assignment = as_object(await db.collection("assignments").doc(assignment_id).get())
    let student = await get_studentRecord(student_id);
    let classRec = await get_class(assignment.class_id);
    let teacher = await get_teacherRecord(classRec.teacher_id);
    let exam = await get_exam_record(exam_id)

    let newMessage = {
        className: classRec.name,
        class_id: classRec.id,
        closed: false,
        lastUpdated: new Date(),
        studentFirstName: student.firstName,
        studentLastName: student.lastName,
        student_id: student_id,
        teacher_id: teacher.id,
        messageThreads: [
            {
                assignmentName: assignment.name,
                assignment_id: assignment.id,
                examName: exam.name,
                exam_id: exam_id,
                message: message,
                question_id: question_id,
                subject: subject,
                task_id: "",
                task_name: "",
                timeStamp: new Date(),
                read: false
            }
        ]
    }

    return await db.collection("messages").add(newMessage);
}