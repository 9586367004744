<script>
  export let location;
  location = location;
  export let class_id;

  import TeacherSideBar from "./TeacherSideBar.svelte";
  import {get_teacherRecord} from "../db/general.js"

</script>
<style>
  .page-header {
    padding-top:15px;
    color: #10c31c;
    font-family: gothamBlack,"Times New Roman";
    font-weight: bold;
    font-size: 18pt;  
    text-transform: uppercase;
  }

  .icon {
    color: #0084ff;
  }

  .section-title {
    color: black;
    font-weight: bold;
    font-size: 14pt;  
    border-bottom:solid 1px #696a6b;
    width:100%;
    margin-bottom: 15px;
  }

  .textBoxes {
    border-radius: 30px;
  }

  .buttons {
    border-radius: 30px;  
    background-color: #0084ff;
  }

  .subText {
    color: #414141;
    font-size: 8pt;
    text-align: center;
  }
</style>


<TeacherSideBar>
  <div class="page-header">My Settings</div>
  <table border="0" style="width:100%;">
  <tr>
    <td>
    
    <div style="margin-bottom:30px;">
      {#await get_teacherRecord()}
        <div class="spinner-border text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      {:then student}
        <form>
          <div class="section-title">Profile :</div>
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input type="text" class="form-control textBoxes" id="firstName" aria-describedby="firstNameHelp" value={student.firstName}/>
          </div>
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input type="text" class="form-control textBoxes" id="lastName" aria-describedby="lastNameHelp" value={student.lastName} />
          </div>
          <div class="form-group">
            <label for="email">Email address</label>
            <input type="email" disabled class="form-control textBoxes" id="email" aria-describedby="emailHelp" value={student.email}/>
          </div>
        </form>
      {:catch error}
        <div class="alert alert-danger">{error.message}</div>
      {/await}
    </div>
    <div>
      <div class="section-title">Email Notifications :</div>
        <div class="form-check form-check">
          <input class="form-check-input" type="checkbox" id="weeklyReport" value="option1">
          <label class="form-check-label" for="weeklyReport">Weekly Report</label>
        </div>
        <div class="form-check form-check">
          <input class="form-check-input" type="checkbox" id="newAssignments" value="option1">
          <label class="form-check-label" for="newAssignments">New Assignments</label>
        </div>
        <div class="form-check form-check">
          <input class="form-check-input" type="checkbox" id="dueReminders" value="option1">
          <label class="form-check-label" for="dueReminders">Due Date Reminders</label>
        </div>
    </div>

    </td>
    <td style="width:35px;"></td>
    <td valign="top">
      <div>
        <div class="section-title">Options :</div>
        <div style="margin-bottom:30px;">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="sound" value="option1">
            <label class="form-check-label" for="sound">Sound</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="glossary" value="option1">
            <label class="form-check-label" for="glossary">Embed Glossary With Each Question</label>
          </div>
        </div>
      </div>
      
      <div style="margin-bottom:30px;">
          <div class="section-title">Icons :</div>
          <div class="flex-row">
          <div style="text-align: center;">
            <i class="fas fa-chevron-left fa-3x icon"></i>
            <div class="subText">Previous</div>
          </div>
          <div class="flex-gap" />
          <div style="text-align: center;">
            <i class="fas fa-chevron-right fa-3x icon"></i>
            <div class="subText">Next</div>
          </div>
          <div class="flex-gap" />
          <div style="text-align: center;">
            <i class="fas fa-calculator fa-3x icon"/>
            <div class="subText">Calculator</div>
          </div>
          <div class="flex-gap" />
          <div style="text-align: center;">
            <i class="fas fa-hand-paper fa-3x icon"></i>
            <div class="subText">Teacher Assistance</div>
          </div>
          <div class="flex-gap" />
          <div style="text-align: center;">
            <i class="fab fa-youtube fa-3x icon"/>
            <div class="subText">Video Tutorial</div>
          </div>
          <div class="flex-gap" />
          <div style="text-align: center;">
            <i class="fal fa-lightbulb-exclamation fa-3x icon"/>
            <div class="subText">Hints</div>
          </div>
          <div class="flex-gap" />
          <div style="text-align: center;">
            <button class="btn btn-primary" style="border-radius:30px; width:120px; padding-top:12px; padding-bottom:10px;">Submit</button>
            <div class="subText">Submit Work For Grading</div>
          </div>
        </div>
      </div>

      <div>
        <div class="section-title">Mastery Levels :</div>
        <div style="color:red; font-weight:bold">Below (Below 70%)</div>
        <div style="color:#10c31c; font-weight:bold">Met (70-79%)</div>
        <div style="color:#0c6412; font-weight:bold;">Proficient (80-89%)</div>
        <div style="color:#265ad0; font-weight:bold;">Exceed 90%+</div>
      </div>

      <div style="margin-top:40px; text-align: center; border-top: solid 1px #696a6b; padding-top:10px; padding-bottom:10px; background-color: #efefef;">
          <button class="btn btn-primary" style="border-radius:30px; width:220px;">Save Settings</button>
      </div>
    </td>
  </tr>
  </table>

</TeacherSideBar>
