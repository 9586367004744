<script>
  export let test;
  export let assignment_id;

  import { navigate } from "svelte-routing";
  import Question from "../questions/Question.svelte";
  import Toolbar from "./Toolbar.svelte";
  import Judge from "../judge.js";

  let index = 0;

  const judge = new Judge(assignment_id);
  function on_update(question, answer) {
    judge.on_update(test.length - 1, question, answer);
  }
  async function on_submit() {
    await judge.on_submit(assignment_id);
    navigate(`/assignemnts/${assignment_id}/report`);
  }
</script>

<style>
  .performance-test {
    margin-bottom: 5em;
  }
</style>

<div class="performance-test">
  {#each test as question}
    <Question {question} {on_update} />
    <pre>{JSON.stringify(question, null, 2)}</pre>
    <hr />
  {/each}
</div>

<Toolbar {on_submit} />
